import { Component, OnInit, Input } from '@angular/core';
import { Book } from '../../../_models/book';
import { AlertifyService } from '../../../_services/alertify.service';
import { BookService } from '../../../_services/book.service';

@Component({
    selector: 'app-book-card',
    templateUrl: './book-card.component.html',
    styleUrls: ['./book-card.component.css']
})
export class BookCardComponent implements OnInit {
    @Input() books: Book[] = [];
    @Input() returnUrl: string = "";
    @Input() editBookLink: string = "";

    sortedBooks: Book[] = [];
    constructor(private bookService: BookService,
        private alertify: AlertifyService) { }

    ngOnInit() {
        this.sortedBooks = this.books.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)
    }

    deleteItem(list : any, item: any) {
        const index = list.indexOf(item);
        if (index > -1)
            list.splice(index, 1);
    }

    deleteBook(list: any, item: { name: any; id: number; }) {
        this.alertify.confirm($localize`Are you sure to delete ${item.name}?`, $localize`Delete book`, () => {
            this.bookService.deleteBook(item.id).subscribe(() => {
                this.alertify.success($localize`Deleted successfully`);
                this.deleteItem(list, item);
            }, error => {
                this.alertify.error(error);
            });
        });
    }
}
