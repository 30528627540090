import { Component, OnInit, Input } from '@angular/core';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { Router } from '@angular/router';
import { Author } from '../../../_models/author';

@Component({
  selector: 'app-list-group-author',
  templateUrl: './list-group-author.component.html',
  styleUrls: ['./list-group-author.component.css']
})
export class ListGroupAuthorComponent implements OnInit {
    @Input() authors: Author[] = [];

    sortedAuthors: Author[] = [];

    constructor(private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.sortedAuthors = this.authors.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)
    }

    deleteItem(list: any[], item: any) {
        const index = list.indexOf(item);
        if (index > -1)
            list.splice(index, 1);
    }

    deleteAuthor(list: any[], item: { firstName: any; lastName: any; id: number; }) {
        this.alertify.confirm($localize`Are you sure to delete ${item.firstName} ${item.lastName}?`, $localize`Delete Author`, () => {
            this.bookService.deleteAuthor(item.id).subscribe(() => {
                this.alertify.success($localize`Deleted successfully`);
                this.deleteItem(list, item);
            }, error => {
                this.alertify.error(error);
            });
        });
    }

    collapse(id: string){
        var d = document.getElementById(id);
        if(d?.classList.contains("collapse")){
            d?.classList.remove("collapse");
        }else{
            d?.classList.add("collapse");
        }
    }
    //deleteBook(list, item) {
    //    this.alertify.confirm(`Are you sure to delete ${item.name}?`, `Delete book`, () => {
    //        this.bookService.deleteBook(item.id).subscribe(() => {
    //            this.alertify.success('Delete successful');
    //            this.deleteItem(list, item);
    //        }, error => {
    //            this.alertify.error(error);
    //        });
    //    });
    //}

    //deleteBookFile(list, item) {
    //    this.alertify.confirm(`Are you sure to delete ${item.name}?`, `Delete book file`, () => {
    //        this.bookService.deleteBookFile(item.id).subscribe(() => {
    //            this.alertify.success('Delete successful');
    //            this.deleteItem(list, item);
    //        }, error => {
    //            this.alertify.error(error);
    //        });
    //    });
    //}
}
