import { ChangePasswordModel } from './../_models/changePasswordModel';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { ChangeEmailModel } from '../_models/changeEmailModel';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getUsers(page: string | number | boolean | null, itemsPerPage: string | number | boolean | null, userParams: { minAge: string | number | boolean; maxAge: string | number | boolean; gender: string | number | boolean; orderBy: string | number | boolean; } | null, likesParam: any): Observable<PaginatedResult<User[]>> {
        const paginatedResult: PaginatedResult<User[]> = new PaginatedResult<User[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        if (userParams != null) {
            params = params.append('minAge', userParams.minAge);
            params = params.append('maxAge', userParams.maxAge);
            params = params.append('gender', userParams.gender);
            params = params.append('orderBy', userParams.orderBy);
        }

        return this.http.get<User[]>(this.baseUrl + 'user', { observe: 'response', params })
            .pipe(
                map(response => {
                    if(response.body!=null){
                        paginatedResult.result = response.body;
                    }
                    
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination')!);
                    }
                    return paginatedResult;
                })
            );
    }

    getUser(): Observable<User> {
        return this.http.get<User>(this.baseUrl + 'user');
    }

    updateUser(user: User) {
        return this.http.put(this.baseUrl + 'user', user);
    }

    changePassword(changePasswordModel: ChangePasswordModel) {
        return this.http.post(this.baseUrl + 'user/ChangePassword', changePasswordModel);
    }

    confirmEmailRequest(email: string) {
        return this.http.get(this.baseUrl + 'user/ConfirmEmailRequest?email=' + email);
    }

    confirmEmail(username: string, code: string) {
        return this.http.get(this.baseUrl + 'user/ConfirmEmail?username=' + username + '&code=' + code);
    }

    resetPasswordRequet(email: string) {
        return this.http.get(this.baseUrl + 'user/ResetPasswordRequest?email=' + email);
    }

    resetPassword(email: string, newPassword: string, code: string) {
        return this.http.get(this.baseUrl + 'user/ResetPassword?email=' + email + '&newPassword=' + newPassword + '&code=' + code);
    }

    changeEmailRequest(changeEmailModel: ChangeEmailModel) {
        return this.http.post(this.baseUrl + 'user/ChangeEmailRequestCode', changeEmailModel);
    }

    changeEmail(newEmail: string, code: string) {
        return this.http.post(this.baseUrl + 'user/ChangeEmailCode',{'newEmail': newEmail, 'code' : code});
    }

    changeEmailByCode(newEmail: string, code: string) {
        return this.http.post(this.baseUrl + 'user/ChangeEmailCode',{'newEmail': newEmail, 'code' : code});
    }
}
