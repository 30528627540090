<div class="container">
    <div *ngIf="loading" class="spinner-border text-info" role="status">
        <span i18n class="sr-only">Loading...</span>
    </div>
    <div *ngIf="!loading" class="row">
        <div class="col overflow-auto" style="height: 80vh;">
            <h3 i18n>List of all books</h3>
            <div class="d-flex align-content-center flex-wrap">
                <ul class="list-group">
                    <li *ngFor="let book of books" class="list-group-item">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="book.selected">
                                {{book.name}}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col overflow-auto" style="height: 80vh;">
            <h3 i18n *ngIf="category.id==0">List of books in category root</h3>
            <h3 i18n *ngIf="category.id!=0">List of books in category {{category.name}}</h3>
            <div class="d-flex align-content-center flex-wrap">
                <ul class="list-group">
                    <ng-container *ngFor="let book of books">
                        <li *ngIf="book.selected" class="list-group-item">
                            <div class="input-group"><span class="input-group-text" i18n>Display Order</span>
                                <input class="form-control" type="number" style="width: 60px;"
                                    [(ngModel)]="book.displayOrder" /> <span class="m-1" style="vertical-align: middle;align-self: center;">{{book.name}}</span>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
        <button type="button" (click)="updateCategoryBooks()" class="btn btn-success" i18n>Save</button>
    </div>
</div>