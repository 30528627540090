import { EditAuthorBookComponent } from './../../book/edit-author-book/edit-author-book.component';
import { Observable } from 'rxjs/internal/Observable';
import { PostCategory } from '../../../_models/posts/postCategory';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { PostService } from 'src/app/_services/post.service';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { DataFile } from 'src/app/_models/dataFile';
import { DataFilesSelectComponent } from 'src/app/helper/data-files-select/data-files-select.component';

@Component({
  selector: 'app-add-post-category',
  templateUrl: './add-post-category.component.html',
  styleUrls: ['./add-post-category.component.css']
})
export class AddPostCategoryComponent implements OnInit {
  pcatForm: FormGroup = new FormGroup({});
  header: string = $localize`Add Category`
  selectedDataFiles: DataFile[] = [];
  attachShow: boolean = false;
  @Input() parentCategory: PostCategory = {
    id: 0,
    parentId: undefined,
    name: '',
    description: '',
    displayOrder: 0,
    publish: false,
    deleted: false,
    dataFileId: undefined
  };
  @Input() category: PostCategory = {
    id: 0,
    parentId: undefined,
    name: '',
    description: '',
    displayOrder: 0,
    publish: false,
    deleted: false,
    dataFileId: undefined
  };

  @Output() onSave = new EventEmitter<PostCategory>();
  @Output() onEdit = new EventEmitter<PostCategory>();

  @ViewChild(DataFilesSelectComponent) dataFilesSelect!: DataFilesSelectComponent;

  constructor(private postService: PostService) { }

  ngOnInit(): void {
    this.createPostCategoryForm();
    if (this.category.id > 0) {
      this.header = $localize`Edit` + " " + this.category.name
    }
  }

  createPostCategoryForm() {
    this.pcatForm = new FormGroup({
      name: new FormControl(this.category.name, Validators.required),
      description: new FormControl(this.category.description, Validators.required),
      displayOrder: new FormControl(this.category.displayOrder, Validators.required),
      publish: new FormControl(this.category.publish),
    });
  }

  fileSelectChanged(dataFile: DataFile) {
    if (dataFile.selected) {
        this.selectedDataFiles = [];
        this.selectedDataFiles.push(dataFile);
    } else {
        this.selectedDataFiles = [];
    }
  }
  
  dataFileSelectLoaded(){
    this.dataFilesSelect.getDataFiles().map((v,i)=>{
      if (v.id == this.category.dataFileId) {
            v.selected = true;
        }
    })
  }
  showAttach() {
    this.attachShow = true;
  }
  add() {
    if (this.pcatForm.valid) {
      Object.assign(this.category, this.pcatForm.value);
      if (this.selectedDataFiles.length > 0) {
        this.category.dataFileId = this.selectedDataFiles[0].id;
      }
      if(this.parentCategory.id>0){
        this.category.parentId = this.parentCategory.id
      }
      this.postService.addCategory(this.category).subscribe((restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          this.onSave.emit(restResponse.data);
        }
      });
    }
  }

  edit() {
    if (this.pcatForm.valid) {
      Object.assign(this.category, this.pcatForm.value);
      if (this.selectedDataFiles.length > 0) {
        this.category.dataFileId = this.selectedDataFiles[0].id;
      }
      this.postService.editCategory(this.category).subscribe((restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          this.onEdit.emit(this.category);
        }
      });
    }
  }
  onFormSubmit() {
    if (this.category.id <= 0) {
      this.add();
    } else {
      this.edit();
    }
  }
}
