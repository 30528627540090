import { BookFileManagementComponent } from './admin/book/book-file-management/book-file-management.component';
import { CategoryBookListComponent } from './admin/book/category-book-list/category-book-list.component';
import { PostManagmentComponent } from './admin/post/post-managment/post-managment.component';
import { CategoryListComponent } from './admin/faq/category-list/category-list.component';
import { AddQuestionComponent } from './admin/faq/add-question/add-question.component';
import { QuestionsListComponent } from './admin/faq/questions-list/questions-list.component';
import { AnswerTheQuestionComponent } from './admin/faq/answer-the-question/answer-the-question.component';
import { FaqComponent } from './admin/faq/faq.component';
import { FaqHomeComponent } from './admin/faq/faq-home/faq-home.component';
import { BookCategoryManagementComponent } from './admin/book/book-category-management/book-category-management.component';
import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register/register.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { UserPanelComponent } from './user/user-panel/user-panel.component';
import { UserEditResolver } from './_resolvers/user-edit.resolver';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ChangeEmailComponent } from './user/change-email/change-email.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { AddCategoryComponent } from './admin/book/add-category/add-category.component';
import { AddBookComponent } from './admin/book/add-book/add-book.component';
import { AddBookFileComponent } from './admin/book/add-book-file/add-book-file.component';
import { EditBookFileComponent } from './admin/book/edit-book-file/edit-book-file.component';
import { EditBookComponent } from './admin/book/edit-book/edit-book.component';
import { EditCategoryComponent } from './admin/book/edit-category/edit-category.component';
import { CategoryEditResolver } from './_resolvers/category-edit.resolver';
import { BookEditResolver } from './_resolvers/book-edit.resolver';
import { AuthorListResolver } from './_resolvers/author-list.resolver';
import { AddAuthorComponent } from './admin/book/add-author/add-author.component';
import { EditAuthorComponent } from './admin/book/edit-author/edit-author.component';
import { AuthorEditResolver } from './_resolvers/author-edit.resolver';
import { AuthorManagementComponent } from './admin/book/author-management/author-management.component';
import { AddAuthorBookComponent } from './admin/book/add-author-book/add-author-book.component';
import { CategoryListResolver } from './_resolvers/category-list.resolver';
import { EditAuthorBookComponent } from './admin/book/edit-author-book/edit-author-book.component';
import { AuthorsWithBooksResolver } from './_resolvers/authors-with-books.resolver';
import { ConfirmEmailComponent } from './user/confirm-email/confirm-email.component';
import { LibraryManagementComponent } from './admin/book/library-management/library-management.component';
import { BookContentManagementComponent } from './admin/book/book-content-management/book-content-management.component';
import { BookContentResolver } from './_resolvers/book-content-resolver';
import { AddBookContentComponent } from './admin/book/add-book-content/add-book-content.component';
import { BookNameListResolver } from './_resolvers/book-name-list-resolver';
import { EditBookContentComponent } from './admin/book/edit-book-content/edit-book-content.component';
import { BookContentEditResolver } from './_resolvers/book-content-edit-resolver';
import { BookManagementComponent } from './admin/book/book-management/book-management.component';
import { BookResolver } from './_resolvers/book-resolver';
import { BookNameListWithoutContentResolver } from './_resolvers/book-name-list-without-content-resolver';
import { PostHomeComponent } from './admin/post/post-home/post-home.component';


export const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'forgotPassword', component: ForgotPasswordComponent },
    { path: 'changeEmail', component: ChangeEmailComponent },
    { path: 'confirmEmail', component: ConfirmEmailComponent },
    { path: 'resetPassword', component: ResetPasswordComponent },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            { path: 'fetch-data', component: FetchDataComponent, data: { roles: ['Admin'] } },
            { path: 'user/:id/ChangePassword', component: UserPanelComponent },
            { path: 'user', component: UserPanelComponent, resolve: { user: UserEditResolver } },
            { path: 'admin', component: AdminPanelComponent, data: { roles: ['Admin'] } },
            { path: 'userManagement', component: UserManagementComponent, data: { roles: ['Admin'] } },
            { path: 'libraryManagement', component: LibraryManagementComponent, children: [
                    { path: 'authorManagement', component: AuthorManagementComponent
                        , data: { roles: ['Admin'] }, resolve: { authors: AuthorsWithBooksResolver }  },
                    { path: 'bookContentManagement', component: BookContentManagementComponent
                        , data: { roles: ['Admin'] }, resolve: { bookContents: BookContentResolver }  },
                    { path: 'addAuthor', component: AddAuthorComponent, data: { roles: ['Admin'] } },
                    { path: 'bookManagement', component: BookManagementComponent, data: { roles: ['Admin'] }, resolve : { books : BookResolver} },
                    { path: 'bookFileManagement', component: BookFileManagementComponent, data: { roles: ['Admin'] }, resolve : { books : BookResolver} },
                    { path: 'bookCategoryManagement', component: BookCategoryManagementComponent, data: { roles: ['Admin'] }},
                    { path: 'bookCategoryManagement/:id', component: BookCategoryManagementComponent, data: { roles: ['Admin'] }},
                    { path: 'addBookContent', component: AddBookContentComponent
                        , data: { roles: ['Admin'] }, resolve: { books : BookNameListWithoutContentResolver } },
                    { path: 'editBookContent/:id', component: EditBookContentComponent
                        , data: { roles: ['Admin'] }, resolve: { bookContent: BookContentEditResolver, books : BookNameListResolver } },
                    { path: 'addBookContent/:bookId', component: AddBookContentComponent
                        , data: { roles: ['Admin'] }, resolve: { books : BookNameListWithoutContentResolver } },
                    { path: 'addCategory/:parentId', component: AddCategoryComponent, data: { roles: ['Admin'] } },
                    { path: 'categoryBooksList/:categoryId', component: CategoryBookListComponent, data: { roles: ['Admin'] } },
                    { path: 'addCategory', component: AddCategoryComponent, data: { roles: ['Admin'] } },
                    { path: 'addBook', component: AddBookComponent
                        , data: { roles: ['Admin'] }, resolve: { authors: AuthorListResolver } },
                    { path: 'addAuthorBook/:authorId', component: AddAuthorBookComponent
                        , data: { roles: ['Admin'] }, resolve: { categories: CategoryListResolver } },
                    { path: 'addBookFile', component: AddBookFileComponent
                        , data: { roles: ['Admin'] } },
                    { path: 'editCategory/:id', component: EditCategoryComponent
                        , data: { roles: ['Admin'] }, resolve: { category: CategoryEditResolver }  },
                    { path: 'editBook/:id', component: EditBookComponent
                        , data: { roles: ['Admin'] }, resolve: { book: BookEditResolver, authors: AuthorListResolver }  },
                    { path: 'editAuthorBook/:id', component: EditAuthorBookComponent
                        , data: { roles: ['Admin'] }, resolve: { book: BookEditResolver, categories: CategoryListResolver } },
                    { path: 'editBookFile', component: EditBookFileComponent
                        , data: { roles: ['Admin'] }},
                    { path: 'editAuthor/:id', component: EditAuthorComponent
                        , data: { roles: ['Admin'] }, resolve: { author: AuthorEditResolver } },]},
            { path: 'faq', component: FaqComponent, children:[
                { path: 'home', component: FaqHomeComponent, data: { roles: ['Admin', 'Answerer'] } },
                { path: 'questions', component: QuestionsListComponent, data: { roles: ['Admin', 'Answerer'] } },
                { path: 'category-list', component: CategoryListComponent, data: { roles: ['Admin', 'Answerer'] } },
                { path: 'add-question', component: AddQuestionComponent, data: { roles: ['Admin', 'Answerer'] } },
                { path: 'answer-the-question/:id', component: AnswerTheQuestionComponent, data: { roles: ['Admin', 'Answerer'] } },
            ]},
            { path: 'post', children:[
                { path: 'home', component: PostHomeComponent, data: { roles: ['Admin', 'Post'] } },
                { path: 'management/:id', component: PostManagmentComponent, data: { roles: ['Admin', 'Post'] } },
                { path: 'management', component: PostManagmentComponent, data: { roles: ['Admin', 'Post'] } },
            ]}
        ]
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];
