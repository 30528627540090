<div *ngFor="let book of sortedBooks" class="card">
  <!-- <img *ngIf="book.imageUrl" class="card-img-top" [src]="book.imageUrl" alt="Card image cap"> -->
  <div class="card-header">
    {{book.name}}
    <span class="pull-right">
      <a [routerLink]="[editBookLink+book.id]" title="Edit {{book.name}}" class="fa fa-edit btn btn-default btn-sm"></a>
      <a *ngIf="book.bookFiles.length <= 0"  title="Delete {{book.name}}" (click)="deleteBook(sortedBooks, book);" class="fa fa-trash btn btn-default btn-sm"></a>
    </span>
  </div>
  <div class="card-body">
    <p class="card-text">{{book.description}}</p>
    <p class="card-text"><small class="text-muted">{{book.releaseDate}}</small></p>
  </div>
  <app-list-group-book-file *ngIf="book.bookFiles.length > 0" [bookFiles]="book.bookFiles" [returnUrl]="returnUrl"></app-list-group-book-file>
  <div *ngIf="book.bookContentId" class="bg-info text-center"><a [routerLink]="['/libraryManagement/editBookContent/'+book.bookContentId]" title="Edit {{book.name}} text" class="fa fa-edit btn btn-default btn-sm" i18n>This book have text</a></div>
  <div *ngIf="book.releaseDate" class="card-footer text-center">
    <a [routerLink]="['/libraryManagement/addBookFile']" [queryParams]="{id:book.id,returnUrl:returnUrl}" class="btn btn-primary"><i class="fa fa-plus"></i><span i18n>Add book file</span></a>
  </div>
</div>
