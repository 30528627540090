import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BookCategory } from '../../../_models/bookCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
    editCategoryForm!: UntypedFormGroup;
    category = {} as BookCategory;

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.category = data['category'].data;
            this.createaddCategoryForm();
        });
    }

    createaddCategoryForm() {
        this.editCategoryForm = this.fb.group({
            name: [this.category.name, Validators.required],
            description: [this.category.description, Validators.required],
            displayOrder: [this.category.displayOrder, Validators.required],
        });
    }

    editCategory() {
        if (this.editCategoryForm.valid) {
            Object.assign(this.category, this.editCategoryForm.value);
            this.bookService.editCategory(this.category).subscribe(() => {
                this.alertify.success('Edited successfully');
                this.router.navigate(['/libraryManagement/bookManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/bookManagement']);
    }
}
