import { Injectable } from "@angular/core";
import { CacheData } from "../app.component";

@Injectable({
    providedIn: 'root'
  })
  export class CacheService {
    set(key: string, data: object): void {
      if (CacheData.data.has(key)) {
        this.clear(key);
      }
      CacheData.data.set(key, data);
    }
  
    get(key: string): object | undefined{
      return CacheData.data.get(key);
    }
  
    clear(key: string): void {
        CacheData.data.delete(key);
    }
  }