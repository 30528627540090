import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { BookCategory } from "../_models/bookCategory";
import { Observable } from "rxjs";
import { Author } from "../_models/author";
import { BookContent } from "../_models/bookContent";
import { Book } from '../_models/book';
import { RestResponse } from "../_models/restResponse";

@Injectable({
    providedIn: 'root'
})
export class BookService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getCategoriesWithBooks(categoryId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/categoriesWithBooks/'+categoryId);
    }

    getCategoryBooksName(categoryId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/categoryBooksName/'+categoryId);
    }

    getCategories() {
        return this.http.get(this.baseUrl + 'books/categories');
    }

    getAuthorsWithBooks(): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/authorsWithBooks');
    }

    getCategory(categoryId: number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/categoryDetails/' + categoryId);
    }

    getCategoryById(categoryId: number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/category/' + categoryId);
    }

    updateCategoryBooks(cbs: any): Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'books/updateCategoryBooks', cbs);
    }

    addCategory(category: BookCategory) {
        return this.http.post(this.baseUrl + 'books/addCategory', category);
    }

    editCategory(category: BookCategory) {
        return this.http.put(this.baseUrl + 'books/editCategory', category);
    }

    deleteCategory(categoryId: number): Observable<RestResponse> {
        return this.http.delete<RestResponse>(this.baseUrl + 'books/deleteCategory/' + categoryId);
    }

    getAuthors() {
        return this.http.get(this.baseUrl + 'books/authors');
    }

    getAuthor(id: number) : Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/authorDetails/' + id);
    }

    addAuthor(author: any) {
        return this.http.post(this.baseUrl + 'books/addAuthor', author);
    }

    editAuthor(author: any) {
        return this.http.put(this.baseUrl + 'books/editAuthor', author);
    }

    deleteAuthor(id: number) {
        return this.http.delete(this.baseUrl + 'books/deleteAuthor/' + id);
    }

    getBook(bookId: number) : Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/bookDetails/' + bookId);
    }

    getBooksNameList() : Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/booksNameList');
    }
    
    addBook(book: any) : Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'books/addBook', book);
    }

    editBook(book: any) {
        return this.http.put(this.baseUrl + 'books/editBook', book);
    }

    deleteBook(bookId: number): Observable<RestResponse> {
        return this.http.delete<RestResponse>(this.baseUrl + 'books/deleteBook/' + bookId);
    }

    getBookFile(bookFileId: number) : Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'books/bookFileDetails/' + bookFileId);
    }

    addBookFile(bookFile: any) {
        return this.http.post(this.baseUrl + 'books/addBookFile', bookFile);
    }

    editBookFile(bookFile: any) {
        return this.http.put(this.baseUrl + 'books/editBookFile', bookFile);
    }

    deleteBookFile(bookFileId: number) {
        return this.http.delete(this.baseUrl + 'books/deleteBookFile/' + bookFileId);
    }

    addBookContent(bookContent: any) {
        return this.http.post(this.baseUrl + 'books/addBookContent', bookContent);
    }

    editBookContent(bookContent: any) {
        return this.http.put(this.baseUrl + 'books/editBookContent', bookContent);
    }

    deleteBookContent(bookContentId: number) {
        return this.http.delete(this.baseUrl + 'books/deleteBookContent/' + bookContentId);
    }

    getBookContents() : Observable<BookContent[]>{
        return this.http.get<BookContent[]>(this.baseUrl + 'books/bookContents');
    }

    getBookContent(id: number) : Observable<RestResponse>{
        return this.http.get<RestResponse>(this.baseUrl + 'books/bookContentDetails/' + id);
    }

    getBooksNames(){
        return this.http.get(this.baseUrl + 'books/bookNameList');
    }

    getBooks() : Observable<Book[]>{
        return this.http.get<Book[]>(this.baseUrl + 'books/bookList');
    }

    getBooksNamesWithoutContent(){
        return this.http.get(this.baseUrl + 'books/bookNameListWithoutContent');
    }
}
