<div class="container text-center">
  <form [formGroup]="rpf" (ngSubmit)="resetPassword()" class="form-signin">
    <i class="fa fa-5x fa-lock mb-4 mt-4"></i>
    <h1 i18n class="h3 mb-3 font-weight-normal">New Password</h1>
    <div class="form-group">
      <input type="password"
             [ngClass]="{'is-invalid': rpf.get('newPassword')?.errors
        && rpf.get('newPassword')?.touched}"
             class="form-control m-2"
             id="newPassword"
             formControlName="newPassword"
             placeholder="New Password">
      <div i18n class="invalid-feedback"
           *ngIf="rpf.get('newPassword')?.hasError('required')
          && rpf.get('newPassword')?.touched">
        New Password is required
      </div>
      <div i18n class="invalid-feedback"
           *ngIf="rpf.get('newPassword')?.hasError('minlength')
        && rpf.get('newPassword')?.touched">
        New password must be at least 8 characters
      </div>
      <div i18n class="invalid-feedback"
           *ngIf="rpf.get('newPassword')?.hasError('strong')
        && rpf.get('newPassword')?.touched">
        The new password must include lowercase, uppercase and numbers
      </div>
    </div>

    <div class="form-group">
      <input [ngClass]="{'is-invalid': rpf.get('confirmPassword')?.errors
          && rpf.get('confirmPassword')?.touched
          || rpf.get('confirmPassword')?.touched
          && rpf.hasError('mismatch')}"
             type="password"
             class="form-control m-2"
             id="confirmPassword"
             formControlName="confirmPassword"
             placeholder="Confirm Password">
      <div i18n class="invalid-feedback"
           *ngIf="rpf.get('confirmPassword')?.hasError('required')
          && rpf.get('confirmPassword')?.touched">
        Confirm Password is required
      </div>
      <div i18n class="invalid-feedback"
           *ngIf="rpf.hasError('mismatch')
        && rpf.get('confirmPassword')?.touched">
        Passwords must match
      </div>
    </div>
    <button i18n type="submit" class="btn btn-primary" [disabled]="!rpf.valid">Reset</button>
  </form>
</div>
