<form [formGroup]="addBookContentForm" (ngSubmit)="addBookContent()">
  <h2 i18n class="text-center text-primary">Add Book Content</h2>
  <hr>

  <div class="form-group">
    <label i18n>Name</label>
    <input type="text"
           [ngClass]="{'is-invalid': addBookContentForm.get('name')?.errors
        && addBookContentForm.get('name')?.touched}"
           class="form-control"
           formControlName="name"
           placeholder="Name">
    <div i18n class="invalid-feedback">Please choose a name</div>
  </div>
  <div class="form-group">
    <label i18n>Book Name</label>
    <select class="form-select"
    [ngClass]="{'is-invalid': addBookContentForm.get('bookId')?.errors
        && addBookContentForm.get('bookId')?.touched}" formControlName="bookId">
      <option [ngValue]="null" disabled selected>Select book</option>
      <option *ngFor="let book of books" [value]="book.id">{{book.name}}</option>
    </select>
    <div i18n class="invalid-feedback">Please select book</div>
  </div>
  <div class="form-group">
    <label i18n>Text</label>
    <textarea
           [ngClass]="{'is-invalid': addBookContentForm.get('text')?.errors
        && addBookContentForm.get('text')?.touched}"
           class="form-control"
           formControlName="text"
           placeholder="Text"></textarea>
    <div i18n class="invalid-feedback">Please enter book text</div>
  </div>
  <div class="form-group">
    <label i18n>Subjects</label>
    <textarea
           [ngClass]="{'is-invalid': addBookContentForm.get('subjects')?.errors
        && addBookContentForm.get('subjects')?.touched}"
           class="form-control"
           formControlName="subjects"
           placeholder="Subjects"></textarea>
  </div>
  <div class="form-group">
    <label i18n>Foot notes</label>
    <textarea
           [ngClass]="{'is-invalid': addBookContentForm.get('footNotes')?.errors
        && addBookContentForm.get('footNotes')?.touched}"
           class="form-control"
           formControlName="footNotes"
           placeholder="FootNotes"></textarea>
  </div>
  <div class="form-group">
    <label i18n>Book version</label>
    <input type="text"
           [ngClass]="{'is-invalid': addBookContentForm.get('version')?.errors
        && addBookContentForm.get('version')?.touched}"
           class="form-control"
           formControlName="version"
           placeholder="Version">
    <div i18n class="invalid-feedback">Please enter book text version</div>
  </div>
  <div class="form-group">
    <label i18n>Display order</label>
    <input type="number"
           class="form-control"
           formControlName="displayOrder"
           placeholder="Display Order">
  </div>

  <div class="form-group text-center">
    <button i18n class="btn btn-success" [disabled]="!addBookContentForm.valid" type="submit">Save</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
