import { RestResponse, RestResponseStatus } from './../_models/restResponse';
import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
    providedIn: 'root'
})
export class AlertifyService {
    constructor() { }

    confirm(message: string, title: string, okCallback: () => any) {
        alertify.confirm(message, function (e: any) {
            if (e) {
                okCallback();
            } else { }
        }).set({ title: title });
    }

    showMessage(restResponse: RestResponse) {
        switch (restResponse.status) {
            case RestResponseStatus.Success:
                this.success(restResponse.message);
                break;
            case RestResponseStatus.Error:
                this.error(restResponse.message);
                break;
            case RestResponseStatus.Fail:
                this.warning(restResponse.message);
                break;

            default:
                this.message(restResponse.message);
                break;

        }
    }
    success(message: string) {
        alertify.success(message);
    }

    error(message: string) {
        alertify.error(message);
    }

    warning(message: string) {
        alertify.warning(message);
    }

    message(message: string) {
        alertify.message(message);
    }
}
