import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { LocalService } from '../_services/local.service';
import { Language } from '../_models/language';

@Injectable()
export class LanguagesResolver implements Resolve<Language> {
    constructor(private localService: LocalService, private router: Router,
        private alertify: AlertifyService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Language> {
        return this.localService.getLanguages().pipe(
            catchError(error => {
                this.alertify.error($localize`Problem retrieving your data`);
                this.router.navigate(['/home']);
                return of(null);
            })
        ) as Observable<Language>;
    }
}
