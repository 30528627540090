<div *ngIf="loading" class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div *ngIf="!loading" class="container">
    <h3 i18n>Library for english books (Categories)</h3>
    <div *ngIf="bookCats!.bookCategoryDto" class="text-center">
      <h4>{{bookCats!.bookCategoryDto.name}}</h4>
      <h5>{{bookCats!.bookCategoryDto.description}}</h5>
    </div>
    <div *ngIf="!bookCats!.bookCategoryDto" class="text-right">
      <a [routerLink]="['/libraryManagement/addCategory']" class="btn btn-default"><span class="fa fa-plus-circle"></span><span i18n>Add Root Category</span></a>
      <a [routerLink]="['/libraryManagement/categoryBooksList/0']" class="btn btn-default"><span class="fa fa-plus-circle"></span><span i18n>Add Root Book</span></a>
    </div>
    <div *ngIf="bookCats!.bookCategoryDto" class="text-right">
      <a [routerLink]="['/libraryManagement/addCategory/'+bookCats!.bookCategoryDto.id]" class="btn btn-default"><span class="fa fa-plus-circle"></span><span i18n>Add Sub Category in {{bookCats!.bookCategoryDto.name}}</span></a>
      <a [routerLink]="['/libraryManagement/categoryBooksList/'+bookCats!.bookCategoryDto.id]" class="btn btn-default"><span class="fa fa-plus-circle"></span><span i18n>Add Book in {{bookCats!.bookCategoryDto.name}}</span></a>
    </div>
    <div>
      <div class="list-group list-group-root well">
        <app-list-group-category [booksAndCategories]="bookCats"></app-list-group-category>
      </div>
    </div>
  </div>
  