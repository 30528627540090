<nav
  aria-label="Page navigation example"
  [ngClass]="{ collapse: totalPages <= 1 }"
>
  <ul class="pagination">
    <li
      class="page-item"
      [ngClass]="{ disabled: currentPage == i + 1 }"
      *ngFor="let page of [].constructor(actualCount); let i = index"
    >
      <button class="page-link" (click)="goToPage(i + 1)">{{ i + 1 }}</button>
    </li>
    <li class="page-item disabled" *ngIf="more">
      <a class="page-link" href="#">...</a>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: currentPage == totalPages }"
      *ngIf="more"
    >
      <button class="page-link" (click)="goToPage(totalPages)">
        {{ totalPages }}
      </button>
    </li>
    <li class="page-item" [ngClass]="{ collapse: !more }">
      <div class="input-group">
        <input
          type="number"
          [max]="totalPages"
          [min]="1"
          [(ngModel)]="inputPageValue"
          class="form-control"
        />
        <div class="input-group-append">
          <button class="btn btn-primary" (click)="goToPage(inputPageValue)">
            <i class="fa fa-search" aria-hidden="true"></i>
            <span ></span>
          </button>
        </div>
      </div>
    </li>
  </ul>
</nav>
