import { CommonHelper } from './helper/commonHelper';
import { PostService } from 'src/app/_services/post.service';
import { DataFileService } from './_services/dataFile.service';
import { DataFilesSelectComponent } from './helper/data-files-select/data-files-select.component';
import { AddPostComponent } from './admin/post/add-post/add-post.component';
import { PostManagmentComponent } from './admin/post/post-managment/post-managment.component';
import { PostHomeComponent } from './admin/post/post-home/post-home.component';
import { PaginationComponent } from './helper/pagination/pagination.component';
import { LocalizeDatePipe } from './helper/pipes/date.converter.pipe';
import { FaqHomeComponent } from './admin/faq/faq-home/faq-home.component';
import { FaqService } from './_services/faq.service';
import { BookCategoryManagementComponent } from './admin/book/book-category-management/book-category-management.component';
import { BookContentManagementComponent } from './admin/book/book-content-management/book-content-management.component';
import { BookFileManagementComponent } from './admin/book/book-file-management/book-file-management.component';
import { LibraryManagementComponent } from './admin/book/library-management/library-management.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthService } from './_services/auth.service';
import { AlertifyService } from './_services/alertify.service';
import { AuthGuard } from './_guards/auth.guard';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { LanguageInterceptorProvider } from './_services/language-interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { appRoutes } from './routes';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { HasRoleDirective } from './_directives/has-role.directive';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
   
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { RolesModalComponent } from './admin/roles-modal/roles-modal.component';
import { AdminService } from './_services/admin.service';
import { UserPanelComponent } from './user/user-panel/user-panel.component';
import { UserService } from './_services/user.service';
import { UserEditResolver } from './_resolvers/user-edit.resolver';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ChangeEmailComponent } from './user/change-email/change-email.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { BookService } from './_services/book.service';
import { BookManagementComponent } from './admin/book/book-management/book-management.component';
import { AddCategoryComponent } from './admin/book/add-category/add-category.component';
import { LanguagesResolver } from './_resolvers/languages.resolver';
import { LocalService } from './_services/local.service';
import { AddBookComponent } from './admin/book/add-book/add-book.component';
import { AddBookFileComponent } from './admin/book/add-book-file/add-book-file.component';
import { ListGroupCategoryComponent } from './admin/book/list-group-category/list-group-category.component';
import { UploadComponent } from './helper/upload/upload.component';
import { UploadService } from './_services/upload.service';
import { EditBookFileComponent } from './admin/book/edit-book-file/edit-book-file.component';
import { EditCategoryComponent } from './admin/book/edit-category/edit-category.component';
import { EditBookComponent } from './admin/book/edit-book/edit-book.component';
import { BookEditResolver } from './_resolvers/book-edit.resolver';
import { BookFileEditResolver } from './_resolvers/book-file-edit.resolver';
import { CategoryEditResolver } from './_resolvers/category-edit.resolver';
import { AuthorListResolver } from './_resolvers/author-list.resolver';
import { SortByPipe } from './helper/sort-by.pipe';
import { AddAuthorComponent } from './admin/book/add-author/add-author.component';
import { EditAuthorComponent } from './admin/book/edit-author/edit-author.component';
import { AuthorEditResolver } from './_resolvers/author-edit.resolver';
import { AuthorManagementComponent } from './admin/book/author-management/author-management.component';
import { ListGroupAuthorComponent } from './admin/book/list-group-author/list-group-author.component';
import { AddAuthorBookComponent } from './admin/book/add-author-book/add-author-book.component';
import { EditAuthorBookComponent } from './admin/book/edit-author-book/edit-author-book.component';
import { CategoryListResolver } from './_resolvers/category-list.resolver';
import { BookCardComponent } from './admin/book/book-card/book-card.component';
import { ListGroupBookFileComponent } from './admin/book/list-group-book-file/list-group-book-file.component';
import { AuthorsWithBooksResolver } from './_resolvers/authors-with-books.resolver';
import { ConfirmEmailComponent } from './user/confirm-email/confirm-email.component';
import { BookContentResolver } from './_resolvers/book-content-resolver';
import { AddBookContentComponent } from './admin/book/add-book-content/add-book-content.component';
import { BookNameListResolver } from './_resolvers/book-name-list-resolver';
import { BookContentEditResolver } from './_resolvers/book-content-edit-resolver';
import { EditBookContentComponent } from './admin/book/edit-book-content/edit-book-content.component';
import { BookResolver } from './_resolvers/book-resolver';
import { BookNameListWithoutContentResolver } from './_resolvers/book-name-list-without-content-resolver';
import { CommonModule, DatePipe } from '@angular/common';
import { AnswerTheQuestionComponent } from './admin/faq/answer-the-question/answer-the-question.component';
import { FaqComponent } from './admin/faq/faq.component';
import { ImportancePipe } from './helper/pipes/importance.pipe';
import { QuestionsListComponent } from './admin/faq/questions-list/questions-list.component';
import { AddQuestionComponent } from './admin/faq/add-question/add-question.component';
import { CategoryListComponent } from './admin/faq/category-list/category-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddPostCategoryComponent } from './admin/post/add-post-category/add-post-category.component';
import { ImageDirective } from './_directives/default-image';
import { CategoryBookListComponent } from './admin/book/category-book-list/category-book-list.component';
import { FlagsSelectComponent } from './helper/flags-select/flags-select.component';
import { CaptchaService } from './_services/captcha.service';
import { CacheService } from './_services/cache.service';
//import localeFa from '@angular/common/locales/fa';
//import localeFaExtra from '@angular/common/locales/extra/fa';

//registerLocaleData(localeFa, 'fa-IR', localeFaExtra);

export function tokenGetter() {
    return localStorage.getItem('token');
}

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        RegisterComponent,
        FetchDataComponent,
        HasRoleDirective,
        ImageDirective,
        UserManagementComponent,
        AdminPanelComponent,
        RolesModalComponent,
        UserPanelComponent,
        ResetPasswordComponent,
        ChangeEmailComponent,
        ForgotPasswordComponent,
        BookManagementComponent,
        ListGroupCategoryComponent,
        AddCategoryComponent,
        AddBookComponent,
        AddBookFileComponent,
        EditBookFileComponent,
        EditCategoryComponent,
        EditBookComponent,
        SortByPipe,
        UploadComponent,
        AddAuthorComponent,
        EditAuthorComponent,
        AuthorManagementComponent,
        ListGroupAuthorComponent,
        AddAuthorBookComponent,
        EditAuthorBookComponent,
        BookCardComponent,
        ListGroupBookFileComponent,
        ConfirmEmailComponent,
        LibraryManagementComponent,
        BookFileManagementComponent,
        BookContentManagementComponent,
        BookCategoryManagementComponent,
        AddBookContentComponent,
        EditBookContentComponent,
        FaqHomeComponent,
        LocalizeDatePipe,
        PaginationComponent,
        AnswerTheQuestionComponent,
        FaqComponent,
        ImportancePipe,
        QuestionsListComponent,
        AddQuestionComponent,
        CategoryListComponent,
        PostHomeComponent,
        PostManagmentComponent,
        AddPostCategoryComponent,
        AddPostComponent,
        DataFilesSelectComponent,
        CategoryBookListComponent,
        FlagsSelectComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ButtonsModule.forRoot(),
        RouterModule.forRoot(appRoutes),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['localhost:5050', '127.0.0.1:5002', 'api.ahmedalhasan.org'],
                disallowedRoutes: ['localhost:5050/api/auth', '127.0.0.1:5002/api/auth', 'api.ahmedalhasan.org/api/auth'] //, 'apitest.ahmedalhasan.org/api/auth'
            }
        })
    ],
    providers: [
        AuthService,
        ErrorInterceptorProvider,
        LanguageInterceptorProvider,
        AlertifyService,
        AuthGuard,
        AdminService,
        UserService,
        BookService,
        UserEditResolver,
        LanguagesResolver,
        BookEditResolver,
        AuthorEditResolver,
        BookFileEditResolver,
        CategoryEditResolver,
        AuthorListResolver,
        CategoryListResolver,
        AuthorsWithBooksResolver,
        BookContentResolver,
        BookNameListResolver,
        BookNameListWithoutContentResolver,
        BookContentEditResolver,
        BookResolver,
        LocalService,
        UploadService,
        FaqService,
        DatePipe,
        DataFileService,
        PostService,
        CommonHelper,
        CaptchaService,
        CacheService
        //{ provide: LOCALE_ID, useValue: 'fa' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
