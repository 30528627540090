import { PostFile } from './../_models/posts/postFile';
import { DataFile } from './../_models/dataFile';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { RestResponse } from "../_models/restResponse";
import { Observable } from "rxjs/internal/Observable";
import { PostCategory } from "../_models/posts/postCategory";
import { Post } from "../_models/posts/Post";

@Injectable({
    providedIn: 'root'
})
export class PostService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getCategories(parentId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/categories/'+ parentId);
    }
    
    getCategoryDetails(id:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/category/'+ id);
    }
    
    getCategoryHierarchy(id:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/categoryHierarchy/'+ id);
    }

    getCategoriesWithDeleted(parentId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/categoriesWithDeleted/'+ parentId);
    }

    addCategory(category:PostCategory): Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'post/addCategory/', category);
    }

    editCategory(category : PostCategory): Observable<RestResponse>{
        return this.http.put<RestResponse>(this.baseUrl + "post/editCategory", category);
    }

    deleteCategory(postCategoryId : number): Observable<RestResponse>{
        return this.http.delete<RestResponse>(this.baseUrl + `post/deleteCategory/${postCategoryId}`);
    }

    undeleteCategory(postCategoryId : number): Observable<RestResponse>{
        return this.http.delete<RestResponse>(this.baseUrl + `post/undeleteCategory/${postCategoryId}`);
    }

    getPosts(categoryId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/posts/'+ categoryId);
    }
    getPostFlagsKeyValue(): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/postFlagsKeyValue');
    }
    getPostsWithDeleted(categoryId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/postsWithDeleted/'+ categoryId);
    }

    addPost(post:Post): Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'post/addPost/', post);
    }

    editPost(post : Post): Observable<RestResponse>{
        return this.http.put<RestResponse>(this.baseUrl + "post/editPost", post);
    }

    deletePost(postId : number): Observable<RestResponse>{
        return this.http.delete<RestResponse>(this.baseUrl + `post/deletePost/${postId}`);
    }

    undeletePost(postId : number): Observable<RestResponse>{
        return this.http.delete<RestResponse>(this.baseUrl + `post/undeletePost/${postId}`);
    }
    
    addPostFile(postFile:any): Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'post/addPostFile/', postFile);
    }

    editPostFile(postFile : PostFile): Observable<RestResponse>{
        return this.http.put<RestResponse>(this.baseUrl + "post/editPostFile", postFile);
    }

    deletePostFile(postFileId : number): Observable<RestResponse>{
        return this.http.delete<RestResponse>(this.baseUrl + `post/deletePostFile/${postFileId}`);
    }

    deletePostFiles(postId : number): Observable<RestResponse>{
        return this.http.delete<RestResponse>(this.baseUrl + `post/deletePostFiles/${postId}`);
    }

    getPostFiles(postId:Number): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'post/postfiles/'+ postId);
    }
}