<div class="container">
  <div class="row">
    <div *ngIf="loading" class="spinner-border text-info" role="status">
      <span i18n class="sr-only">Loading...</span>
    </div>
    <div *ngIf="!loading">
      <button i18n
          type="button"
          (click)="addCat()"
          class="btn btn-primary m-2"
        >
        <i class="fa fa-plus"></i>Add</button>
      <table class="table m-1">
        <thead>
          <tr>
            <th i18n>Number</th>
            <th i18n>Publish</th>
            <th i18n>Title</th>
            <th i18n>Deleted</th>
          </tr>
        </thead>
        <tr *ngFor="let category of questionCategories">
          <td>{{ category.id }}</td>
          <td>
            <input
              type="checkbox"
              class="form-check-input"
              disabled
              [checked]="category.publish"
            />
          </td>
          <td>{{ category.title }}</td>
          <td>
            <input
              type="checkbox"
              class="form-check-input"
              disabled
              [checked]="category.deleted"
            />
          </td>
          <td>
            <div>
              <button i18n
                type="button"
                (click)="editCat(category.id)"
                class="btn btn-info m-2"
              >
                <i class="fa fa-edit"></i>
                Edit
              </button>
              <button i18n *ngIf="!category.deleted"
                type="button"
                (click)="deleteCat(category.id)"
                class="btn btn-danger m-2"
              >
                <i class="fa fa-trash-can"></i>
                Delete
              </button>
              <button i18n *ngIf="category.deleted"
                type="button"
                (click)="undeleteCat(category.id)"
                class="btn btn-success m-2"
              >
              <i class="fa fa-trash-restore-alt"></i>
                Undelete
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div *ngIf="editCategory">
        <form>
          <div class="form-group">
            <label i18n for="title">Title</label>
            <input
              type="text"
              class="form-control"
              id="title"
              name="title"
              [(ngModel)]="editCategory.title"
            />
          </div>
          <div class="form-group">
            <label i18n for="publish">Publish</label>
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="editCategory.publish"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <button i18n
          type="button"
          (click)="saveCat()"
          class="btn btn-success m-2"
        >
        <i class="fa fa-save"></i>
          Save
        </button>
        </form>
        {{ editCategory.title }}
      </div>
    </div>
  </div>
</div>
