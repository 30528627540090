import { BookCategory } from './../../../_models/bookCategory';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookName } from 'src/app/_models/bookName';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BookService } from 'src/app/_services/book.service';

@Component({
  selector: 'app-category-book-list',
  templateUrl: './category-book-list.component.html',
  styleUrls: ['./category-book-list.component.css']
})
export class CategoryBookListComponent implements OnInit{

  loading : Boolean = true;
  books : BookName[] = [];
  category : BookCategory = {
    id: 0,
    name: '',
    description: '',
    displayOrder: 0,
    parentId: 0,
    childrens: [],
    books: []
  };

  constructor(private route: ActivatedRoute,
    private bookService: BookService,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.bookService.getBooksNameList().subscribe({
      next: (restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          this.books = restResponse.data;
          this.route.params.subscribe(params => {
            var id: Number = 0;
            if (params['categoryId']) { id = parseInt(params['categoryId']) };
            if(id!=0){
              this.bookService.getCategoryById(id as number).subscribe({
                next: (restResponse2: RestResponse)=>{
                  if (restResponse2.status === RestResponseStatus.Success) {
                    this.category = restResponse2.data;
                  }
                },
                error: (error) => {
                  this.alertify.showMessage(error);
                  this.loading = false;
                }
              });
            }
            this.bookService.getCategoryBooksName(id).subscribe({
              next: (restResponse: RestResponse) => {
                if (restResponse.status === RestResponseStatus.Success) {
                  
                  restResponse.data.forEach((element: BookName) => {
                    this.books.filter((ff: BookName) => ff.id == element.id).map(s=>{s.selected = true;s.displayOrder = element.displayOrder})});
                  
                  // this.books.map((h) => {
                  //   if (restResponse.data > 0 && restResponse.data.filter((ff: BookName) => ff.id == h.id).length > 0) {
                  //     h.selected = true;
                  //     h.displayOrder = 
                  //   }
                  // });
                } else {
                  this.alertify.showMessage(restResponse);
                }
                this.loading = false;
              },
              error: (error) => {
                this.alertify.showMessage(error);
                this.loading = false;
              }
            })
          });      
        } else {
          this.alertify.showMessage(restResponse);
          this.loading = false;
        }
      },
      error: (error) => {
        this.alertify.showMessage(error);
      }
    });
  }

  updateCategoryBooks(){
    var data = {
      categoryId : this.category.id,
      idDisplayOrders : this.books.filter((f)=>f.selected==true)
    };
    this.bookService.updateCategoryBooks(data).subscribe({
      next: (restResponse: RestResponse) => {
        this.alertify.showMessage(restResponse)
      },
      error: (error) => {
        this.alertify.showMessage(error);
      }
    });
  }
}
