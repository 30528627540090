<form [formGroup]="registerForm" (ngSubmit)="register()">
  <h2 i18n class="text-center text-primary">Sign Up</h2>
  <hr>

  <div class="form-group">
    <input type="text"
           [ngClass]="{'is-invalid': registerForm.get('username')?.errors
        && registerForm.get('username')?.touched}"
           class="form-control"
           formControlName="username"
           placeholder="Username">
    <div i18n class="invalid-feedback">Please choose a username</div>
  </div>

  <div class="form-group">
    <input type="password"
           [ngClass]="{'is-invalid': registerForm.get('password')?.errors
        && registerForm.get('password')?.touched}"
           class="form-control"
           formControlName="password"
           placeholder="Password">
    <div i18n class="invalid-feedback"
         *ngIf="registerForm.get('password')?.hasError('required')
          && registerForm.get('password')?.touched">
      Password is required
    </div>
    <div i18n class="invalid-feedback"
         *ngIf="registerForm.get('password')?.hasError('minlength')
        && registerForm.get('password')?.touched">
      Password must be at least 8 characters
    </div>
    <div i18n class="invalid-feedback"
         *ngIf="registerForm.get('password')?.hasError('strong')
        && registerForm.get('password')?.touched">
      The password must include lowercase, uppercase and numbers
    </div>
  </div>

  <div class="form-group">
    <input [ngClass]="{'is-invalid': registerForm.get('confirmPassword')?.errors
          && registerForm.get('confirmPassword')?.touched
          || registerForm.get('confirmPassword')?.touched
          && registerForm.hasError('mismatch')}"
           type="password"
           class="form-control"
           formControlName="confirmPassword"
           placeholder="Confirm Password">
    <div i18n class="invalid-feedback"
         *ngIf="registerForm.get('confirmPassword')?.hasError('required')
          && registerForm.get('confirmPassword')?.touched">
      Confirm Password is required
    </div>
    <div i18n class="invalid-feedback"
         *ngIf="registerForm.hasError('mismatch')
        && registerForm.get('confirmPassword')?.touched">
      Passwords must match
    </div>
  </div>
  <img *ngIf="!loading" (click)="changeCaptcha()" [src]="'data:image/jpeg;base64,'+captchaImage" />
  <input type="text" [ngClass]="{'is-invalid': registerForm.get('captchaText')?.errors
  && registerForm.get('captchaText')?.touched}"
     class="form-control"
     formControlName="captchaText"
     placeholder="Captcha">
<div i18n class="invalid-feedback">Please enter the security code.</div>
  <div class="form-group text-center">
    <button i18n class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Register</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>

</form>
