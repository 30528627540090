<div class="container">
    <div class="row">
        <h2 i18n>Book content management</h2>
    </div>
    <div class="float-right">
        <a [routerLink]="['/libraryManagement/addBookContent']"
            class="btn btn-primary"><span i18n class="fa fa-plus">Add book content</span></a>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th i18n scope="col">Name</th>
                <th i18n scope="col">Book Name</th>
                <th i18n scope="col">Created</th>
                <th i18n scope="col">Edited</th>
                <th i18n scope="col">Display Order</th>
                <th i18n scope="col">Version</th>
                <th i18n scope="col">Edit</th>
                <th i18n scope="col">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let bookContent of bookContents">
                <th scope="row">{{bookContent.id}}</th>
                <td>{{bookContent.name}}</td>
                <td>{{bookContent.bookName}}</td>
                <td>{{bookContent.createdOn}}</td>
                <td>{{bookContent.editedOn}}</td>
                <td>{{bookContent.displayOrder}}</td>
                <td>{{bookContent.version}}</td>
                <td>
                    <a [routerLink]="['/libraryManagement/editBookContent/'+bookContent.id]" class="fa fa-edit btn btn-default btn-sm" title="Edit {{bookContent.name}}">
                    </a>
                </td>
                <td>
                    <a (click)="deleteBookContent(bookContents, bookContent)" class="fa fa-trash btn btn-default btn-sm" title="Delete {{bookContent.name}}"></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>