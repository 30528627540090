import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordValidator } from '../../helper/password.validator';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    rpf: UntypedFormGroup = new UntypedFormGroup({});
    email: string | null | undefined;
    code: string | null | undefined;
    constructor(private fb: UntypedFormBuilder, private userService: UserService,
        private alertify: AlertifyService, private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.email = params.get('email');
            this.code = params.get('code');
        });
        this.createRPForm();
    }

    createRPForm() {
        this.rpf = this.fb.group({
            newPassword: ['', [Validators.required, Validators.minLength(8), PasswordValidator.strong]],
            confirmPassword: ['', Validators.required]
        }, { validator: this.passwordMatchValidator });
    }
    passwordMatchValidator(g: UntypedFormGroup) {
        return g.get('newPassword')?.value === g.get('confirmPassword')?.value ? null : { 'mismatch': true };
    }
    resetPassword() {
        if (!this.email || !this.code){
            this.alertify.error($localize`Error in reset password`);
            return;
        }
        let model = Object.assign({}, this.rpf?.value);
        this.userService.resetPassword(this.email, model.newPassword, this.code).subscribe(() => {
            this.alertify.success($localize`Password reset successfully`);
            this.router.navigate(['/home']);
        }, error => {
            this.alertify.error(error);
        });
    }
}
