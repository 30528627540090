import { RestResponse } from './../../_models/restResponse';
import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { forEach } from 'lodash';
import { PostService } from 'src/app/_services/post.service';

@Component({
  selector: 'app-flags-select',
  templateUrl: './flags-select.component.html',
  styleUrls: ['./flags-select.component.css']
})
export class FlagsSelectComponent implements OnInit {

  @Input() flags: any
  @Input() flag: number = 0

  constructor(private postService:PostService){

  }
  ngOnInit(): void {
    // this.postService.getPostFlagsKeyValue().subscribe({
    //   next: (r:RestResponse) => {
    //     this.flags = r.data
    //   }
    // })
  }

  isFlag(f:number):boolean{
    return (this.flag & (1 << f)) !== 0
  }

  setFlags(f:number,event:Event){
    if((<HTMLInputElement>event.target).checked===true){
      this.flag |= 1 << f
    }else{
      this.flag &= ~(1 << f)
    }
    console.log('flag is = '+this.flag);
  }

  getFlag():number{
    return this.flag
  }
}
