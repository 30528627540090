import { QuestionCategory } from './../../../_models/faq/questionCategory';
import { AlertifyService } from './../../../_services/alertify.service';
import { RestResponse, RestResponseStatus } from './../../../_models/restResponse';
import { FaqService } from './../../../_services/faq.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  loading: Boolean = true;
  questionCategories: QuestionCategory[] = [];
  editCategory: QuestionCategory | null | undefined;

  constructor(private faqService: FaqService,
    private alertifyService: AlertifyService) { }

  ngOnInit(): void {
    this.faqService.getQuestionCategoriesWithDeleted().subscribe({
      next: (restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          this.questionCategories = restResponse.data;
          this.loading = false;
        } else {
          if (restResponse.message) {
            this.alertifyService.error(restResponse.message);
          }
        }
      },
      error: (error: any) => {
        this.alertifyService.error(error);
      }
    });
  }

  editCat(catId: number) {
    this.editCategory = Object.assign({}, this.questionCategories.find(c => c.id === catId));
  }

  deleteCat(catId: number) {
    this.faqService.deleteQuestionCategory(catId).subscribe({
      next: (restResponse: RestResponse) => {
        if(restResponse.status==RestResponseStatus.Success){
          this.questionCategories.find(c => c.id === catId)!.deleted = true;
        }else{
          this.alertifyService.showMessage(restResponse);
        }
      }
    })
  }
  undeleteCat(catId: number) {
    this.faqService.undeleteQuestionCategory(catId).subscribe({
      next: (restResponse: RestResponse) => {
        if(restResponse.status==RestResponseStatus.Success){
          this.questionCategories.find(c => c.id === catId)!.deleted = false;
        }else{
          this.alertifyService.showMessage(restResponse);
        }
      }
    })
  }
  saveCat() {
    if(this.editCategory && this.editCategory?.id!>0){
      this.faqService.editQuestionCategory(this.editCategory).subscribe({
        next: (restResponse: RestResponse) => {
          if(restResponse.status==RestResponseStatus.Success){
            var c = this.questionCategories.find(c => c.id === this.editCategory?.id)!;//!.deleted = false;
            c.publish = this.editCategory!.publish;
            c.title = this.editCategory!.title;
            this.editCategory = null;
          }else{
            this.alertifyService.showMessage(restResponse);
          }
        }
      })  
    }else{
      this.faqService.addQuestionCategory(this.editCategory).subscribe({
        next: (restResponse: RestResponse) => {
          if(restResponse.status==RestResponseStatus.Success){
            this.editCategory!.id = restResponse.data.id;
            this.questionCategories.push(this.editCategory!);
            this.editCategory = null;
          }else{
            this.alertifyService.showMessage(restResponse);
          }
        }
      })
    }
  }

  addCat() {
    this.editCategory = {} as QuestionCategory;
  }
}
