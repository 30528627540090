import { Component, OnInit, Input } from '@angular/core';
import { BookFile } from '../../../_models/bookFile';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-list-group-book-file',
    templateUrl: './list-group-book-file.component.html',
    styleUrls: ['./list-group-book-file.component.css']
})
export class ListGroupBookFileComponent implements OnInit {
    @Input() bookFiles: BookFile[] = [];
    @Input() returnUrl: string = "";

    sortedBookFiles: BookFile[] = [];
    constructor(private bookService: BookService,
        private alertify: AlertifyService) { }

    ngOnInit() {
        this.sortedBookFiles = this.bookFiles.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)
    }

    deleteItem(list: any[], item: any) {
        const index = list.indexOf(item);
        if (index > -1)
            list.splice(index, 1);
    }

    deleteBookFile(list: any[], item: { name: any; id: number; }) {
        this.alertify.confirm($localize`Are you sure to delete ${item.name}?`, $localize`Delete book file`, () => {
            this.bookService.deleteBookFile(item.id).subscribe(() => {
                this.alertify.success($localize`Deleted successfully`);
                this.deleteItem(list, item);
            }, error => {
                this.alertify.error(error);
            });
        });
    }
}
