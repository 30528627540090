<form [formGroup]="editAuthorForm" (ngSubmit)="editAuthor()">
  <h2 i18n class="text-center text-primary">Edit Author</h2>
  <hr>

  <div class="form-group">
    <label i18n>First Name</label>
    <input type="text"
           [ngClass]="{'is-invalid': editAuthorForm.get('firstName')?.errors
        && editAuthorForm.get('firstName')?.touched}"
           class="form-control"
           formControlName="firstName"
           placeholder="First Name">
    <div i18n class="invalid-feedback">Please choose a first name</div>
  </div>
  <div class="form-group">
    <label i18n>Last Name</label>
    <input type="text"
           [ngClass]="{'is-invalid': editAuthorForm.get('lastName')?.errors
        && editAuthorForm.get('lastName')?.touched}"
           class="form-control"
           formControlName="lastName"
           placeholder="Last Name">
    <div i18n class="invalid-feedback">Please choose a last name</div>
  </div>
  <div class="form-group">
    <label i18n>Gender</label>
    <select class="form-select"
            [ngClass]="{'is-invalid': editAuthorForm.get('gender')?.errors
        && editAuthorForm.get('gender')?.touched}"
            formControlName="gender">
      <option i18n>Select gender</option>
      <option i18n value="male">Male</option>
      <option i18n value="female">Female</option>
    </select>
    <div i18n class="invalid-feedback">Please select gender</div>
  </div>
  <div class="form-group">
    <label i18n>Bio</label>
    <input type="text"
           [ngClass]="{'is-invalid': editAuthorForm.get('bio')?.errors
        && editAuthorForm.get('bio')?.touched}"
           class="form-control"
           formControlName="bio"
           placeholder="Bio">
    <div i18n class="invalid-feedback">Please enter bio</div>
  </div>
  <div class="form-group">
    <label i18n>Academy</label>
    <input type="text"
           [ngClass]="{'is-invalid': editAuthorForm.get('academy')?.errors
        && editAuthorForm.get('academy')?.touched}"
           class="form-control"
           formControlName="academy"
           placeholder="Academy">
    <div i18n class="invalid-feedback">Please enter academy</div>
  </div>
  <div class="form-group">
    <label i18n>BirthDate</label>
    <input type="text" id="birthDate" class="form-control" placeholder="Enter birth date like (1980-03-13)"
           name="birthDate" formControlName="birthDate" />
  </div>
  <div class="form-group">
    <label i18n>Nationality</label>
    <input type="text"
           [ngClass]="{'is-invalid': editAuthorForm.get('nationality')?.errors
        && editAuthorForm.get('nationality')?.touched}"
           class="form-control"
           formControlName="nationality"
           placeholder="Nationality">
    <div i18n class="invalid-feedback">Please enter nationality</div>
  </div>
  <div class="form-group">
    <label i18n>Religion</label>
    <input type="text"
           [ngClass]="{'is-invalid': editAuthorForm.get('religion')?.errors
        && editAuthorForm.get('religion')?.touched}"
           class="form-control"
           formControlName="religion"
           placeholder="Religion">
    <div i18n class="invalid-feedback">Please enter religion</div>
  </div>
  <div class="form-group">
    <label i18n>Display Order</label>
    <input type="number"
           class="form-control"
           formControlName="displayOrder"
           placeholder="Display Order">
  </div>
  <div class="form-group">
    <label i18n>Author Image</label>
    <app-data-files-select (onLoaded)="dataFileSelectLoaded()" (onFileSelectChanged)="fileSelectChanged($event)" [selectedFiles]="selectedDataFiles" [MultipleFileSelect]="false" dataFilesSelect></app-data-files-select>
    <!-- <app-upload [initFileSelect]="author.imageUrl" [path]="'authors/images'" [buttonContent]="'Upload Image'" (onFileSelected)="fileSelected($event)"></app-upload> -->
    <small i18n>Upload author image or select from list</small>
  </div>
  <div class="form-group text-center">
    <button i18n class="btn btn-success" [disabled]="!editAuthorForm.valid" type="submit">Save</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
