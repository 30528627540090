<div class="container">
  <div class="row">
    <h2 i18n class="text-center text-primary">Answer The Question</h2>
    <hr />
    <div *ngIf="loading" class="spinner-border text-info" role="status">
      <span i18n class="sr-only">Loading...</span>
    </div>
    <div *ngIf="!loading">
      <div class="d-flex flex-row">
        <div class="p-2"><span i18n>Username</span><h5>{{ question!.userName }}</h5></div>
        <div class="p-2"><span i18n>LockedBy</span><h5>{{ question!.lockedBy }}</h5></div>
        <div class="p-2"><span i18n>Importance</span><h5>{{ question!.importance | importance }}</h5></div>
        <div class="p-2"><span i18n>Created On</span><h5>{{ question!.createdOn | localizeDate }}</h5></div>
        <div *ngIf="question!.editedOn" class="p-2"><span i18n>Edited On</span><h5>{{ question!.editedOn | localizeDate }}</h5></div>
        <div *ngIf="question!.deleted" class="p-2"><h5><span i18n>Deleted</span></h5></div>
        <div *ngIf="question!.publish" class="p-2"><h5><span i18n>Published</span></h5></div>
      </div>
      <div class="row"><span i18n>Question?</span><h5>{{ question!.questionText }}</h5>
      </div>
      <form
        class="form-control"
        [formGroup]="answerForm!"
        (ngSubmit)="answerTheQuestion()"
      >
        <div class="form-group">
          <label i18n for="answerText">Answer</label>
          <textarea
            class="form-control"
            [ngClass]="{
              'is-invalid':
                answerForm!.get('answerText')?.errors &&
                answerForm!.get('answerText')?.touched
            }"
            formControlName="answerText"
            placeholder="Please enter the answer to the above question here"
            rows="5"
          ></textarea>
          <div i18n class="invalid-feedback">Please enter answer.</div>
        </div>
        <div class="form-check mt-3">
          <input class="form-check-input" type="checkbox" id="publish" formControlName="publish">
          <label i18n for="publish" class="form-check-label">This question and answer is useful for all people</label>
        </div>
        <div class="form-group p-4">
          <button i18n class="btn btn-success" [disabled]="!answerForm!.valid" type="submit">Save</button>
          <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
