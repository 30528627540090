import { Component, OnInit } from '@angular/core';
import { Book } from 'src/app/_models/book';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from 'src/app/_services/book.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';

@Component({
    selector: 'app-book-management',
    templateUrl: './book-management.component.html',
    styleUrls: ['./book-management.component.css']
})
export class BookManagementComponent implements OnInit {
    books: Book[] = [];
  constructor(private route: ActivatedRoute,
      private bookService: BookService,
      private router: Router,
      private alertify: AlertifyService) { }

  ngOnInit() {
      this.route.data.subscribe(data => {
          this.books = data['books'];
      });
  }

  deleteBook(book:Book){
    this.alertify.confirm($localize`Are you sure to delete ${book.name}?`, $localize`Delete book`, () => {
        this.bookService.deleteBook(book.id).subscribe({
            next: (restResponse : RestResponse)=>{
                this.alertify.showMessage(restResponse)
                const index = this.books.indexOf(book);
            if (index > -1)
                this.books.splice(index, 1);
            },
            error: (restResponse: RestResponse) => {
              this.alertify.showMessage(restResponse);
            }
          });
    });
  }
}
