<div class="container">
  <div class="row">
    <div class="col-md-auto">
      <div class="list-group">
        <a i18n href="#" class="list-group-item list-group-item-action active">
          Library controls
        </a>
        <a i18n [routerLink]="['/libraryManagement/authorManagement']" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action">Authors</a>
        <a i18n [routerLink]="['/libraryManagement/bookCategoryManagement']" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action">Categories</a>
        <a i18n [routerLink]="['/libraryManagement/bookManagement']" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action">Books</a>
        <!-- <a i18n [routerLink]="['/libraryManagement/bookFileManagement']" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action">Book Files</a> -->
        <a i18n [routerLink]="['/libraryManagement/bookContentManagement']" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action">Book Contents</a>
      </div>
    </div>
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
