import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { User } from '../_models/user';
import { RestResponse } from '../_models/restResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    baseUrl = environment.apiUrl + 'auth/';
    jwtHelper = new JwtHelperService();
    decodedToken: any;
    currentUser: User | null | undefined;

    constructor(private http: HttpClient) { }

    login(model: any): Observable<RestResponse>{
        return this.http.post<RestResponse>(this.baseUrl + 'login', model).pipe(
            map(data=>{
                if (data) {
                    localStorage.setItem('token', data.data.token);
                    localStorage.setItem('user', JSON.stringify(data.data));
                    this.decodedToken = this.jwtHelper.decodeToken(data.data.token);
                    this.currentUser = data.data;
                }
                return data;
            })
        );
    }
    /*
    login(model: any) {
        return this.http.post(this.baseUrl + 'login', model).pipe(
            map((response: any) => {
                const data = response as RestResponse;
                if (data) {
                    localStorage.setItem('token', data.data.token);
                    localStorage.setItem('user', JSON.stringify(data.data));
                    this.decodedToken = this.jwtHelper.decodeToken(data.data.token);
                    this.currentUser = data.data;
                }
            }),
        );
    }
    */
    register(user: User) : Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'register', user);
    }

    loggedIn() {
        const token = localStorage.getItem('token');
        return !this.jwtHelper.isTokenExpired(token!);
    }

    roleMatch(allowedRoles : any): boolean {
        let isMatch = false;
        const userRoles = this.decodedToken.role as Array<string>;
        allowedRoles.forEach((element: string) => {
            if (userRoles && userRoles.includes(element)) {
                isMatch = true;
                return;
            }
        });
        return isMatch;
    }
}
