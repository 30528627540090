  <div class="container">
      <div *ngIf="loading" class="spinner-border text-info" role="status">
        <span i18n class="sr-only">Loading...</span>
      </div>
      <div *ngIf="!loading">
        <nav *ngIf="categoryHierarchy.length>0" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" aria-current="page"><a i18n [routerLink]="['/post/management']">Root</a></li>
            <li *ngFor="let cat of categoryHierarchy | slice:0:categoryHierarchy.length-1 ; let i = index " class="breadcrumb-item"><a [routerLink]="['/post/management/'+categoryHierarchy[categoryHierarchy.length-i-1].id]" >{{categoryHierarchy[categoryHierarchy.length-i-1].name}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{categoryHierarchy[0].name}}</li>
          </ol>
        </nav>
      <div class="text-info m-4">{{currentPostCategory.name}}</div>
      <div>    
          <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button i18n class="nav-link active" id="subcategories-tab" data-bs-toggle="tab" data-bs-target="#subcategories" type="button" role="tab" aria-controls="subcategories" aria-selected="true">Categories In {{currentPostCategory.name}}</button>
          </li>
          <li class="nav-item" role="presentation">
            <button i18n class="nav-link" id="posts-tab" data-bs-toggle="tab" data-bs-target="#posts" type="button" role="tab" aria-controls="posts" aria-selected="false">Posts in {{currentPostCategory.name}}</button>
          </li>
          <li *ngIf="postCategoriesDeleted.length>0" class="nav-item" role="presentation">
            <button i18n class="nav-link" id="deletedcategories-tab" data-bs-toggle="tab" data-bs-target="#deletedcategories" type="button" role="tab" aria-controls="deletedcategories" aria-selected="true">Deleted Category({{currentPostCategory.name}})</button>
          </li>
          <li *ngIf="postsDeleted.length>0" class="nav-item" role="presentation">
            <button i18n class="nav-link" id="deletedposts-tab" data-bs-toggle="tab" data-bs-target="#deletedposts" type="button" role="tab" aria-controls="deletedposts" aria-selected="true">Deleted Post({{currentPostCategory.name}})</button>
          </li>
        </ul>
        <div class="tab-content m-2" id="myTabContent">


          <div class="tab-pane fade show active" id="subcategories" role="tabpanel" aria-labelledby="subcategories-tab">
            <!-- <div><button class="btn btn-success"><i class="fas fa-plus-circle m-1"></i>Add Post Category</button> </div> -->
              <div class="d-flex align-items-start">
                  <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button i18n class="nav-link active" id="v-pills-cat-tab0" data-bs-toggle="pill" data-bs-target="#v-pills-cat0" type="button" role="tab" aria-controls="v-pills-cat0" aria-selected="true"><i class="fas fa-plus-circle m-1"></i>New Category</button>
                    <button *ngFor="let cat of postCategories" class="nav-link" id="v-pills-cat-tab{{cat.id}}" data-bs-toggle="pill" [attr.data-bs-target]="'#v-pills-cat' + cat.id" type="button" role="tab" [attr.aria-controls]="'v-pills-cat'+cat.id" aria-selected="true">({{cat.displayOrder}}) {{cat.name}}</button>
                  </div>
                  <div class="tab-content w-100" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-cat0" role="tabpanel" aria-labelledby="v-pills-cat-tab0"><app-add-post-category (onSave)="onSaveCategory($event)" [parentCategory]="currentPostCategory"></app-add-post-category></div>
                    <div *ngFor="let cat of postCategories" class="tab-pane fade m-2" id="v-pills-cat{{cat.id}}" role="tabpanel" [attr.aria-labelledby]="'v-pills-cat-tab'+cat.id">
                      <div>
                        <button i18n class="btn btn-primary m-2" type="button" (click)="editCat.classList.toggle('collapse');viewCat.classList.toggle('collapse')"><i class="fas fa-edit m-1"></i>Edit</button>
                        <button i18n class="btn btn-primary m-2" type="button" (click)="onDeleteCategory(cat)"><i class="fas fa-trash m-1"></i>Delete</button>
                        <button i18n class="btn btn-primary m-2" type="button" (click)="onManageCategory(cat)"><i class="fas fa-sign-in m-1"></i>Manage this Category</button>
                      </div>
                      <div class="collapse" #editCat>
                        <div class="card card-body">
                          <app-add-post-category (onEdit)="onEditCategory($event)" [category]="cat"></app-add-post-category>
                        </div>
                      </div>
                      <div #viewCat>
                        <div class="card card-body">
                          <h4 class="card-title">{{cat.name}}</h4>
                          <p class="card-text">{{cat.description}}</p>
                          <p><input type="checkbox" class="form-check-input" disabled [checked]="cat.publish" /><span i18n>Publish</span></p>
                          <p i18n class="card-text">Display Order : {{cat.displayOrder}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          

          <div class="tab-pane fade" id="posts" role="tabpanel" aria-labelledby="posts-tab">
            <div class="d-flex align-items-start">
              <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <div class="list-group m-2 overflow-auto" style="max-height: 500px;">
                  <button i18n class="nav-link active mb-2" id="v-pills-post-tab0" data-bs-toggle="pill" data-bs-target="#v-pills-post0" type="button" role="tab" aria-controls="v-pills-post0" aria-selected="true"><i class="fas fa-plus-circle m-1"></i>New Post</button>
                  <button *ngFor="let post of posts" class="list-group-item list-group-item-action" id="v-pills-post-tab{{post.id}}" data-bs-toggle="pill" [attr.data-bs-target]="'#v-pills-post' + post.id" type="button" role="tab" [attr.aria-controls]="'v-pills-post'+post.id" aria-selected="true">({{post.displayOrder}}) {{post.title}}</button>
                </div>
              </div>
              <div class="tab-content w-100" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-post0" role="tabpanel" aria-labelledby="v-pills-post-tab0"><app-add-post [post]="initPost()" [categoryId]="this.currentPostCategory.id==0?null:this.currentPostCategory.id" (onSave)="onSavePost($event)"></app-add-post></div>
                <div *ngFor="let post of posts" class="tab-pane fade m-2" id="v-pills-post{{post.id}}" role="tabpanel" [attr.aria-labelledby]="'v-pills-post-tab'+post.id">
                  <div>
                    <button i18n class="btn btn-primary m-2" type="button" (click)="editPost.classList.toggle('collapse');viewPost.classList.toggle('collapse')"><i class="fas fa-edit m-1"></i>Edit</button>
                    <button i18n class="btn btn-primary m-2" type="button" (click)="onDeletePost(post)"><i class="fas fa-trash m-1"></i>Delete</button>
                  </div>
                  <div class="collapse" #editPost>
                    <div class="card card-body">
                      <app-add-post (onEdit)="onEditPost($event)" [post]="post"></app-add-post>
                    </div>
                  </div>
                  <div #viewPost>
                    <div class="card card-body">
                      <h4 class="card-title">{{post.title}}</h4>
                      <p class="card-text">{{post.body}}</p>
                      <p><input type="checkbox" class="form-check-input" disabled [checked]="post.publish" /><span i18n>Publish</span></p>
                      <p i18n class="card-text">Display Order : {{post.displayOrder}}</p>
                      <div id="displayOrderList" class="list-group list-group-numbered mt-2 mb-2">
                        <div *ngFor="let pf of post.postFiles" class="list-group-item d-flex justify-content-between align-items-start">
                          <div class="ms-2 me-auto">
                            <div class="fw-bold">{{pf.dataFile.title}}</div>
                            <div class="d-flex flex-wrap align-content-start">{{pf.dataFile.urlAddress.split('/').pop()}}</div>
                          </div>
                        </div>
                      </div>
                      <p i18n>Display the final output</p>
                      <div class="preline border-start border-2 border-info mt-2 p-4">
                        {{showBody(post.body)}}
                      </div>
                    </div>
                  </div>
                  <div>
                    <!-- <table class="table table-striped">
                      <thead>
                      <tr>
                        <th class="text-center"><b>Attachments</b></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of post.postFiles! | sortBy:'asc':'displayOrder'; let i = index"
                          class="draggable"
                          [class.active]="draggedOverIndex == i"
                          draggable="true"
                          (dragover)="allowDrop($event, i)"
                          (dragstart)="onDragStart(i)"
                          (drop)="onDrop($event,post.postFiles, i)"
                      >
                        <td>{{item.displayOrder}} {{item.dataFile.title}}</td>
                      </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          


          <div class="tab-pane fade" id="deletedcategories" role="tabpanel" aria-labelledby="deletedcategories-tab">
            <div>
              <div class="d-flex flex-wrap flex-row bd-highlight mb-3">
                <div *ngFor="let dcat of postCategoriesDeleted" class="p-4 bd-highlight">
                  <div class="text-decoration-none">
                    <div class="card text-dark bg-light tile-size">
                      <div class="card-body p-4">
                        <h4 class="card-title">{{dcat.name}}</h4>
                        <p class="card-text">{{dcat.description}}</p>
                        <p i18n class="card-text">Display Order : {{dcat.displayOrder}}</p>
                        <p><input type="checkbox" class="form-check-input" disabled [checked]="dcat.publish" /><span i18n>Publish</span></p>
                        <button i18n (click)="onUnDeleteCategory(dcat)" class="btn btn-primary"><i class="fas fa-trash-restore m-2"></i>Undelete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="tab-pane fade" id="deletedposts" role="tabpanel" aria-labelledby="deletedposts-tab">
            <div>
              <div class="d-flex flex-wrap flex-row bd-highlight mb-3">
                <div *ngFor="let p of postsDeleted" class="p-4 bd-highlight">
                  <div class="text-decoration-none">
                    <div class="card text-dark bg-light tile-size">
                      <div class="card-body p-4">
                        <h4 class="card-title">{{p.title}}</h4>
                        <p class="card-text">{{p.body}}</p>
                        <p i18n class="card-text">Display Order : {{p.displayOrder}}</p>
                        <p><input type="checkbox" class="form-check-input" disabled [checked]="p.publish" /><span i18n>Publish</span></p>
                        <button i18n (click)="onUnDeletePost(p)" class="btn btn-primary"><i class="fas fa-trash-restore m-2"></i>Undelete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  