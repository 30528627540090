import { Component, OnInit } from '@angular/core';
import { BookContent } from 'src/app/_models/bookContent';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from 'src/app/_services/book.service';
import { AlertifyService } from 'src/app/_services/alertify.service';

@Component({
  selector: 'app-book-content-management',
  templateUrl: './book-content-management.component.html',
  styleUrls: ['./book-content-management.component.css']
})
export class BookContentManagementComponent implements OnInit {
    bookContents: BookContent[] = [];
    constructor(private route: ActivatedRoute,
        private bookService: BookService,
        private router: Router,
        private alertify: AlertifyService) { }

  ngOnInit() {
        this.route.data.subscribe(data => {
            this.bookContents = data['bookContents'];
        });
  }

  deleteItem(list: BookContent[], item: BookContent) {
      const index = list.indexOf(item);
      if (index > -1)
          list.splice(index, 1);
  }

  deleteBookContent(list: BookContent[], item: BookContent) {
      this.alertify.confirm($localize`Are you sure to delete ${item.name}?`, $localize`Delete book content`, () => {
          this.bookService.deleteBookContent(item.id).subscribe(() => {
              this.alertify.success($localize`Deleted successfully`);
              this.deleteItem(list, item);
          }, error => {
              this.alertify.error(error);
          });
      });
  }
}
