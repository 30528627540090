import { CacheService } from './../../_services/cache.service';
import { Observable } from 'rxjs';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from './../commonHelper';
import { RestResponse } from './../../_models/restResponse';
import { HttpEventType } from '@angular/common/http';
import { UploadService } from './../../_services/upload.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { RestResponseStatus } from 'src/app/_models/restResponse';
import { DataFile } from './../../_models/dataFile';
import { DataFileService } from 'src/app/_services/dataFile.service';
import { Component, OnInit, Input, Output, EventEmitter, NgModule, ElementRef } from '@angular/core';
import { HtmlParser } from '@angular/compiler';

@Component({
  selector: 'app-data-files-select',
  templateUrl: './data-files-select.component.html',
  styleUrls: ['./data-files-select.component.css']
})
export class DataFilesSelectComponent implements OnInit {

  @Input() path: string = "";
  @Input() title: string | null | undefined;
  @Input() buttonContent: string = "Upload File";
  @Input() selectedFiles: DataFile[] = [];
  @Input() titleNeed: boolean = true;
  @Input() MultipleFileSelect: boolean = true;

  @Output() public onUploadFinished = new EventEmitter();
  @Output() public onFileSelectChanged = new EventEmitter<DataFile>();
  @Output() public onLoaded = new EventEmitter();

  loading: boolean = true;
  dataFiles: DataFile[] = [];
  dataFile: DataFile | undefined;
  public progress: number = 0;
  public message: string | null | undefined;
  public fileList: any;
  public fileSelected: string | null | undefined;
  public searchText: string = '';
  public showSelected: boolean = false;
  public showImage: boolean = false;

  constructor(private fileService: DataFileService,
    private uploadService: UploadService,
    private dataFileService: DataFileService,
    public commonHelper: CommonHelper,
    private alertify: AlertifyService,
    private cacheService: CacheService) { }

  ngOnInit(): void {
    var dataFileCached = this.cacheService.get("dataFile")
    if (!dataFileCached) {
      this.fileService.getDataFiles().subscribe((restResponse: RestResponse) => {
        if (restResponse.status == RestResponseStatus.Success) {
          this.dataFiles = restResponse.data
          this.dataFiles.map((v) => {
            v.show = true;
            if (this.selectedFiles.length > 0 && this.selectedFiles.filter((ff) => ff.id == v.id).length > 0) {
              v.selected = true;
            }
          });
          this.cacheService.set("dataFile", this.dataFiles);
        } else {
          this.alertify.showMessage(restResponse);
        }
        this.loading = false;
        this.onLoaded.emit();
      });
    } else {
      this.dataFiles = dataFileCached as DataFile[]
      this.loading = false;
    }
  }

  cardClicked(file: DataFile) {
    if (this.MultipleFileSelect == false) {
      this.dataFiles.map((v, i) => {
        if (v.selected && v != file) {
          v.selected = false;
        }
      });
    }
    file.selected = !file.selected;
    this.onFileSelectChanged.emit(file);
  }

  getDataFiles(): DataFile[] {
    return this.dataFiles;
  }
  getSelectedDataFiles(): DataFile[] {
    return this.dataFiles.filter(v => v.selected == true);
  }
  onSearchChange() {
    var s: string = this.searchText.toLowerCase();
    this.dataFiles.map((v, i) => {
      if (v.title.toLowerCase().includes(s) ||
        v.urlAddress.toLowerCase().includes(s) || this.searchText == '') {
        v.show = true;
      }
      else {
        v.show = false;
      }
    })
  }

  showSelectedFile() {
    if (this.showSelected) {
      this.dataFiles.map((v, i) => {
        if (v.selected == true)
          v.show = true;
        else v.show = false;
      })
    } else {
      this.onSearchChange();
    }
  }

  showImageFile() {
    this.showSelectedFile();
  }


  myfilter(files: DataFile[]): DataFile[] {
    return files.filter((v) => { if (v.show == true) return v; else return null });
  }

  public reset() {
    this.fileSelected = null;
    this.message = null;
    this.progress = 0;
    this.title = null;
    //this.getFileList();
  }

  public getFileAndTitle(): any {
    return { fileSelected: this.fileSelected, title: this.title }
  }

  public uploadFileToPublicStorage(files: FileList | null) {
    if (!files || files.length === 0) {
      this.alertify.error($localize`Please choose file.`);
      return;
    }

    if (this.titleNeed && (this.title == undefined || this.title == "")) {
      this.alertify.error($localize`Please enter file title.`);
      files = null;
      return;
    }

    this.progress = 0;
    this.message = null;
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    //let p;
    //if (this.path) {
    //    p = {
    //        path: this.path
    //    };
    //}

    this.uploadService.uploadFileToPublicStorage(formData, this.path,).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total!);
        else if (event.type === HttpEventType.Response) {
          var result = event.body as RestResponse;
          if (result.status == RestResponseStatus.Error || result.status == RestResponseStatus.Fail) {
            this.alertify.showMessage(result);
            this.progress = 0;
            return;
          }
          this.message = $localize`Uploaded successfully`;
          this.fileSelected = result.data;
          this.dataFile = {
            id: 0,
            urlAddress: this.fileSelected!,
            title: this.title!,
            selected: false,
            show: true
          };
          this.dataFileService.addDataFile(this.dataFile).subscribe((restResponse: RestResponse) => {
            if (restResponse.status === RestResponseStatus.Success) {
              this.dataFile = restResponse.data;
              this.dataFile!.selected = false;
              this.dataFile!.show = true;
              this.dataFiles.push(this.dataFile!);
              this.dataFile = undefined;
            } else {
              this.alertify.showMessage(restResponse);
            }
          });
        }
      },
      error: (err) => {
        this.alertify.showMessage(err);
      },
      complete: () => {
        //this.getFileList();
      }
    });
  }

  public uploadFile(files: FileList | null) {
    if (!files || files.length === 0) {
      this.alertify.error($localize`Please choose file.`);
      return;
    }

    if (this.titleNeed && (this.title == undefined || this.title == "")) {
      this.alertify.error($localize`Please enter file title.`);
      files = null;
      return;
    }

    this.progress = 0;
    this.message = null;
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    //let p;
    //if (this.path) {
    //    p = {
    //        path: this.path
    //    };
    //}

    this.uploadService.uploadFile(formData, this.path,).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total!);
        else if (event.type === HttpEventType.Response) {
          var result = event.body as RestResponse;
          if (result.status == RestResponseStatus.Error || result.status == RestResponseStatus.Fail) {
            this.alertify.showMessage(result);
            this.progress = 0;
            return;
          }
          this.message = $localize`Uploaded successfully`;
          this.fileSelected = result.data;
          this.dataFile = {
            id: 0,
            urlAddress: this.fileSelected!,
            title: this.title!,
            selected: false,
            show: true
          };
          this.dataFileService.addDataFile(this.dataFile).subscribe((restResponse: RestResponse) => {
            if (restResponse.status === RestResponseStatus.Success) {
              this.dataFile = restResponse.data;
              this.dataFile!.selected = false;
              this.dataFile!.show = true;
              this.dataFiles.push(this.dataFile!);
              this.dataFile = undefined;
            } else {
              this.alertify.showMessage(restResponse);
            }
          });
        }
      },
      error: (err) => {
        this.alertify.showMessage(err);
      },
      complete: () => {
        //this.getFileList();
      }
    });
  }
}
