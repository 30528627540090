import { DataFilesSelectComponent } from './../../../helper/data-files-select/data-files-select.component';
import { DataFile } from './../../../_models/dataFile';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-edit-book',
    templateUrl: './edit-book.component.html',
    styleUrls: ['./edit-book.component.css']
})
export class EditBookComponent implements OnInit {
    editBookForm!: UntypedFormGroup;
    book: any;
    authors: any;
    selectedDataFiles: DataFile[] = [];

    @ViewChild(DataFilesSelectComponent) dataFilesSelect!: DataFilesSelectComponent;

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.book = data['book'].data;
            if(this.book.releaseDate!=null) this.book.releaseDate = this.book.releaseDate.split("T", 1)[0];
            this.authors = data['authors'];
            this.createaddBookForm();
        });
    }

    dataFileSelectLoaded(){
        this.dataFilesSelect.getDataFiles().map((v,i)=>{
            if(v.id==this.book.dataFileId){
                v.selected = true;
            }
        })
    }

    createaddBookForm() {
        this.editBookForm = this.fb.group({
            name: [this.book.name, Validators.required],
            description: [this.book.description, Validators.required],
            authorId: [this.book.authorId],
            releaseDate: [this.book.releaseDate, [Validators.required, Validators.pattern(/^\d{4}[./-]\d{2}[./-]\d{2}$/)]],
            isMain: [this.book.isMain],
            displayOrder: [this.book.displayOrder]
        });
    }
    // fileSelected(event: any) {
    //     this.book.imageUrl = event;
    // }
    fileSelectChanged(dataFile: DataFile) {
        if (dataFile.selected) {
            this.selectedDataFiles = [];
            this.selectedDataFiles.push(dataFile);
            this.book.dataFileId = dataFile.id;
        } else {
            this.book.dataFileId = null;
        }
    }
    editBook() {
        if (this.editBookForm.valid) { 
            Object.assign(this.book, this.editBookForm.value);
            this.bookService.editBook(this.book).subscribe(() => {
                this.alertify.success($localize`Edited successfully`);
                this.router.navigate(['/libraryManagement/bookManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/bookManagement']);
    }
}
