<form class="form-center" [formGroup]="editCategoryForm" (ngSubmit)="editCategory()">
  <h2 i18n class="text-center text-primary">Edit Category</h2>
  <hr>

  <div class="form-group">
    <label i18n>Name</label>
    <input type="text"
           [ngClass]="{'is-invalid': editCategoryForm.get('name')?.errors
        && editCategoryForm.get('name')?.touched}"
           class="form-control"
           formControlName="name"
           placeholder="Name">
    <div i18n class="invalid-feedback">Please choose a name</div>
  </div>
  <div class="form-group">
    <label i18n>Description</label>
    <input type="text"
           [ngClass]="{'is-invalid': editCategoryForm.get('description')?.errors
        && editCategoryForm.get('description')?.touched}"
           class="form-control"
           formControlName="description"
           placeholder="Description">
    <div i18n class="invalid-feedback">Please enter description</div>
  </div>
  <div class="form-group">
    <label i18n>Display Order</label>
    <input type="number"
           class="form-control"
           formControlName="displayOrder"
           placeholder="Display Order">
  </div>
  <div class="form-group text-center">
    <button i18n class="btn btn-success" [disabled]="!editCategoryForm.valid" type="submit">Save</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
