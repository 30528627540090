import { PostFile } from './../../../_models/posts/postFile';
import { DatePipe } from '@angular/common';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostCategory } from 'src/app/_models/posts/postCategory';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { PostService } from 'src/app/_services/post.service';
import { Post } from 'src/app/_models/posts/Post';
import { CommonHelper } from 'src/app/helper/commonHelper';

@Component({
  selector: 'app-post-managment',
  templateUrl: './post-managment.component.html',
  styleUrls: ['./post-managment.component.css']
})
export class PostManagmentComponent implements OnInit {

  currentPostCategory: PostCategory = {
    id: 0,
    parentId: null,
    name: $localize`Root`,
    description: '',
    displayOrder: 0,
    publish: false,
    deleted: false,
    dataFileId: undefined
  };

  postCategories: PostCategory[] = [];
  postCategoriesDeleted: PostCategory[] = [];
  postCategoryShow: boolean[] = [];
  posts: Post[] = [];
  postShow: boolean[] = [];
  postsDeleted: Post[] = [];
  loading: Boolean = true;
  categoryHierarchy: PostCategory[] = [];

  private draggedIndex: number | undefined;
  public draggedOverIndex: number | undefined;

  constructor(private route: ActivatedRoute,
    private postService: PostService,
    private alertify: AlertifyService,
    private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      if (params['id']) {
        var id = parseInt(params['id']);
        this.postService.getCategoryDetails(id).subscribe({
          next: (restResponse: RestResponse) => {
            if (restResponse.status === RestResponseStatus.Success) {
              this.currentPostCategory = restResponse.data;
              this.updateData();
              this.breadcrumbsLoad(this.currentPostCategory);
            } else {
              this.alertify.showMessage(restResponse);
            }
          },
          error: (error) => {
            this.alertify.showMessage(error);
          }
        })

      } else {
        this.updateData();
      }
    });
  }

  showBody(body:string): string{
    return CommonHelper.removeAllHtmlTags(body)
  }

  breadcrumbsLoad(cat : PostCategory){
    this.postService.getCategoryHierarchy(cat.id!).subscribe({
      next: (restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          this.categoryHierarchy = restResponse.data;
        } else {
          this.alertify.showMessage(restResponse);
        }
      },
      error: (error) => {
        this.alertify.showMessage(error);
      }
    })
  }
  updateData() {
    this.postService.getCategoriesWithDeleted(this.currentPostCategory.id).subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        this.postCategories = restResponse.data.filter((cat: { deleted: any; }) => !cat.deleted).sort((a: { displayOrder: number; }, b: { displayOrder: number; }) => (a.displayOrder < b.displayOrder ? -1 : 1));
        this.postCategoriesDeleted = restResponse.data.filter((cat: { deleted: any; }) => cat.deleted).sort((a: { displayOrder: number; }, b: { displayOrder: number; }) => (a.displayOrder < b.displayOrder ? -1 : 1));
        this.postCategoryShow = new Array(restResponse.data.length).fill(false);
        this.postService.getPostsWithDeleted(this.currentPostCategory.id).subscribe((restResponse: RestResponse) => {
          if (restResponse.status === RestResponseStatus.Success) {
            //this.posts = restResponse.data;
            this.posts = restResponse.data.filter((post: { deleted: any; }) => !post.deleted).sort((a: { displayOrder: number; }, b: { displayOrder: number; }) => (a.displayOrder < b.displayOrder ? -1 : 1));
            this.postsDeleted = restResponse.data.filter((post: { deleted: any; }) => post.deleted).sort((a: { displayOrder: number; }, b: { displayOrder: number; }) => (a.displayOrder < b.displayOrder ? -1 : 1));    
            this.postShow = new Array(restResponse.data.length).fill(false);
            this.loading = false;
          }
        });
      }
    });
  }
  
  toggleComponentShow(switches : boolean[], index:number) {     
    // Toggle selected element's detail view.
    switches[index] = !switches[index];
  
    // Close any other opened detail views. 
    switches.map((val, i) => i === index ? val : false);
  }

  // allowDrop($event: { preventDefault: () => void; }, index: number): void {
  //   this.draggedOverIndex = index;
  //   $event.preventDefault();
  // }

  // onDragStart(index: number): void {
  //   this.draggedIndex = index;
  // }

  // onDrop($event: { preventDefault: () => void; },files: any, index: any): void {
  //   $event.preventDefault();
  //   const item = files[this.draggedIndex!];
  //   files.splice(this.draggedIndex, 1);
  //   files.splice(index, 0, item);
  //   this.draggedIndex = -1;
  //   this.draggedOverIndex = -1;
  // }

  onSaveCategory(category: PostCategory) {
    if (this.currentPostCategory.id > 0)
      category.parentId = this.currentPostCategory.id
    this.postCategories.push(category);
    this.alertify.success($localize`Category added successfully.`)
  }

  onEditCategory(category: PostCategory) {
    this.alertify.success($localize`Category edited successfully.`)
  }

  deleteItem(list: any, item: any) {
    const index = list.indexOf(item);
    if (index > -1)
      list.splice(index, 1);
  }

  onDeleteCategory(category: PostCategory) {
    this.alertify.confirm($localize`Are you sure to delete ${category.name}?`, $localize`Delete post category`, () => {
      this.postService.deleteCategory(category.id).subscribe((restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          category.deleted = true;
          this.postCategoriesDeleted.push(category);
          this.deleteItem(this.postCategories, category);
          this.alertify.success($localize`Category deleted successfully.`)
        }
      });
    });
  }

  onUnDeleteCategory(category: PostCategory) {
    this.postService.undeleteCategory(category.id).subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        category.deleted = false;
        this.postCategories.push(category);
        this.deleteItem(this.postCategoriesDeleted, category);
        this.alertify.success($localize`Category undeleted successfully.`)
      }
    });
  }

  onManageCategory(cat: PostCategory) {
    this.router.navigate([`/post/management/${cat.id}`]);
  }
  onClickCategoryHierarchy(cat: PostCategory) {
    this.router.navigate([`/post/management/${cat.id}`]);
  }
  //    Posts -----------------------------------------------------------
  initPost(): Post {
    return {
      id: 0,
      categoryId: this.currentPostCategory.id == 0 ? null : this.currentPostCategory.id,
      title: '',
      body: '',
      displayOrder: 0,
      deleted: false,
      publish: false,
      postFiles: null,
      flags: 0
    }
  }
  onSavePost(post: Post) {
    this.posts.push(post);
    this.alertify.success($localize`Post added successfully.`)
  }

  // onSavePostFile(postFile: PostFile) {
  //   this.posts.filter((p,i)=>{
  //     if(p.id==postFile.postId){
  //       if(p.postFiles==null) p.postFiles = [];
  //       p.postFiles?.push(postFile);
  //     }
  //   });
  //   this.alertify.success($localize`Post File added successfully.`)
  // }

  onEditPost(post: Post) {
    this.alertify.success($localize`Post edited successfully.`)
  }

  onDeletePost(post: Post) {
    this.alertify.confirm($localize`Are you sure to delete ${post.title}?`, $localize`Delete post`, () => {
      this.postService.deletePost(post.id).subscribe((restResponse: RestResponse) => {
        if (restResponse.status === RestResponseStatus.Success) {
          post.deleted = true;
          this.postsDeleted.push(post);
          this.deleteItem(this.posts, post);
          this.alertify.success($localize`Post deleted successfully.`)
        }
      });
    });
  }

  onUnDeletePost(post: Post) {
    this.postService.undeletePost(post.id).subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        post.deleted = false;
        this.posts.push(post);
        this.deleteItem(this.postsDeleted, post);
        this.alertify.success($localize`Post undeleted successfully.`)
      }
    });
  }

  editPost() {
  }
  attachFileToPost(post: Post) {

  }
}
