<div class="container text-center">
  <form [formGroup]="fpf" (ngSubmit)="forgotPassword()" class="form-signin">
    <i class="fa fa-5x fa-lock mb-4 mt-4"></i>
    <h1 i18n class="h3 mb-3 font-weight-normal">Reset Password</h1>
    <div class="form-group">
      <input type="email" id="email" formControlName="email" placeholder="Enter Email"
             [ngClass]="{'is-invalid': fpf.get('email')?.errors
             && fpf.get('email')?.touched}" class="form-control" />
      <div i18n class="invalid-feedback" *ngIf="fpf.get('email')?.hasError('required')
          && fpf.get('email')?.touched">Enter your email for reset password</div>
      <div i18n class="invalid-feedback" *ngIf="fpf.get('email')?.hasError('pattern')
          && fpf.get('email')?.touched">Email is not valid</div>
    </div>
    <button i18n type="submit" class="btn btn-primary" [disabled]="!fpf.valid">Reset</button>
    <a i18n class="btn btn-success ml-2" [routerLink]="['/home']">Login</a>
  </form>
</div>
