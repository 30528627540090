import { RestResponse } from './../../_models/restResponse';
import { ChangeEmailComponent } from './../change-email/change-email.component';
import { Component, OnInit } from '@angular/core';
import { User } from '../../_models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ChangePasswordModel } from 'src/app/_models/changePasswordModel';
import { ChangeEmailModel } from '../../_models/changeEmailModel';
import { PasswordValidator } from '../../helper/password.validator';

@Component({
    selector: 'app-user-panel',
    templateUrl: './user-panel.component.html',
    styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent implements OnInit {
    user: User | null | undefined;
    changePasswordModel: ChangePasswordModel | null | undefined;
    changeEmailModel: ChangeEmailModel | null | undefined;
    updateForm: UntypedFormGroup = new UntypedFormGroup({});
    changePasswordForm: UntypedFormGroup = new UntypedFormGroup({});
    changeEmailForm: UntypedFormGroup = new UntypedFormGroup({});
    codeEmailForm?: UntypedFormGroup;

    constructor(private route: ActivatedRoute,
        private router: Router, private userService: UserService,
        private alertify: AlertifyService, private fb: UntypedFormBuilder, private datepipe: DatePipe) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.user = data['user'];
            this.createUpdateUserForm();
            this.createChangePasswordForm();
            this.createChangeEmailForm();
        });
    }
    createUpdateUserForm() {
        this.updateForm = this.fb.group({
            gender: [this.user?.gender],
            username: [this.user?.username, Validators.required],
            dateOfBirth: [this.datepipe.transform(this.user?.dateOfBirth, 'yyyy/MM/dd')], //this.datepipe.transform(this.user.dateOfBirth, "MM/dd/yyy")
            introduction: [this.user?.introduction],
            city: [this.user?.city],
            country: [this.user?.country]
        });
    }

    createChangePasswordForm() {
        this.changePasswordForm = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(8), PasswordValidator.strong]],
            confirmPassword: ['', Validators.required]
        }, { validator: this.passwordMatchValidator });
    }

    createChangeEmailForm() {
        this.changeEmailForm = this.fb.group({
            newEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            password: ['', [Validators.required, Validators.minLength(8), PasswordValidator.strong]],
        });
    }

    createCodeEmailForm() {
        this.codeEmailForm = this.fb.group({
            code: ['', [Validators.required]],
        });
    }

    passwordMatchValidator(g: UntypedFormGroup) {
        return g.get('newPassword')?.value === g.get('confirmPassword')?.value ? null : { 'mismatch': true };
    }

    updateUser() {
        if (this.updateForm?.valid) {
            this.user = Object.assign({}, this.updateForm?.value);
            if (this.user) {
                this.userService.updateUser(this.user).subscribe(() => {
                    this.alertify.success($localize`Profile updated successfully`);
                    this.updateForm?.reset(this.user);
                }, error => {
                    this.alertify.error(error);
                });
            }

        }
    }

    changePassword() {
        if (this.changePasswordForm?.valid) {
            this.changePasswordModel = Object.assign({}, this.changePasswordForm.value);
            if (this.changePasswordModel) {
                this.userService.changePassword(this.changePasswordModel).subscribe(() => {
                    this.alertify.success($localize`Password changed successfully`);
                    this.changePasswordForm?.reset();
                }, error => {
                    this.alertify.error(error);
                });
            }

        }
    }

    changeEmail() {
        if (this.changeEmailForm?.valid) {
            this.changeEmailModel = Object.assign({}, this.changeEmailForm?.value);
            if (this.changeEmailModel) {
                this.userService.changeEmailRequest(this.changeEmailModel).subscribe(() => {
                    this.alertify.success($localize`Check your email for changing.`);
                    this.createCodeEmailForm();
                }, (error : RestResponse) => {
                    this.alertify.error(error.message);
                });
            }

        }
    }

    codeEmail() {
        if (this.codeEmailForm?.valid) {
                this.userService.changeEmailByCode(this.changeEmailForm?.value.newEmail, this.codeEmailForm.value.code).subscribe(() => {
                    this.alertify.success($localize`Your email changed successfully.`);
                    this.codeEmailForm = undefined;
                }, (error : RestResponse) => {
                    this.alertify.error(error.message);
                });
        }
    }

    confirmEmailRequest() {
        if (this.user?.emailConfirmed) {
            this.alertify.message($localize`Your email is confirmed.`);
            return;
        }
        if (this.user && this.user.email) {
            this.userService.confirmEmailRequest(this.user.email)
                .subscribe(() => {
                    this.alertify.success($localize`Please check your email.`);
                }, error => {
                    this.alertify.error(error);
                });
        }
    }
}
