import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../../_services/alertify.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {

    queryObj: any = {};
    //username: string;
    //newEmail: string;
    //code: string;
    message: string = '';

    constructor(private route: ActivatedRoute, private userService: UserService,
        private alertify: AlertifyService) { }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(params => {
            console.log(params.get('username'));
            this.userService.confirmEmail(
                params.get('username')!, params.get('code')!).subscribe(() => {
                    this.message = $localize`Your email confirmed successfully.`;
                }, error => {
                    this.message = $localize`Faild to confirm your email.`;
                    this.alertify.error(error);
                });
        });
  }
}
