<div class="row">
<table class="table">
  <tr>
    <th i18n style="width: 10%">User Id</th>
    <th i18n style="width: 30%">Username</th>
    <th i18n style="width: 40%">Active roles</th>
    <th i18n style="width: 20%"></th>
  </tr>
  <tr *ngFor="let user of users">
    <td>{{user.id}}</td>
    <td>{{user.username}}</td>
    <td>{{user.roles}}</td>
    <td><button i18n class="btn btn-info" (click)="editRolesModal(user)">Edit Roles</button></td>
  </tr>
</table>
</div>
