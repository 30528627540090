<form [formGroup]="addBookFileForm" (ngSubmit)="addBookFile()">
  <h2 i18n class="text-center text-primary">Add Book File</h2>
  <hr>

  <div class="form-group">
    <input type="text"
           [ngClass]="{'is-invalid': addBookFileForm.get('name')?.errors
        && addBookFileForm.get('name')?.touched}"
           class="form-control"
           formControlName="name"
           placeholder="Name">
    <div i18n class="invalid-feedback">Please choose a name</div>
  </div>
  <div class="form-group mt-2">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <input type="text" [ngClass]="{'is-invalid': addBookFileForm.get('type')?.errors
        && addBookFileForm.get('type')?.touched}"
               class="form-control"
               formControlName="type"
               placeholder="Type" />
      </div>
      <select class="form-select" (input)="onTypeSelect($event)">
        <option></option>
        <option *ngFor="let t of types" [value]="t">{{t}}</option>
      </select>
    </div>
    <div i18n class="invalid-feedback">Please enter type</div>
  </div>
  <div class="form-group">
    <input type="text"
           [ngClass]="{'is-invalid': addBookFileForm.get('version')?.errors
        && addBookFileForm.get('version')?.touched}"
           class="form-control"
           formControlName="version"
           placeholder="Version">
    <div i18n class="invalid-feedback">Please enter file version</div>
  </div>
  <div class="form-group mt-2">
    <input type="number"
           class="form-control"
           formControlName="displayOrder"
           placeholder="Display Order">
  </div>
  <div class="mt-2">
    <app-data-files-select (onFileSelectChanged)="fileSelectChanged($event)" [selectedFiles]="selectedDataFiles" [MultipleFileSelect]="false" dataFilesSelect></app-data-files-select>
  </div>
  <!-- <app-upload [path]="'books'" [buttonContent]="'Upload book'" (onFileSelected)="fileSelected($event)"></app-upload> -->
  <div class="form-group text-center mt-2">
    <button i18n class="btn btn-success" [disabled]="!addBookFileForm.valid" type="submit">Save</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
