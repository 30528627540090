import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpInterceptor, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor{
    locale: string;// = 'en';
    constructor(@Inject(LOCALE_ID) locale: string) {
        this.locale = locale;
    }
    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {

        console.log(`url: ${req.url}`);

        const modifiedReq = req.clone({
            headers: req.headers.set('Content-Language', this.locale)
                .set('Accept-Language', this.locale),
            url: req.url.replace(/\/fa\/|\/en\/|\/ar\//mi, "\/")
        });

        console.log(`request language modified to ${this.locale}`);

        console.log(`url: ${modifiedReq.url}`)

        return next.handle(modifiedReq);
    }
}

export const LanguageInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true
};
