<header>
  <nav
    class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3"
  >
    <div class="container">
      <a class="navbar-brand font-weight-bold" [routerLink]="['/home']" i18n>Ahmed Al Hasan</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Toggle navigation"
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
        [ngClass]="{ show: isExpanded }"
      >
        <ul *ngIf="loggedIn()" class="navbar-nav flex-grow">
          <!-- <li class="nav-item" routerLinkActive="['link-active']">
            <a i18n class="nav-link text-dark" [routerLink]="['/counter']">Counter</a>
          </li> -->
          <li class="nav-item" routerLinkActive="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/user']"><i class="fa fa-user"></i> {{authService.decodedToken?.unique_name | titlecase}}</a>
          </li>
          <li class="nav-item" routerLinkActive="['link-active']">
            <a i18n class="nav-link text-dark" (click)="logout()" routerLink="['/home']">LogOut</a>
          </li>
          <!-- <li *appHasRole="['Admin']" class="nav-item" routerLinkActive="['link-active']">
            <a i18n class="nav-link text-dark" [routerLink]="['/fetch-data']">Fetch data</a>
          </li> -->
          <li *appHasRole="['Admin']" class="nav-item" routerLinkActive="['link-active']">
            <a i18n class="nav-link text-dark" [routerLink]="['/admin']">Admin</a>
          </li>
          <li *appHasRole="['Answerer']" class="nav-item" routerLinkActive="['link-active']">
            <a i18n class="nav-link text-dark" [routerLink]="['/faq/home']">FAQ</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<a class=" container nav-link text-dark" [routerLink]="[previousUrl]"><i class="fa fa-chevron-right"></i></a>
