import { DataFile } from './../../../_models/dataFile';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-add-author',
    templateUrl: './add-author.component.html',
    styleUrls: ['./add-author.component.css']
})
export class AddAuthorComponent implements OnInit {
    addAuthorForm!: UntypedFormGroup;
    author: any;
    urlAddress: string = "";
    selectedDataFiles: DataFile[] = [];

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.createaddAuthorForm();
    }
    // fileSelected(event: any) {
    //     this.urlAddress = event;
    // }
    createaddAuthorForm() {
        this.addAuthorForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            gender: ['', Validators.required],
            bio: ['', Validators.required],
            academy: ['', Validators.required],
            birthDate: [null],//, [Validators.required, Validators.pattern(/^\d{4}[./-]\d{2}[./-]\d{2}$/)]],
            nationality: [''],
            religion: [''],
            displayOrder: [0]
        });
    }

    fileSelectChanged(dataFile: DataFile) {
        if (dataFile.selected) {
            this.selectedDataFiles = [];
            this.selectedDataFiles.push(dataFile);
        } else {
            this.selectedDataFiles = [];
        }
    }

    addAuthor() {
        if (this.addAuthorForm.valid) {
            this.author = Object.assign({}, this.addAuthorForm.value);
            if (this.selectedDataFiles.length > 0) {
                this.author.dataFileId = this.selectedDataFiles[0].id;
            }else{
                this.author.dataFileId = null;
            }
            this.bookService.addAuthor(this.author).subscribe(() => {
                this.alertify.success($localize`Saved successfully`);
                this.router.navigate(['/libraryManagement/authorManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/authorManagement']);
    }
}
