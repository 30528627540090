import { Component, OnInit } from '@angular/core';
import { Author } from '../../../_models/author';
import { BookService } from '../../../_services/book.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-author-management',
    templateUrl: './author-management.component.html',
    styleUrls: ['./author-management.component.css']
})
export class AuthorManagementComponent implements OnInit {
    authors: Author[] = [];

    constructor(private route: ActivatedRoute,
        private bookService: BookService,
        private router: Router,
        private alertify: AlertifyService) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.authors = data['authors'].data;
        });
        //this.bookService.getAuthorsWithBooks().subscribe((authors: Author[]) => {
        //    this.authors = authors;
        //}, error => {
        //    this.alertify.error(error);
        //});
    }
}
