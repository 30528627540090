<div class="container">
    <div class="row">
        <form class="form-center form-control" [formGroup]="pcatForm" (ngSubmit)="onFormSubmit()">
          <h2 class="text-center text-primary">{{header}}</h2>
          <hr>
          <div class="form-group">
            <label i18n>Name</label>
            <input type="text"
                   [ngClass]="{'is-invalid': pcatForm.get('name')?.errors
                && pcatForm.get('name')?.touched}"
                   class="form-control"
                   formControlName="name">
            <div i18n class="invalid-feedback">Please enter a name</div>
          </div>
          <div class="form-group">
            <label i18n>Description</label>
            <input type="text"
                   [ngClass]="{'is-invalid': pcatForm.get('description')?.errors
                && pcatForm.get('description')?.touched}"
                   class="form-control"
                   formControlName="description">
            <div i18n class="invalid-feedback">Please enter a description</div>
          </div>
          <div class="form-group">
            <label i18n>Display Order</label>
            <input type="number"
                   [ngClass]="{'is-invalid': pcatForm.get('displayOrder')?.errors
                && pcatForm.get('displayOrder')?.touched}"
                   class="form-control"
                   formControlName="displayOrder">
            <div i18n class="invalid-feedback">Please enter a display order</div>
          </div>
          <div class="form-group form-check mt-3">
            <input class="form-check-input" type="checkbox" id="publishCategory" formControlName="publish">
            <label i18n for="publishCategory" class="form-check-label">Publish</label>
          </div>
          <div class="form-group">
            <button i18n *ngIf="!attachShow" type="button" (click)="showAttach()" class="btn btn-primary">Attach file</button>
            <label *ngIf="attachShow" i18n>Post Category Image</label>
            <app-data-files-select *ngIf="attachShow" (onLoaded)="dataFileSelectLoaded()" (onFileSelectChanged)="fileSelectChanged($event)" [selectedFiles]="selectedDataFiles" [MultipleFileSelect]="false" dataFilesSelect></app-data-files-select>
            <small *ngIf="attachShow" i18n>Upload post category image or select from list</small>
          </div>
          <div class="form-group text-center m-2">
            <button i18n class="btn btn-success" [disabled]="!pcatForm.valid" type="submit">Save</button>
          </div>
        </form>
    </div>
</div>
