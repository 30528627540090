<div *ngIf="!loggedIn()" class="text-center">
  <form #loginForm="ngForm" (ngSubmit)="login()" class="form-signin">
    <i class="fa fa-5x fa-user mb-4 mt-4"></i>
    <h1 class="h3 mb-3 font-weight-normal" i18n>Please sign in</h1>
    <label for="username" class="sr-only" i18n>User name</label>
    <input type="text" name="username" class="form-control m-3" placeholder="User name"
           required autofocus [(ngModel)]="model.username">
    <label for="inputPassword" class="sr-only" i18n>Password</label>
    <input type="password" name="password" class="form-control m-3" placeholder="Password"
           required [(ngModel)]="model.password">
    <img *ngIf="!loading" (click)="changeCaptcha()" [src]="'data:image/jpeg;base64,'+captchaImage" />
    <input type="text" name="captchaText" class="form-control m-3" required [(ngModel)]="model.captchaText">
    <p class="mt-3"><a class="text-danger text-decoration-none" [routerLink]="['/forgotPassword']" i18n>Forgot your password?</a></p>
    <button class="btn btn-lg btn-primary btn-block" [disabled]="!loginForm.valid" type="submit" i18n>Sign in</button>
    <p class="mt-3"><span i18n>Not registered yet?</span><a i18n class="text-decoration-none m-2" [routerLink]="['/register']">Sign Up</a></p>
    <hr class="mt-5" />
    <div class="text-right footer-language"><span class="m-2">
      <a class="link-secondary text-decoration-none m-3" href="/fa/index.html">فارسی </a></span>
      <a class="link-secondary text-decoration-none m-3" href="/ar/index.html">عربی</a>
      <a class="link-secondary text-decoration-none m-3" href="/index.html">English</a>
    </div>
  </form>
</div>