<div class="container mt-5">
  <h2 i18n>Admin Panel</h2>
  <div class="list-group">
    <div i18n class="list-group-item list-group-item-action active">
      Management
    </div>
    <a i18n [routerLink]="['/userManagement']" class="list-group-item list-group-item-action">User</a>
    <a i18n [routerLink]="['/libraryManagement']" class="list-group-item list-group-item-action">Library</a>
    <a i18n [routerLink]="['/faq/home']" class="list-group-item list-group-item-action">FAQ</a>
    <a i18n [routerLink]="['/post/home']" class="list-group-item list-group-item-action">Post</a>
  </div>
  <!--<div class="tab-panel">
    <tabset class="member-tabset">
      <tab heading="User Management" *appHasRole="['Admin']">
        <div class="container">
          <app-user-management></app-user-management>
        </div>
      </tab>
      <tab heading="Book Management" *appHasRole="['Admin']">
        <div class="container">
          <app-book-management></app-book-management>
        </div>
      </tab>
    </tabset>
  </div>-->
</div>
