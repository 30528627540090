import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() public totalPages: number = 0;
  @Input() public pagesShowCount: number = 0;
  @Input() public currentPage: number = 1;

  @Output('onGoToPage') goToPageEvent = new EventEmitter<number>();

  more: boolean = false;
  actualCount: number = 0;
  inputPageValue: number = this.currentPage;

  constructor() { }

  ngOnInit() {
    this.more = (this.totalPages > this.pagesShowCount + 2) ? true : false;
    if (this.more) {
      this.actualCount = this.pagesShowCount;
    } else {
      this.actualCount = this.totalPages;
    }
  }

  public goToPage(page: number) {
    if (this.currentPage !== page) {
      this.goToPageEvent.emit(page);
      this.currentPage = page;
      this.inputPageValue = page;
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }
}
