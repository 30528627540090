<div class="container">
  <div class="row">
    <div *ngIf="loading" class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="!loading">
      <div class="row gy-2 gx-3 align-items-center shadow-sm p-3 mb-5 bg-white rounded border border-light shadow">
        <div i18n class="col-auto">
          Category
          <select
            class="form-select"
            (change)="getQuestions()"
            [(ngModel)]="questionFilter.categoryId"
          >
            <option></option>
            <option *ngFor="let item of questionCategories" [value]="item.id">
              {{ item.title }}
            </option>
          </select>
        </div>
        <div i18n class="col-auto">
          Importance
          <select
            class="form-select"
            (change)="getQuestions()"
            [(ngModel)]="questionFilter.importance"
          >
            <option></option>
            <option
              *ngFor="let item of [].constructor(3); let i = index"
              [value]="i + 1"
            >
              {{ i + 1 | importance }}
            </option>
          </select>
        </div>
        <div class="col-auto">
          <input
            class="form-check-input"
            type="checkbox"
            id="unanswered"
            (change)="getQuestions()"
            [(ngModel)]="questionFilter.unanswered"
          />
          <label i18n class="form-check-label" for="unanswered">
            Unanswer Questions
          </label>
        </div>
        <div class="col-auto">
          <input
            class="form-check-input"
            type="checkbox"
            id="publish"
            (change)="getQuestions()"
            [(ngModel)]="questionFilter.publish"
          />
          <label i18n class="form-check-label" for="publish"> Publish </label>
        </div>
      </div>
      <table class="table m-1">
        <thead>
          <tr>
            <th i18n>Number</th>
            <th i18n>Publish</th>
            <th i18n>Category</th>
            <th i18n>Question</th>
            <th i18n>Created On</th>
            <th i18n>Locked By</th>
            <th i18n>Answer</th>
          </tr>
        </thead>
        <tr *ngFor="let question of pagedModel.source">
          <td>{{ question.id }}</td>
          <td>
            <input type="checkbox" class="form-check-input" disabled [checked]="question.publish">
          </td>
          <td>{{ getQuestionCategory(question.questionCategoryId).title }}</td>
          <td>
            <div class="text-primary">{{ question.questionText }} <span class="text-dark">(<span i18n>by</span> : <span class="text-info">{{ question.userName }}</span
              >)</span></div>
            <div *ngIf="question.answer?.answerText" class="text-success">
              {{ question.answer?.answerText }}
              <span class="text-dark">(<span i18n>answered by</span> : </span
              ><span class="text-info">{{ question.answer?.answeredBy }}</span
              >)
            </div>
          </td>
          <td>{{ question.createdOn | localizeDate }}</td>
          <td>{{ question.lockedBy }}</td>

          <td>
            <button i18n
              type="button"
              *ngIf="
                question.lockedById !== null &&
                question.lockedById > 0 &&
                currentUser.username === question.lockedBy
              "
              (click)="unlock(question)"
              class="btn btn-info"
            >
              unlock
            </button>
            <button i18n
              type="button"
              *ngIf="
                question.lockedById == null &&
                question.answer?.answerText == null
              "
              (click)="answerTheQuestion(question.id)"
              class="btn btn-info"
            >
              Answer
            </button>
            <button i18n
              type="button"
              *ngIf="question.lockedById == null && question.answer?.answerText"
              (click)="editAnswerTheQuestion(question.id)"
              class="btn btn-info"
            >
              Edit Answer
            </button>
          </td>
        </tr>
      </table>
      <app-pagination
        [totalPages]="pagedModel.totalPages"
        [pagesShowCount]="2"
        (onGoToPage)="goToPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
