import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from 'src/app/_services/book.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BookContent } from 'src/app/_models/bookContent';

@Component({
  selector: 'app-edit-book-content',
  templateUrl: './edit-book-content.component.html',
  styleUrls: ['./edit-book-content.component.css']
})
export class EditBookContentComponent implements OnInit {
  editBookContentForm!: UntypedFormGroup;
  bookContent: any;
  returnUrl : undefined;
  books: any;

  constructor(private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private bookService: BookService,
    private alertify: AlertifyService,
    private router: Router) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.bookContent = data['bookContent'].data;
      this.books = data['books'];
      this.createEditBookContentForm();
    });

    this.route.params.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }
  createEditBookContentForm() {
    this.editBookContentForm = this.fb.group({
      name: [this.bookContent.name, Validators.required],
      bookId: [this.bookContent.bookId, Validators.required],
      text: [this.bookContent.text, Validators.required],
      subjects: [this.bookContent.subjects.join('\n')],
      footNotes: [this.bookContent.footNotes.join('\n')],
      version: [this.bookContent.version, Validators.required],
      displayOrder: [0]
    });
  }
  editBookContent() {
    if (this.editBookContentForm.valid) {
      var bc = new BookContent(this.editBookContentForm.value);
      Object.assign(this.bookContent, bc);
      this.bookService.editBookContent(this.bookContent).subscribe(() => {
        this.alertify.success($localize`Edited successfully`);
        if (this.returnUrl)
          this.router.navigate(['/' + this.returnUrl]);
        else
          this.router.navigate(['/libraryManagement/bookContentManagement']);
      }, error => {
        this.alertify.error(error);
      });
    }
  }

  cancel() {
    if (this.returnUrl)
      this.router.navigate(['/' + this.returnUrl]);
    else
      this.router.navigate(['/libraryManagement/bookContentManagement']);
  }
}
