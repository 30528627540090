export class BookContent {
    public constructor(init?: any) {
        Object.assign(this, init);
        if (typeof init.subjects === "string") {
            this.subjects = init.subjects?.split("\n").filter(function(e: any){return e});
        }
        if (typeof init.footNotes === "string") {
            this.footNotes = init.footNotes?.split("\n").filter(function(e: any){return e});
        }
    }

    id!: number;
    name!: string;
    bookName!: string;
    createdOn!: Date;
    editedOn!: Date;
    displayOrder!: number;
    text!: string;
    subjects!: Array<string>;
    footNotes!: Array<string>;
    version!: string;
}