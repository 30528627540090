import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { BookCategory } from "../_models/bookCategory";
import { Observable } from "rxjs";
import { Language } from "../_models/language";

@Injectable({
    providedIn: 'root'
})
export class LocalService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getLanguages(): Observable<Language>{
        return this.http.get<Language>(this.baseUrl + 'Localization/langs');
    }
}
