import { QuestionFilter } from './../_models/faq/questionFilter';
import { Answer } from './../_models/faq/answer';
import { RestResponse } from './../_models/restResponse';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Question } from '../_models/faq/question';
import { Observable } from 'rxjs';
import { QuestionCategory } from '../_models/faq/questionCategory';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getQuestionCategories(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + 
     `faq/questionCategories`);
  }

  getQuestionCategoriesWithDeleted(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + 
     `faq/questionCategoriesWithDeleted`);
  }

  getUnansweredQuestions(categoryId : Number, pageIndex:Number, pageSize:Number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + 
    `faq/unansweredQuestions?categoryId=${categoryId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }

  getQuestion(questionId : number):Observable<RestResponse>{
    return this.http.get<RestResponse>(this.baseUrl + `faq/getQuestionDetails/${questionId}`);
  }

  lockQuestion(questionId : number): Observable<RestResponse>{
    return this.http.post<RestResponse>(this.baseUrl + "faq/lockQuestion", {id : questionId})
  }

  unlockQuestion(questionId : number): Observable<RestResponse>{
    return this.http.post<RestResponse>(this.baseUrl + "faq/unlockQuestion", {id : questionId})
  }

  answerTheQuestion(answer : any): Observable<RestResponse>{
    return this.http.post<RestResponse>(this.baseUrl + "faq/answerTheQuestion", answer);
  }

  editAnswer(answer : any): Observable<RestResponse>{
    return this.http.put<RestResponse>(this.baseUrl + "faq/editAnswer", answer);
  }

  publishQuestion(questionId : number): Observable<RestResponse>{
    return this.http.put<RestResponse>(this.baseUrl + "faq/publishQuestion", {id : questionId})
  }
  
  unpublishQuestion(questionId : number): Observable<RestResponse>{
    return this.http.put<RestResponse>(this.baseUrl + "faq/unpublishQuestion", {id : questionId})
  }

  getQuestions(questionFilter?:QuestionFilter): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + 'faq/questions', questionFilter);
  }

  addQuestion(question : any): Observable<RestResponse>{
    return this.http.post<RestResponse>(this.baseUrl + "faq/addQuestion", question);
  }

  addQuestionCategory(questionCategory : any): Observable<RestResponse>{
    return this.http.post<RestResponse>(this.baseUrl + "faq/addQuestionCategory", questionCategory);
  }
  
  editQuestionCategory(questionCategory : any): Observable<RestResponse>{
    return this.http.put<RestResponse>(this.baseUrl + "faq/editQuestionCategory", questionCategory);
  }

  deleteQuestionCategory(questionCategoryId : number): Observable<RestResponse>{
    return this.http.delete<RestResponse>(this.baseUrl + `faq/deleteQuestionCategory/${questionCategoryId}`);
  }

  undeleteQuestionCategory(questionCategoryId : number): Observable<RestResponse>{
    return this.http.delete<RestResponse>(this.baseUrl + `faq/undeleteQuestionCategory/${questionCategoryId}`);
  }
}
