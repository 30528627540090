import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    fpf: UntypedFormGroup = new UntypedFormGroup({});
    constructor(private fb: UntypedFormBuilder, private userService: UserService,
        private alertify: AlertifyService) { }

    ngOnInit() {
        this.createRPForm();
    }

    createRPForm() {
        this.fpf = this.fb.group({
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
        });
    }

    forgotPassword() {
        let model = Object.assign({}, this.fpf?.value);
        this.userService.resetPasswordRequet(model.email).subscribe(() => {
            this.alertify.success($localize`Check your email`);
        }, error => {
            this.alertify.error(error);
        });
    }
}
