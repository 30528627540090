import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RestResponse } from '../_models/restResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCaptcha(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + 'auth/captcha');
  }
}