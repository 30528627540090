<div class="container">
  <div class="row">
    <div class="d-flex flex-wrap flex-row bd-highlight mb-3">
      <div class="p-4 bd-highlight">
        <a [routerLink]="['/faq/questions']" class="text-decoration-none">
          <div class="card text-dark bg-light tile-size">
            <div class="card-body p-4">
              <h4 i18n class="card-title">Questions</h4>
              <p i18n class="card-text">List of questions</p>
            </div>
          </div>
        </a>
      </div>
      <div class="p-4 bd-highlight">
        <a [routerLink]="['/faq/add-question']" class="text-decoration-none">
          <div class="card text-dark bg-light tile-size">
            <div class="card-body p-4">
              <h4 i18n class="card-title">Add Question</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="p-4 bd-highlight">
        <a [routerLink]="['/faq/category-list']" class="text-decoration-none">
          <div class="card text-dark bg-light tile-size">
            <div class="card-body p-4">
              <h4 i18n class="card-title">Question Category</h4>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
