import { CommonHelper } from './../helper/commonHelper';
import { environment } from './../../environments/environment';
import { AfterViewInit, Directive, HostListener, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appImage]'
})
export class ImageDirective implements OnInit, AfterViewInit{

    @Input() src: string | undefined;
    @Input() show: boolean = false;

    constructor(private imageRef: ElementRef,
        private commonHelper : CommonHelper) {

    }

    ngOnInit(): void {
    }

    load(){
        if (this.commonHelper.isImage(this.src!)) {
            if(this.src?.toLowerCase().startsWith("https://") || this.src?.toLowerCase().startsWith("http://")){
                this.setImage(this.src);
            }else{
                this.setImage(environment.baseUrl + this.src!);
            }
        } else {
            this.setImage('assets/default-image.svg');
        }
    }
    ngAfterViewInit(): void {
        const threshold = 0.2; // how much % of the element is in view
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.load();
                        // run your animation code here
                        //observer.disconnect(); // disconnect if you want to stop observing else it will rerun every time its back in view. Just make sure you disconnect in ngOnDestroy instead
                    }
                });
            },
            { threshold }
        );
        observer.observe(this.imageRef.nativeElement);
    }
    @HostListener("error")
    private onError() {
        this.setImage('assets/default-image.svg');
        //this.imageRef.nativeElement.style.display = "none";
    }
    // ngAfterViewInit(): void{
    //     const img = new Image();
    //     img.onload = () => {
    //         this.setImage(this.src!);
    //     };

    //     img.onerror = () => {
    //         // Set a placeholder image 
    //         this.setImage('assets/placeholder.png');
    //     };

    //     img.src = this.src!;
    // }

    private setImage(src: string) {
        if(this.show){
            this.imageRef.nativeElement.setAttribute('src', src);
        }
        else{
            this.imageRef.nativeElement.setAttribute('src', 'assets/default-image.svg');
        }
    }
}