import { RestResponse, RestResponseStatus } from './../_models/restResponse';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { CaptchaService } from '../_services/captcha.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    model: any = {};
    loading: Boolean = true
    captchaImage : any;
    captchaKey: String = "";

    constructor(public authService: AuthService, private alertify: AlertifyService,
        private router: Router, private captchaService:CaptchaService) { }

    ngOnInit(): void {
        this.changeCaptcha();
    }

    login() {
        this.model.captchaKey = this.captchaKey;
        this.authService.login(this.model).subscribe({
            next: (restResponse: RestResponse) =>{
                this.alertify.showMessage(restResponse);
                if(restResponse.status==RestResponseStatus.Success){
                    this.router.navigate(['/']);
                }
            },
            error: (error: RestResponse) =>{
                this.alertify.error(error.message);
            }
        })
    }

    changeCaptcha(){
        this.captchaService.getCaptcha().subscribe({
            next: (response: RestResponse) =>{
                if(response.status==RestResponseStatus.Success){
                    this.captchaImage = response.data.image;
                    this.captchaKey = response.data.key;
                    this.loading = false;
                }else{
                    this.alertify.showMessage(response);
                }
            },
            error: (error: RestResponse) =>{
                this.alertify.error(error.message);
            }
        })
    }
    loggedIn() {
        return this.authService.loggedIn();
    }
}
