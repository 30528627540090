import { RestResponse, RestResponseStatus } from './../../../_models/restResponse';
import { AuthService } from './../../../_services/auth.service';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { FaqService } from 'src/app/_services/faq.service';
import { QuestionCategory } from 'src/app/_models/faq/questionCategory';
import { Question } from 'src/app/_models/faq/question';
import { PagedModel } from 'src/app/_models/pagedModel';
import { Component, OnInit } from '@angular/core';
import { QuestionFilter } from 'src/app/_models/faq/questionFilter';

@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.css']
})
export class QuestionsListComponent implements OnInit {

  loading: Boolean = true;
  pagedModel: PagedModel<Question> = {} as PagedModel<Question>;
  questionCategories: QuestionCategory[] = [];
  currentUser: any;
  questionFilter: QuestionFilter = {};

  constructor(private faqService: FaqService,
    private alertifyService: AlertifyService,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.questionFilter.pageSize = 10;
    this.currentUser = this.authService.currentUser;
    this.faqService.getQuestionCategories().subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        this.questionCategories = restResponse.data;
        this.faqService.getQuestions(this.questionFilter).subscribe((restResponse: RestResponse) => {
          if (restResponse.status === RestResponseStatus.Success) {
            this.pagedModel = restResponse.data;
            this.loading = false;
          }
        });
      }
    });
  }

  public getQuestionCategory(catId:number):QuestionCategory{
    return this.questionCategories.filter(qc=>qc.id==catId)[0]
  }

  public answerTheQuestion(questionId: number) {
    this.faqService.lockQuestion(questionId).subscribe((restResponse: RestResponse) => {
      this.alertifyService.message(restResponse.message);
      if (restResponse.status === RestResponseStatus.Success) {
        this.router.navigate(['/faq/answer-the-question', questionId]);
      }
    });
  }

  public editAnswerTheQuestion(questionId: number) {
    this.faqService.lockQuestion(questionId).subscribe((restResponse: RestResponse) => {
      this.alertifyService.message(restResponse.message);
      if (restResponse.status === RestResponseStatus.Success) {
        this.router.navigate(['/faq/answer-the-question', questionId]);
      }
    });
  }

  public unlock(question: Question) {
    this.faqService.unlockQuestion(question.id).subscribe((restResponse: RestResponse) => {
      this.alertifyService.message(restResponse.message);
      if (restResponse.status === RestResponseStatus.Success) {
        question!.lockedById = null;
        question!.lockedBy = "";
      }
    });
  }

  public goToPage(page: number) {
    this.questionFilter.pageIndex = page - 1;
    this.faqService.getQuestions(this.questionFilter).subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        this.pagedModel = restResponse.data;
        this.loading = false;
      }
    });
  }

  public getQuestions(){
    this.questionFilter.pageIndex = 0;
    this.faqService.getQuestions(this.questionFilter).subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        this.pagedModel = restResponse.data;
        this.loading = false;
      }
    });
  }
}
