import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BookService } from '../_services/book.service';

@Injectable()
export class BookFileEditResolver implements Resolve<any> {
    constructor(private bookService: BookService, private router: Router,
        private alertify: AlertifyService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        console.log(parseInt(route.paramMap.get('id')!));
        return this.bookService.getBookFile(parseInt(route.paramMap.get('id')!)).pipe(
            catchError(error => {
                this.alertify.error($localize`Problem retrieving your data`);
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}
