import { BookCategory } from './../../../_models/bookCategory';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { Router } from '@angular/router';
import { BookCategoriesAndBooks } from 'src/app/_models/bookCategoriesAndBooks';
import { RestResponse } from 'src/app/_models/restResponse';
import { BookCategoryViewModel } from 'src/app/_models/bookCategoryViewModel';

@Component({
    selector: 'app-list-group-category',
    templateUrl: './list-group-category.component.html',
    styleUrls: ['./list-group-category.component.css']
})
export class ListGroupCategoryComponent implements OnInit {

    @Input() booksAndCategories: BookCategoriesAndBooks | undefined;

    //sortedBookCategories: BookCategoriesAndBooks[] = [];

    constructor(private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        console.log(this.booksAndCategories!.bookCategories);
        //this.sortedBookCategories = this.booksAndCategories?.bookCategories.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)
    }

    deleteItem(list: any[], item: any) {
        const index = list.indexOf(item);
        if (index > -1)
            list.splice(index, 1);
    }

    deleteCategory(category: BookCategoryViewModel) {
        this.alertify.confirm($localize`Are you sure to delete ${category.name}?`, $localize`Delete category`, () => {
            this.bookService.deleteCategory(category.id).subscribe({
                next: (restResponse:RestResponse)=>{
                this.alertify.success($localize`Deleted successfully`);
                this.deleteItem(this.booksAndCategories!.bookCategories, category);
                this.alertify.showMessage(restResponse);
                },
            error: (restResponse:RestResponse)=> {
                this.alertify.showMessage(restResponse);
            }});
        });
    }

    collapse(id: string){
        var d = document.getElementById(id);
        if(d?.classList.contains("collapse")){
            d?.classList.remove("collapse");
        }else{
            d?.classList.add("collapse");
        }
    }
    //deleteBook(list, item) {
    //    this.alertify.confirm(`Are you sure to delete ${item.name}?`, `Delete book`, () => {
    //        this.bookService.deleteBook(item.id).subscribe(() => {
    //            this.alertify.success('Delete successful');
    //            this.deleteItem(list, item);
    //        }, error => {
    //            this.alertify.error(error);
    //        });
    //    });
    //}

    //deleteBookFile(list, item) {
    //    this.alertify.confirm(`Are you sure to delete ${item.name}?`, `Delete book file`, () => {
    //        this.bookService.deleteBookFile(item.id).subscribe(() => {
    //            this.alertify.success('Delete successful');
    //            this.deleteItem(list, item);
    //        }, error => {
    //            this.alertify.error(error);
    //        });
    //    });
    //}
}
