import { Component, OnInit } from '@angular/core';
import { RestResponse } from 'src/app/_models/restResponse';
import { CacheService } from 'src/app/_services/cache.service';
import { PostService } from 'src/app/_services/post.service';

@Component({
  selector: 'app-post-home',
  templateUrl: './post-home.component.html',
  styleUrls: ['./post-home.component.css']
})
export class PostHomeComponent implements OnInit {

  constructor(private postService: PostService,
    private cacheService: CacheService) { }

  ngOnInit(): void {
    var postFlagsCached = this.cacheService.get("postFlags")
    if (!postFlagsCached) {
      this.postService.getPostFlagsKeyValue().subscribe({
        next: (r: RestResponse) => {
          this.cacheService.set("postFlags", r.data);
        }
      })
    }
  }

}
