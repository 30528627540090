import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getFileList(path: string) {
        return this.http.get(this.baseUrl + 'upload/fileList?path=' + path);
    }

    uploadFile(formData: FormData, path: string) {
        return this.http.post(this.baseUrl + 'upload/file', formData,
            { reportProgress: true, observe: 'events', params: { path: path } });
    }

    uploadFileToPublicStorage(formData: FormData, path: string) {
        return this.http.post(this.baseUrl + 'upload/fileToPublicStorage', formData,
            { reportProgress: true, observe: 'events', params: { path: path } });
    }
}
