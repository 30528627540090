import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '../_models/user';
import { PasswordValidator } from '../helper/password.validator';
import { CaptchaService } from '../_services/captcha.service';
import { RestResponse, RestResponseStatus } from '../_models/restResponse';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
    user: User | undefined;
    registerForm: UntypedFormGroup = new UntypedFormGroup({});
    loading: Boolean = true
    captchaImage : any;
    captchaKey: String = "";

    constructor(private authService: AuthService, private router: Router,
        private alertify: AlertifyService, private fb: UntypedFormBuilder, private captchaService:CaptchaService) { }

    ngOnInit(): void {
        this.changeCaptcha();
        this.createRegisterForm();
    }
    createRegisterForm() {
        this.registerForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(8), PasswordValidator.strong]],
            confirmPassword: ['', Validators.required],
            captchaText: ['', Validators.required]
        }, { validator: this.passwordMatchValidator });
    }

    passwordMatchValidator(g: UntypedFormGroup) {
        return g.get('password')!.value === g.get('confirmPassword')!.value ? null : { 'mismatch': true };
    }
    changeCaptcha(){
        this.captchaService.getCaptcha().subscribe({
            next: (response: RestResponse) =>{
                if(response.status==RestResponseStatus.Success){
                    this.captchaImage = response.data.image;
                    this.captchaKey = response.data.key;
                    this.loading = false;
                }else{
                    this.alertify.showMessage(response);
                }
            },
            error: (error: RestResponse) =>{
                this.alertify.error(error.message);
            }
        })
    }
    register() {
        if (this.registerForm?.valid) {
            //this.user = Object.assign({}, this.registerForm.value);
            var regData: any = { 
                username: this.registerForm.value.username, 
                password: this.registerForm.value.password,
                captchaKey: this.captchaKey,
                captchaText: this.registerForm.value.captchaText
            };
            this.authService.register(regData).subscribe({
                next: (response: RestResponse) => {
                    this.alertify.success($localize`Registration successful`);
            }, error: (error: RestResponse) => {
                this.alertify.showMessage(error);
            }, complete: () => {
                //this.authService.login(this.user).subscribe(() => {
                    this.router.navigate(['/home']);
                //});
            }});
        }
    }

    cancel() {
        this.router.navigate(['/home']);
    }
}
