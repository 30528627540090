import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import * as moment from 'moment-hijri';
import * as moment_ar from 'moment-hijri';

@Pipe({
    name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {
    locale: string = 'en';
    constructor(@Inject(LOCALE_ID) locale: string) {
        this.locale = locale;
    }
    transform(value: any, args?: any): any {
        switch (this.locale) {
            case "fa":
                let MomentDatefa = moment(value, 'YYYY/MM/DD');
                return MomentDatefa.locale(this.locale).format('YYYY/M/D');

            case "ar":
                let MomentDate = moment_ar(value, 'YYYY/MM/DD');
                return MomentDate.locale(this.locale).format('iYYYY/iM/iD');

            default:
                let MomentDatear = moment(value, 'YYYY/MM/DD');
                return MomentDatear.locale(this.locale).format('YYYY/M/D');
        }

    }
}