import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from './_models/user';
import { NavigationEnd, Router } from '@angular/router';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    jwtHelper = new JwtHelperService();

    @ViewChild(NavMenuComponent) navMenu!: NavMenuComponent

    constructor(private authService: AuthService, private router: Router) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                if (ev.url) {

                    PathControl.previousPath = PathControl.currentPath
                    PathControl.currentPath = ev.url

                    if (PathControl.previousPath) {
                        this.navMenu.previousUrl = PathControl.previousPath
                    }

                    console.log("current path:" + PathControl.currentPath + "\nprevious path:" + PathControl.previousPath)
                }
            }
        });
    }

    ngOnInit(): void {
        const token = localStorage.getItem('token');
        const user: User = JSON.parse(localStorage.getItem('user')!);
        if (token) {
            this.authService.decodedToken = this.jwtHelper.decodeToken(token);
        }
        if (user) {
            this.authService.currentUser = user;
        }
    }

}

export const PathControl = {
    previousPath: '',
    currentPath: ''
};

export const CacheData = {
    data: new Map<string, object>()
};