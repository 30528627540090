import { Question } from './../../../_models/faq/question';
import { RestResponse, RestResponseStatus } from './../../../_models/restResponse';
import { Answer } from './../../../_models/faq/answer';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService } from './../../../_services/alertify.service';
import { FaqService } from './../../../_services/faq.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { isFunction } from 'lodash';

@Component({
  selector: 'app-answer-the-question',
  templateUrl: './answer-the-question.component.html',
  styleUrls: ['./answer-the-question.component.css']
})
export class AnswerTheQuestionComponent implements OnInit {

  answerForm?: UntypedFormGroup
  answer?: Answer;
  questionId?: number;
  loading: boolean = true;
  question?: Question;
  publish?: boolean;
  isEdit: boolean = false;

  constructor(private fb: UntypedFormBuilder,
    private faqService: FaqService,
    private alertify: AlertifyService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.questionId = +this.route.snapshot.paramMap.get("id")!;
    this.faqService.getQuestion(this.questionId).subscribe(q => {
      if (q.status === RestResponseStatus.Success) {
        this.question = q.data;
        if (this.question?.answer?.answerText) {
          this.isEdit = true;
        }
        this.createAnswerForm();
      } else {
        this.alertify.showMessage(q);
      }
      this.loading = false;
    })
  }

  createAnswerForm() {
    this.answerForm = this.fb.group({
      answerText: [this.question?.answer?.answerText, Validators.required],
      publish: [this.question?.publish]
    });
  }

  answerTheQuestion() {
    if (this.answerForm!.valid) {  //   add answer
      if (!this.isEdit) {
        this.faqService.answerTheQuestion({ questionId: this.question?.id, answerText: this.answerForm?.get("answerText")?.value }).subscribe((restResponse: RestResponse) => {
          this.alertify.showMessage(restResponse);
          if (restResponse.status === RestResponseStatus.Success && this.answerForm?.get("publish")?.value === true) {
            this.faqService.publishQuestion(this.question!.id).subscribe((restResponse: RestResponse) => {
              this.alertify.showMessage(restResponse);
            });
          }else{
            this.faqService.unpublishQuestion(this.question!.id).subscribe((restResponse: RestResponse) => {
              this.alertify.showMessage(restResponse);
            });
          }
          this.router.navigate(['/faq/questions']);
        });
      }else{       //  edit answer
        this.faqService.editAnswer({ id: this.question?.answer?.id, answerText: this.answerForm?.get("answerText")?.value }).subscribe((restResponse: RestResponse) => {
          this.alertify.showMessage(restResponse);
          if (restResponse.status === RestResponseStatus.Success && this.answerForm?.get("publish")?.value === true) {
            this.faqService.publishQuestion(this.question!.id).subscribe((restResponse: RestResponse) => {
              this.alertify.showMessage(restResponse);
            });
          }else{
            this.faqService.unpublishQuestion(this.question!.id).subscribe((restResponse: RestResponse) => {
              this.alertify.showMessage(restResponse);
            });
          }
          this.router.navigate(['/faq/questions']);
        });

      }
    }
  }

  cancel() {
    this.faqService.unlockQuestion(this.question!.id).subscribe((restResponse: RestResponse) => {
      this.alertify.message(restResponse.message);
      this.router.navigate(['/faq/questions']);
    });
  }
}
