<form [formGroup]="editBookContentForm" (ngSubmit)="editBookContent()">
    <h2 i18n class="text-center text-primary">Edit Book Content</h2>
    <hr>
  
    <div class="form-group">
      <label i18n>Name</label>
      <input type="text"
             [ngClass]="{'is-invalid': editBookContentForm.get('name')?.errors
          && editBookContentForm.get('name')?.touched}"
             class="form-control"
             formControlName="name"
             placeholder="Name">
      <div i18n class="invalid-feedback">Please choose a name</div>
    </div>
    <div class="form-group">
      <label i18n>Book Name</label>
      <select class="form-select" formControlName="bookId">
        <option [ngValue]="null" disabled [selected]="bookContent.bookId==0">Select book</option>
        <option *ngFor="let book of books" [value]="book.id" [selected]="book.id==bookContent.bookId">{{book.name}}</option>
      </select>
      <div i18n class="invalid-feedback">Please select book</div>
    </div>
    <div class="form-group">
      <label i18n>Text</label>
      <textarea
             [ngClass]="{'is-invalid': editBookContentForm.get('text')?.errors
          && editBookContentForm.get('text')?.touched}"
             class="form-control"
             formControlName="text"
             placeholder="Text"></textarea>
      <div i18n class="invalid-feedback">Please enter book text</div>
    </div>
    <div class="form-group">
      <label i18n>Subjects</label>
      <textarea
             [ngClass]="{'is-invalid': editBookContentForm.get('subjects')?.errors
          && editBookContentForm.get('subjects')?.touched}"
             class="form-control"
             formControlName="subjects"
             placeholder="Subjects"></textarea>
    </div>
    <div class="form-group">
      <label i18n>Foot notes</label>
      <textarea
             [ngClass]="{'is-invalid': editBookContentForm.get('footNotes')?.errors
          && editBookContentForm.get('footNotes')?.touched}"
             class="form-control"
             formControlName="footNotes"
             placeholder="FootNotes"></textarea>
    </div>
    <div class="form-group">
      <label i18n>Book version</label>
      <input type="text"
             [ngClass]="{'is-invalid': editBookContentForm.get('version')?.errors
          && editBookContentForm.get('version')?.touched}"
             class="form-control"
             formControlName="version"
             placeholder="Version">
      <div i18n class="invalid-feedback">Please enter book text version</div>
    </div>
    <div class="form-group">
      <label i18n>Display order</label>
      <input type="number"
             class="form-control"
             formControlName="displayOrder"
             placeholder="Display Order">
    </div>
  
    <div class="form-group text-center">
      <button i18n class="btn btn-success" [disabled]="!editBookContentForm.valid" type="submit">Save</button>
      <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
    </div>
  </form>
  