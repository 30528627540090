<div class="container">
  <div class="row">
    <div *ngIf="loading" class="spinner-border text-info" role="status">
        <span i18n class="sr-only">Loading...</span>
      </div>
    <form *ngIf="!loading" [formGroup]="faqForm!" (ngSubmit)="add()">
      <div class="form-group p-2">
        <label i18n for="importance">Importance</label>
        <select class="form-control" formControlName="importance"
        [ngClass]="{
            'is-invalid':
              faqForm!.get('importance')?.errors &&
              faqForm!.get('importance')?.touched}">
          <option
            *ngFor="let item of [].constructor(3); let i = index"
            [value]="i + 1"
          >
            {{ i + 1 | importance }}
          </option>
        </select>
        <div i18n class="invalid-feedback">Please select importance.</div>
      </div>
      <div class="from-group p-2">
        <label i18n for="importance">Question Category</label>
        <select
          class="form-control"
          formControlName="questionCategoryId"
          [ngClass]="{
            'is-invalid':
              faqForm!.get('questionCategoryId')?.errors &&
              faqForm!.get('questionCategoryId')?.touched
          }">
          <option *ngFor="let item of questionCategories" [value]="item.id">
            {{ item.title }}
          </option>
        </select>
        <div i18n class="invalid-feedback">Please select question category.</div>
      </div>
      <div class="form-group p-2">
        <label i18n for="questionText">Question</label>
        <textarea
          class="form-control"
          [ngClass]="{
            'is-invalid':
              faqForm!.get('questionText')?.errors &&
              faqForm!.get('questionText')?.touched}"
          formControlName="questionText"
          placeholder="Please enter the question"
          rows="5"
        ></textarea>
        <div i18n class="invalid-feedback">Please enter question.</div>
      </div>
      <div class="form-group p-2">
        <label i18n for="answerText">Answer</label>
        <textarea
          class="form-control"
          [ngClass]="{
            'is-invalid':
              faqForm!.get('answerText')?.errors &&
              faqForm!.get('answerText')?.touched}"
          formControlName="answerText"
          placeholder="Please enter the answer"
          rows="5"
        ></textarea>
        <div i18n class="invalid-feedback">Please enter answer.</div>
      </div>
      <div class="form-group p-2">
        <input class="form-check-input m-1" type="checkbox" id="publish" formControlName="publish">
        <label i18n for="publish" class="form-check-label">This question and answer is useful for all people</label>
      </div>
      <div class="form-group p-2">
        <input class="form-check-input m-1" type="checkbox" id="anotherQuestion" formControlName="anotherQuestion">
        <label i18n for="anotherQuestion" class="form-check-label">Add another question</label>
      </div>
      <div class="form-group p-2">
        <button i18n class="btn btn-success" [disabled]="!faqForm!.valid" type="submit">Save</button>
        <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
      </div>
    </form>
  </div>
</div>