<div *ngIf="loading" class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div *ngIf="!loading">
<form [formGroup]="editBookFileForm" (ngSubmit)="editBookFile()">
  <h2 i18n class="text-center text-primary">Edit Book File</h2>
  <hr>

  <div class="form-group">
    <label i18n>Name</label>
    <input type="text"
           [ngClass]="{'is-invalid': editBookFileForm.get('name')?.errors
        && editBookFileForm.get('name')?.touched}"
           class="form-control"
           formControlName="name"
           placeholder="Name">
    <div i18n class="invalid-feedback">Please choose a name</div>
  </div>
  <div class="form-group">
    <label i18n>Type</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <input type="text" [ngClass]="{'is-invalid': editBookFileForm.get('type')?.errors
        && editBookFileForm.get('type')?.touched}"
               class="form-control"
               formControlName="type"
               placeholder="Type" />
      </div>
      <select class="form-select" (input)="onTypeSelect($event)">
        <option i18n [ngValue]="null" disabled selected>Select type or write in box</option>
        <option *ngFor="let t of types" [value]="t">{{t}}</option>
      </select>
    </div>
    <div i18n class="invalid-feedback">Please enter type</div>
  </div>
  <div class="form-group">
    <label i18n>Version</label>
    <input type="text"
           [ngClass]="{'is-invalid': editBookFileForm.get('version')?.errors
        && editBookFileForm.get('version')?.touched}"
           class="form-control"
           formControlName="version"
           placeholder="Version">
    <div i18n class="invalid-feedback">Please enter file version</div>
  </div>
  <div class="form-group">
    <label i18n>Display Order</label>
    <input type="number"
           class="form-control"
           formControlName="displayOrder"
           placeholder="Display Order">
  </div>
  <div class="form-group">
    <label i18n>Upload Book File</label>
    <app-data-files-select (onLoaded)="dataFileSelectLoaded()" (onFileSelectChanged)="fileSelectChanged($event)" [selectedFiles]="selectedDataFiles" [MultipleFileSelect]="false" dataFilesSelect></app-data-files-select>
    <!-- <app-upload [initFileSelect]="bookFile.urlAddress" [path]="'books'" [buttonContent]="'Upload book'" (onFileSelected)="fileSelected($event)"></app-upload> -->
    <small i18n>Upload the book file or select from list of book files</small>
  </div>
  <div class="form-group text-center">
    <button i18n class="btn btn-success" [disabled]="!editBookFileForm.valid" type="submit">Save</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
</div>

