<ul *ngFor="let category of booksAndCategories!.bookCategories" class="list-group">
  <li class="list-group-item"><a [routerLink]="['/libraryManagement/bookCategoryManagement/'+category.id]">{{category.name}}</a>
    <span class="pull-right">
      <a [routerLink]="['/libraryManagement/editCategory/'+category.id]" class="fa fa-edit btn btn-default btn-sm"
        title="Edit {{category.name}} category"></a>
      <a [routerLink]="['/libraryManagement/addCategory/'+category.id]" class="fa fa-plus-circle btn btn-default btn-sm"
        title="Add sub category for {{category.name}}"></a>
      <a class="fa fa-trash btn btn-default btn-sm" (click)="deleteCategory(category)"
        title="Delete"></a>
    </span>
  </li>
</ul>
<div class="container">
  <div class="card-columns">
    <app-book-card [editBookLink]="'/libraryManagement/editAuthorBook/'" [books]="booksAndCategories!.books"
      [returnUrl]="'libraryManagement/authorManagement'"></app-book-card>
  </div>
</div>