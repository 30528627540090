import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BookService } from '../_services/book.service';
import { RestResponse } from '../_models/restResponse';

@Injectable()
export class BookEditResolver implements Resolve<RestResponse> {
    constructor(private bookService: BookService, private router: Router,
        private alertify: AlertifyService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<RestResponse> {
        return this.bookService.getBook(parseInt(route.paramMap.get('id')!)).pipe(
            // map((data: RestResponse) => {
            //     data.releaseDate = data.releaseDate.split("T", 1)[0];
            //     return data;
            // }),
            catchError(error => {
                this.alertify.error($localize`Problem retrieving your data`);
                this.router.navigate(['/home']);
                return of(null);
            })
        )as Observable<RestResponse>;
    }
}
