import { Injectable } from '@angular/core';
@Injectable()
export class CommonHelper {
    static removeAllHtmlTags(html:string) : string{
        var regex = /(<([^>]+)>)/ig
        return html.replace(regex, "");
    }
    static importanceToString(importance: number) {
        switch (importance) {
            case 1: return "";
            case 2: return "";
            case 3: return "";
            default: return "";
        }
    }

    isImage(url : string){
        var ext = url.split('.').pop()?.toLowerCase();
        if(ext=="jpg" || ext=="png" || ext=="webp"){
            return true
        }
        return false;
    }

    static getExtension(url : string){
        return url.split('.').pop()?.toLowerCase();
    }

    isElementVisible (el : any, holder : any) {
        holder = holder || document.body
        const { top, bottom, height } = el.getBoundingClientRect()
        const holderRect = holder.getBoundingClientRect()
      
        return top <= holderRect.top
          ? holderRect.top - top <= height
          : bottom - holderRect.bottom <= height
      }
}