import { DataFile } from './../../../_models/dataFile';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-add-book-file',
    templateUrl: './add-book-file.component.html',
    styleUrls: ['./add-book-file.component.css']
})
export class AddBookFileComponent implements OnInit {
    addBookFileForm!: UntypedFormGroup;
    bookFile: any;
    bookId!: number;
    urlAddress!: string;
    types: string[] = ['pdf', 'docx', 'doc', 'googledrive.pdf', 'googledrive.docx', 'googledrive.doc'];
    returnUrl: string | null | undefined;
    selectedDataFiles: DataFile[] = [];

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.bookId = parseInt(params.get('id')!);
            this.returnUrl = params.get('returnUrl');
        });
        this.createaddBookFileForm();
    }
    onTypeSelect(typeselected: Event) {
        this.addBookFileForm.patchValue({ type: (typeselected.target as HTMLSelectElement).value });
    }
    // fileSelected(event : any) {
    //     this.urlAddress = event;
    // }
    fileSelectChanged(dataFile: DataFile) {
        if (dataFile.selected) {
            this.selectedDataFiles = [];
            this.selectedDataFiles.push(dataFile);
        } else {
            this.selectedDataFiles = [];
        }
    }
    createaddBookFileForm() {
        this.addBookFileForm = this.fb.group({
            name: ['', Validators.required],
            type: ['', Validators.required],
            version: ['', Validators.required],
            displayOrder: [0]
        });
    }

    addBookFile() {
        if (this.selectedDataFiles.length <= 0) {
            this.alertify.error($localize`Please upload or select file.`);
            return;
        }
        if (this.addBookFileForm.valid) {
            this.bookFile = Object.assign({}, this.addBookFileForm.value);
            this.bookFile.bookId = this.bookId;
            if (this.selectedDataFiles.length > 0) {
                this.bookFile.dataFileId = this.selectedDataFiles[0].id;
            }
            this.bookService.addBookFile(this.bookFile).subscribe(() => {
                this.alertify.success($localize`Saved successfully`);
                if (this.returnUrl) {
                    this.router.navigate(['/' + this.returnUrl]);
                } else {
                    this.router.navigate(['/libraryManagement/bookManagement']);
                }
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        if (this.returnUrl) {
            this.router.navigate(['/' + this.returnUrl]);
        } else {
            this.router.navigate(['/libraryManagement/bookManagement']);
        }
    }
}
