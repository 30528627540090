<div class="container">
  <div class="row">
    <h2 i18n>Book management</h2>
  </div>
  <div class="float-right">
    <a [routerLink]="['/libraryManagement/addBook']" class="btn btn-primary">
      <span class="fa fa-plus"></span>
    </a>
  </div>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th i18n scope="col">Name</th>
          <!-- <th i18n scope="col">Description</th> -->
          <!-- <th i18n scope="col">Category</th> -->
          <th i18n scope="col">Author</th>
          <th i18n scope="col">Release Date</th>
          <th i18n scope="col">Display Order</th>
          <th i18n scope="col">Is Main</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let book of books">
          <td scope="row">{{book.id}}</td>
          <td scope="row">{{book.name}}</td>
          <!-- <td scope="row">{{book.description}}</td> -->
          <!-- <td scope="row">{{book.category}}</td> -->
          <td scope="row">{{book.author}}</td>
          <td scope="row">{{book.releaseDate}}</td>
          <td scope="row">{{book.displayOrder}}</td>
          <td scope="row"><input type="checkbox" class="custom-checkbox" [checked]="book.isMain" /></td>
          <td scope="row">
            <a i18n [routerLink]="['/libraryManagement/editBook/'+book.id]" class="btn btn-outline-primary">
              Edit
            </a>
          </td>
          <td scope="row">
            <a i18n (click)="deleteBook(book)" class="btn btn-outline-primary">
              Delete
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>