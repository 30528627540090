import { RestResponse } from './../_models/restResponse';
import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error : any) => {
                return throwError(() => error.error);
                /*
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        return throwError(() => new Error(error.statusText));
                    }
                    //const applicationError = error.headers.get('Application-Error');
                    //if (applicationError) {
                    //    console.error(applicationError);
                    //    return throwError(applicationError);
                    //}
                    const serverError = error.error.errors;
                    let modalStateErrors = '';
                    if (serverError && typeof serverError === 'object') {
                        for (const key in serverError) {
                            if (serverError[key]) {
                                modalStateErrors += serverError[key] + '\n';
                            }
                        }
                    }
                    return throwError(() => new Error(modalStateErrors || serverError || 'Server Error'));
                }
                return throwError(() => new Error(error));

                */
            })
        );
    }
}

export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
};
