import { DataFile } from './../_models/dataFile';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { RestResponse } from "../_models/restResponse";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
    providedIn: 'root'
})
export class DataFileService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getDataFiles(): Observable<RestResponse> {
        return this.http.get<RestResponse>(this.baseUrl + 'dataFiles/getDataFiles');
    }

    addDataFile(dataFile:DataFile): Observable<RestResponse> {
        return this.http.post<RestResponse>(this.baseUrl + 'dataFiles/add/', dataFile);
    }
}