import { Question } from 'src/app/_models/faq/question';
import { RestResponse, RestResponseStatus } from './../../../_models/restResponse';
import { QuestionCategory } from './../../../_models/faq/questionCategory';
import { Router } from '@angular/router';
import { AlertifyService } from './../../../_services/alertify.service';
import { FaqService } from 'src/app/_services/faq.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})
export class AddQuestionComponent implements OnInit {

  faqForm?: UntypedFormGroup;
  loading: Boolean = true;
  questionCategories: QuestionCategory[] = [];
  //anotherQuestion: boolean = true;

  constructor(private fb: UntypedFormBuilder,
    private faqService: FaqService,
    private alertify: AlertifyService,
    private router: Router) { }

  ngOnInit(): void {
    this.faqService.getQuestionCategories().subscribe((restResponse: RestResponse) => {
      if (restResponse.status === RestResponseStatus.Success) {
        this.questionCategories = restResponse.data;
        this.createFaqForm();
        this.loading = false;
      }
    });
  }

  createFaqForm() {
    this.faqForm = this.fb.group({
      importance: ["", Validators.required],
      questionCategoryId: ["", Validators.required],
      questionText: ["", Validators.required],
      answerText: [""],
      anotherQuestion: [false],
      publish: [false]
    });
  }
  add() {
    if (this.faqForm?.valid) {
      this.faqService.addQuestion({
        questionCategoryId: this.faqForm?.get('questionCategoryId')?.value,
        questionText: this.faqForm?.get('questionText')?.value,
        importance: this.faqForm?.get('importance')?.value
      }).subscribe((restResponse: RestResponse) => {
        this.alertify.showMessage(restResponse);
        if (restResponse.status === RestResponseStatus.Success && this.faqForm?.get("answerText")?.value) {
          let question: Question = restResponse.data;
          this.faqService.answerTheQuestion({ questionId: question.id, answerText: this.faqForm?.get("answerText")?.value }).subscribe((restResponse: RestResponse) => {
            if (this.faqForm?.get("publish")?.value === true) {
              this.faqService.publishQuestion(question.id).subscribe((restResponse: RestResponse) => {
                this.addFinished();
              });
            } else {
              this.addFinished();
            }
          });
        }
      });
    }
  }

  addFinished() {
    if (this.faqForm?.get("anotherQuestion")?.value) {
      this.faqForm?.reset();
    } else {
      this.router.navigate(['/faq/home']);
    }
  }
  cancel() {
    this.router.navigate(['/faq/home']);
  }
}
