import { Component, OnInit } from '@angular/core';
import { User } from '../../_models/user';
import { AdminService } from '../../_services/admin.service';
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsModalRef }from 'ngx-bootstrap/modal'
import { RolesModalComponent } from '../roles-modal/roles-modal.component';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
    users: User[] = [];
    bsModalRef!: BsModalRef;

    constructor(private adminService: AdminService,
        private modalService: BsModalService) { }

    ngOnInit() {
        this.getUsersWithRoles();
    }

    getUsersWithRoles() {
        this.adminService.getUsersWithRoles().subscribe((users: any) => {
            this.users = users;
        }, error => {
            console.log(error);
        });
    }

    editRolesModal(user: User) {
        const initialState = {
            user,
            roles: this.getRolesArray(user)
        };
        this.bsModalRef = this.modalService.show(RolesModalComponent, { initialState });
        this.bsModalRef.content.updateSelectedRoles.subscribe((values: any[]) => {
            const rolesToUpdate = {
                roleNames: [...values.filter(el => el.checked === true).map(el => el.name)],
                username: user.username
            };
            if (rolesToUpdate) {
                this.adminService.updateUserRoles(rolesToUpdate).subscribe(() => {
                    user.roles = [...rolesToUpdate.roleNames];
                }, error => {
                    console.log(error);
                });
            }
        });
    }

    private getRolesArray(user: User) {
        const roles = [];
        const userRoles = user.roles;
        const availableRoles: any[] = [
            { name: 'Admin', value: 'Admin' },
            { name: 'Answerer', value: 'Answerer' },
            { name: 'Editor', value: 'Editor' },
            { name: 'Member', value: 'Member' },
        ];

        for (let i = 0; i < availableRoles.length; i++) {
            let isMatch = false;
            for (let j = 0; j < userRoles?.length!; j++) {
                if (userRoles && availableRoles[i].name === userRoles[j]) {
                    isMatch = true;
                    availableRoles[i].checked = true;
                    roles.push(availableRoles[i]);
                    break;
                }
            }
            if (!isMatch) {
                availableRoles[i].checked = false;
                roles.push(availableRoles[i]);
            }
        }
        return roles;
    }

}
