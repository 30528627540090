import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BookService } from '../../../_services/book.service';
import { BookCategory } from '../../../_models/bookCategory';
import { Language } from '../../../_models/language';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
    addCategoryForm!: UntypedFormGroup;
    category = {} as BookCategory;
    parentId!: number;
    //languages: Language[];

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.parentId = parseInt(params['parentId']);
        });

        //this.route.data.subscribe(data => {
        //    this.languages = data['languages'];
        //});

        this.createaddCategoryForm();
    }

    createaddCategoryForm() {
        this.addCategoryForm = this.fb.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            displayOrder: [0]
        });
    }

    addCategory() {
        if (this.addCategoryForm.valid) {
            this.category = Object.assign({}, this.addCategoryForm.value);
            this.category.parentId = this.parentId;
            this.bookService.addCategory(this.category).subscribe(() => {
                this.alertify.success($localize`Saved successfully`);
                this.router.navigate(['/libraryManagement/bookCategoryManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/bookCategoryManagement']);
    }
}
