import { DataFileService } from './../../../_services/dataFile.service';
import { DataFilesSelectComponent } from './../../../helper/data-files-select/data-files-select.component';
import { UploadComponent } from './../../../helper/upload/upload.component';
import { AlertifyService } from './../../../_services/alertify.service';
import { PostFile } from './../../../_models/posts/postFile';
import { DataFile } from './../../../_models/dataFile';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { PostService } from 'src/app/_services/post.service';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';
import { Post } from 'src/app/_models/posts/Post';
import { result } from 'lodash';
import { FlagsSelectComponent } from 'src/app/helper/flags-select/flags-select.component';
import { CacheService } from 'src/app/_services/cache.service';
import { CommonHelper } from 'src/app/helper/commonHelper';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.css']
})
export class AddPostComponent implements OnInit {
  postForm: FormGroup = new FormGroup({});
  //attachmentAddress: String | undefined | null;
  dataFile: DataFile | undefined | null;
  selectedDataFiles: DataFile[] = [];
  attachShow: boolean = false;
  postFlags: any;

  @ViewChild(DataFilesSelectComponent) dataFilesSelect!: DataFilesSelectComponent;
  @ViewChild(FlagsSelectComponent) flagsSelect!: FlagsSelectComponent;

  @Input() post: Post = {
    id: 0,
    categoryId: null,
    title: '',
    body: '',
    displayOrder: 0,
    deleted: false,
    publish: false,
    postFiles: null,
    flags: 0
  }

  @Input() categoryId: number | null = null;

  @Output() onAddPostFile = new EventEmitter<PostFile>();
  @Output() onSave = new EventEmitter<Post>();
  @Output() onEdit = new EventEmitter<Post>();

  constructor(private postService: PostService,
    private dataFileService: DataFileService,
    private alertify: AlertifyService,
    private cacheService: CacheService) { }

  ngOnInit(): void {
    var postFlagsCached = this.cacheService.get("postFlags")
    if (!postFlagsCached) {
      this.postService.getPostFlagsKeyValue().subscribe({
        next: (r: RestResponse) => {
          this.postFlags = r.data
          this.cacheService.set("postFlags", this.postFlags);
        }
      })
    } else {
      this.postFlags = postFlagsCached
    }
    this.createPostForm();
    if (this.post.id > 0) {
      this.post.postFiles?.sort((a: { displayOrder: number; }, b: { displayOrder: number; }) => (a.displayOrder < b.displayOrder ? -1 : 1))
        .forEach((f) => { this.selectedDataFiles.push(f.dataFile); });
    }
  }

  showBody(body:string): string{
    return CommonHelper.removeAllHtmlTags(body)
  }
  createPostForm() {
    this.postForm = new FormGroup({
      title: new FormControl(this.post.title, Validators.required),
      body: new FormControl(this.post.body, Validators.required),
      displayOrder: new FormControl(this.post.displayOrder, Validators.required),
      publish: new FormControl(this.post.publish),
    });
  }

  showAttach() {
    this.attachShow = true;
  }
  reset() {
    this.post.title = "";
    this.post.body = "";
    this.post.displayOrder = 0;
    this.post.publish = false;
    this.post.flags = 0;
    this.createPostForm();
    this.dataFilesSelect!.reset();
  }
  fileSelectChanged(dataFile: DataFile) {
    var df: DataFile = this.selectedDataFiles.filter((f) => f.id == dataFile.id)[0];
    if (df)
      dataFile = df;
    if (dataFile.selected) {
      this.selectedDataFiles?.push(dataFile);
    } else {
      this.selectedDataFiles?.splice(this.selectedDataFiles.indexOf(dataFile), 1);
    }
  }
  deleteDataFileFromSelectList(dataFile: DataFile) {
    dataFile.selected = false;
    this.selectedDataFiles?.splice(this.selectedDataFiles.indexOf(dataFile), 1);
  }
  // fileSelected(event : any) {
  //   this.dataFile = {
  //     id : 0,
  //     urlAddress : event.fileSelected,
  //     title: event.title,
  //     selected: false,
  //     show: true
  //   };
  //   this.dataFileService.addDataFile(this.dataFile).subscribe((restResponse: RestResponse) => {
  //     if (restResponse.status === RestResponseStatus.Success) {
  //       this.dataFile = restResponse.data
  //     }
  //   });
  //   //this.attachmentAddress = event;
  // }

  add() {
    if (this.postForm.valid) {
      var post: Post = {
        id: 0,
        categoryId: this.categoryId,
        title: '',
        body: '',
        displayOrder: 0,
        deleted: false,
        publish: false,
        postFiles: null,
        flags: this.flagsSelect.getFlag()
      };
      Object.assign(post!, this.postForm.value);
      this.postService.addPost(post!).subscribe({
        next: (restResponse: RestResponse) => {
          if (restResponse.status === RestResponseStatus.Success) {
            var i = 0;
            post.postFiles = []
            this.selectedDataFiles?.forEach((df) => {
              i++;
              var postFile: any = {
                id: 0,
                postId: restResponse.data.id,
                dataFileId: df.id,
                dataFile: df,
                displayOrder: i
              };
              this.postService.addPostFile(postFile).subscribe((result: RestResponse) => {
                if (result.status === RestResponseStatus.Success) {
                  postFile.id = result.data.Id;
                  this.onAddPostFile.emit(postFile);
                  post.postFiles?.push(postFile)
                  if (this.selectedDataFiles.length == post.postFiles?.length) {
                    this.onSave.emit(post);
                  }
                } else {
                  this.alertify.showMessage(result);
                }
              });
            });
            this.alertify.showMessage(restResponse);
            post.id = restResponse.data.id;
            if (this.selectedDataFiles.length <= 0) {
              this.onSave.emit(post);
            }
          } else {
            this.alertify.showMessage(restResponse);
          }
        }, error: (error: RestResponse) => {
          this.alertify.showMessage(error);
        }, complete: () => {
          //this.reset();
        }
      });
    }
  }

  edit() {
    if (this.postForm.valid) {
      Object.assign(this.post, this.postForm.value);
      this.post.flags = this.flagsSelect.getFlag();
      this.postService.editPost(this.post).subscribe({
        next: (restResponse: RestResponse) => {
          if (restResponse.status === RestResponseStatus.Success) {
            this.postService.deletePostFiles(this.post.id).subscribe({
              next: (rr: RestResponse) => {
                if (rr.status == RestResponseStatus.Success) {
                  var i = 0;
                  this.post.postFiles = [];
                  this.selectedDataFiles?.forEach((df) => {
                    i++;
                    var postFile: any = {
                      id: 0,
                      postId: this.post.id,
                      dataFileId: df.id,
                      dataFile: df,
                      displayOrder: i
                    };
                    this.postService.addPostFile(postFile).subscribe((result: RestResponse) => {
                      if (result.status === RestResponseStatus.Success) {
                        this.post.postFiles?.push(postFile);
                        //   this.onSave.emit(restResponse.data);
                      } else {
                        this.alertify.showMessage(result);
                      }
                    });
                  });
                }
              },
              error: (error: RestResponse) => { this.alertify.showMessage(error); }
            });
            this.onEdit.emit(this.post);
          } else {
            this.alertify.showMessage(restResponse);
          }
        }, error: (error: any) => {
          this.alertify.showMessage(error);
        }, complete: () => {

        }
      });



    }
  }

  onFormSubmit() {
    if (this.post.id <= 0) {
      this.add();
    } else {
      this.edit();
    }
  }

  /*
  // darg and drop
  // The current dragging item
draggingEle : HTMLElement | undefined;

// The current position of mouse relative to the dragging element
x : number = 0;
y : number = 0;

placeholder : HTMLDivElement | undefined;
isDraggingStarted = false;

mouseDownHandler(e: any) {
    this.draggingEle = e.target;

    // Calculate the mouse position
    const rect = this.draggingEle!.getBoundingClientRect();
    this.x = e.pageX - rect.left;
    this.y = e.pageY - rect.top;

    // Attach the listeners to `document`
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);

    const draggingRect = this.draggingEle!.getBoundingClientRect();

    if (!this.isDraggingStarted) {
        // Update the flag
        this.isDraggingStarted = true;

        // Let the placeholder take the height of dragging element
        // So the next element won't move up
        this.placeholder = document.createElement('div');
        this.placeholder.classList.add('placeholder');
        this.draggingEle!.parentNode!.insertBefore(
          this.placeholder,
          this.draggingEle!.nextSibling
        );

        // Set the placeholder's height
        this.placeholder!.style.height = `${draggingRect.height}px`;
    }
};

mouseMoveHandler(e : any) {
    // Set position for dragging element
    this.draggingEle!.style.position = 'absolute';
    this.draggingEle!.style.top = `${e.pageY - this.y}px`;
    this.draggingEle!.style.left = `${e.pageX - this.x}px`;

    const prevEle = this.draggingEle!.previousElementSibling;
    const nextEle = this.placeholder!.nextElementSibling;

    if (prevEle && this.isAbove(this.draggingEle, prevEle)) {
      // The current order    -> The new order
      // prevEle              -> placeholder
      // draggingEle          -> draggingEle
      // placeholder          -> prevEle
      this.swap(this.placeholder, this.draggingEle);
      this.swap(this.placeholder, prevEle);
      return;
  }
  if (nextEle && this.isAbove(nextEle, this.draggingEle)) {
    // The current order    -> The new order
    // draggingEle          -> nextEle
    // placeholder          -> placeholder
    // nextEle              -> draggingEle
    this.swap(nextEle, this.placeholder);
    this.swap(nextEle, this.draggingEle);
}
}

swap(nodeA : any, nodeB: any) {
  const parentA = nodeA.parentNode;
  const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

  // Move `nodeA` to before the `nodeB`
  nodeB.parentNode.insertBefore(nodeA, nodeB);

  // Move `nodeB` to before the sibling of `nodeA`
  parentA.insertBefore(nodeB, siblingA);
};

mouseUpHandler(){
  // Remove the position styles
  this.draggingEle!.style.removeProperty('top');
  this.draggingEle!.style.removeProperty('left');
  this.draggingEle!.style.removeProperty('position');

  this.x = 0;
  this.y = 0;
  this.draggingEle = undefined;

  // Remove the handlers of `mousemove` and `mouseup`
  document.removeEventListener('mousemove', this.mouseMoveHandler);
  document.removeEventListener('mouseup', this.mouseUpHandler);

      // Remove the placeholder
      this.placeholder && this.placeholder!.parentNode!.removeChild(this.placeholder);
      // Reset the flag
      this.isDraggingStarted = false;
};

ngAfterViewInit(): void {
  const list = document.getElementById('displayOrderList');

  // Query all items
  [].slice.call(list!.querySelectorAll('.draggable')).forEach( (item) => {
      (item as HTMLElement).addEventListener('mousedown', this.mouseDownHandler);
  });
}

isAbove(nodeA : any, nodeB: any) {
  // Get the bounding rectangle of nodes
  const rectA = nodeA.getBoundingClientRect();
  const rectB = nodeB.getBoundingClientRect();

  return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
};*/

}
