import { DataFilesSelectComponent } from './../../../helper/data-files-select/data-files-select.component';
import { DataFile } from './../../../_models/dataFile';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';
import { faL } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-edit-book-file',
    templateUrl: './edit-book-file.component.html',
    styleUrls: ['./edit-book-file.component.css']
})
export class EditBookFileComponent implements OnInit {
    editBookFileForm!: UntypedFormGroup;
    bookFile: any;
    loading: Boolean = true;
    types: string[] = ["pdf", "docx", "doc", "googledrive.pdf", "googledrive.docx", "googledrive.doc"];
    returnUrl: undefined;
    selectedDataFiles: DataFile[] = [];

    @ViewChild(DataFilesSelectComponent) dataFilesSelect!: DataFilesSelectComponent;

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            
            if (params['id']) {
                var id = parseInt(params['id']);
                console.log(id);
                this.bookService.getBookFile(id).subscribe({
                    next: (restResponse: RestResponse) => {
                        console.log(restResponse);
                        if (restResponse.status === RestResponseStatus.Success) {
                            
                            this.bookFile = restResponse.data;
                            this.createaddBookForm();
                            this.loading = false;
                        } else {
                            this.alertify.showMessage(restResponse);
                        }
                    },
                    error: (error) => {
                        this.alertify.showMessage(error);
                    }
                })

            }
            this.returnUrl = params['returnUrl'];
        });
        // this.route.data.subscribe(data => {
        //     this.bookFile = data['bookFile'];
        //     this.createaddBookForm();
        // });

        // this.route.params.subscribe(params => {
        //     this.returnUrl = params['returnUrl'];
        // });
    }

    dataFileSelectLoaded() {
        this.dataFilesSelect.getDataFiles().map((v, i) => {
            if (v.id == this.bookFile.dataFileId) {
                v.selected = true;
            }
        })
    }

    onTypeSelect(typeselected: any) {
        this.editBookFileForm.patchValue({ type: (typeselected.target as HTMLSelectElement).value });
    }
    createaddBookForm() {
        this.editBookFileForm = this.fb.group({
            name: [this.bookFile.name, Validators.required],
            type: [this.bookFile.type, Validators.required],
            version: [this.bookFile.version, Validators.required],
            displayOrder: [this.bookFile.displayOrder]
        });
    }
    // fileSelected(event: any) {
    //     this.bookFile.urlAddress = event;
    // }
    fileSelectChanged(dataFile: DataFile) {
        if (dataFile.selected) {
            this.selectedDataFiles = [];
            this.selectedDataFiles.push(dataFile);
            this.bookFile.dataFileId = dataFile.id;
        } else {
            this.bookFile.dataFileId = null;
        }
    }
    editBookFile() {
        if (!this.bookFile.dataFileId == null) {
            this.alertify.error($localize`Please upload or select file.`);
            return;
        }
        if (this.editBookFileForm.valid) {
            Object.assign(this.bookFile, this.editBookFileForm.value);
            this.bookService.editBookFile(this.bookFile).subscribe(() => {
                this.alertify.success($localize`Edited successfully`);
                if (this.returnUrl)
                    this.router.navigate(['/' + this.returnUrl]);
                else
                    this.router.navigate(['/libraryManagement/bookManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        if (this.returnUrl)
            this.router.navigate(['/' + this.returnUrl]);
        else
            this.router.navigate(['/libraryManagement/bookManagement']);
    }
}
