import { RestResponse } from './../../../_models/restResponse';
import { DataFile } from './../../../_models/dataFile';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { CommonHelper } from 'src/app/helper/commonHelper';

@Component({
    selector: 'app-add-author-book',
    templateUrl: './add-author-book.component.html',
    styleUrls: ['./add-author-book.component.css']
})
export class AddAuthorBookComponent implements OnInit {
    addBookForm!: UntypedFormGroup;
    book: any;
    authorId!: number;
    categories: any;
    urlAddress: string = "";
    selectedDataFiles: DataFile[] = [];

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private commonHelper: CommonHelper,
        private router: Router) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.categories = data['categories'];
            this.route.params.subscribe(params => {
                this.authorId = parseInt(params['authorId']);
            });
            this.createaddBookForm();
        });
    }

    //public uploadFinished = (event) => {
    //    this.response = event;
    //}
    // fileSelected(event : any) {
    //     this.urlAddress = event;
    // }
    createaddBookForm() {
        this.addBookForm = this.fb.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            bookCategoryId: [null],
            releaseDate: [null, [Validators.required, Validators.pattern(/^\d{4}[./-]\d{2}[./-]\d{2}$/)]],
            isMain: [false],
            displayOrder: [0]
        });
    }

    // fileSelectChanged(dataFile: DataFile) {
    //     if (dataFile.selected) {
    //         this.selectedDataFiles = [];
    //         this.selectedDataFiles.push(dataFile);
    //     } else {
    //         this.selectedDataFiles = [];
    //     }
    // }
    fileSelectChanged(dataFile: DataFile) {
        var df: DataFile = this.selectedDataFiles.filter((f) => f.id == dataFile.id)[0];
        if (df)
          dataFile = df;
        if (dataFile.selected) {
          this.selectedDataFiles?.push(dataFile);
        } else {
          this.selectedDataFiles?.splice(this.selectedDataFiles.indexOf(dataFile), 1);
        }
      }
    addBook() {
        if (this.addBookForm.valid) {
            this.book = Object.assign({}, this.addBookForm.value);
            this.book.authorId = this.authorId;
            this.book.bookFiles = [];
            if (this.selectedDataFiles.length > 0) {
                this.selectedDataFiles.forEach((v) => {
                    if(this.commonHelper.isImage(v.urlAddress)){
                        this.book.dataFileId = v.id;
                    }else{
                        this.book.bookFiles.push({
                            name: this.book.name, 
                            type : CommonHelper.getExtension(v.urlAddress),
                            dataFileId: v.id
                        });
                    }
                });
                this.book.dataFileId = this.selectedDataFiles[0].id;
            }
            this.bookService.addBook(this.book).subscribe({
                next:(restResponse:RestResponse)=>{
                    this.alertify.success($localize`Saved successfully`);
                    this.router.navigate(['/libraryManagement/authorManagement']);    
                },
                error: (error: RestResponse) => {
                    this.alertify.showMessage(error);
                }});
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/authorManagement']);
    }
}
