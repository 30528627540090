<div class="container">
    <div *ngIf="loading" class="spinner-border text-info" role="status">
        <span i18n class="sr-only">Loading...</span>
    </div>
    <div *ngIf="!loading" class="row">
        <div class="col" style="border-radius: 10px;border: 1px solid #707070;padding: 10px;">
            <div class="row">
                <div class="col-sm-8">
                    <input class="form-control" [(ngModel)]="searchText" type="text" (input)="onSearchChange()"
                    placeholder="Filter files..." />
                </div>
                <div class="col-sm-4" style="align-self: center;">
                    <div class="form-check">
                        <input (change)="showSelectedFile()" [(ngModel)]="showSelected" class="form-check-input" type="checkbox" id="selectCheck">
                        <label i18n class="form-check-label" for="selectCheck">
                          Selected
                        </label>
                      </div>
                </div>
                <div class="col-sm-4" style="align-self: center;">
                    <div class="form-check">
                        <input (change)="showImageFile()" [(ngModel)]="showImage" class="form-check-input" type="checkbox" id="showImageCheck">
                        <label i18n class="form-check-label" for="showImageCheck">
                            Show Images
                        </label>
                      </div>
                </div>
            </div>
            <div class="mt-3" style="max-height: 320px;overflow-y: scroll;overflow-x: auto;">
                <div class="row row-cols-1 row-cols-md-3 g-4 m-1">
                    <ng-container *ngFor="let file of myfilter(dataFiles)">
                        <div class="col">
                            <div *ngIf="file.show" class="card h-100 mt-3">
                                <div *ngIf="!this.commonHelper.isImage(file.urlAddress)" (click)="cardClicked(file)" style="position: relative;"><span style="position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,50%);    font-size: 40px;
    font-weight: bold;
    color: cornflowerblue;cursor: pointer;">{{file.urlAddress.split('.').pop()}}</span></div>
                                <img *ngIf="file.selected" src="../../../assets/check.svg"
                                    style="display: flex;width: 20px;height: 20px;direction: rtl;position: absolute;top: -10px;right: -10px;"
                                    alt="...">
                                <img src="{{file.urlAddress}}" (click)="cardClicked(file)" class="card-img-top" style="cursor: pointer;"
                                    alt="{{file.title}}" appImage [show]="showImage">
                                <div class="card-body">
                                    <h5 class="card-title">{{file.title}}</h5>
                                    <p class="card-text"><a class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="{{file.urlAddress}}">{{file.urlAddress.split('/').pop()}}</a></p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col" style="padding: 10px;">
            <div i18n>Upload File</div>
            <div class="form-group" style="margin-top: 10px;">
                <label i18n>File Title</label>
                <input type="text" [(ngModel)]="title" class="form-control" />
            </div>
            <div class="mt-2">
                <input class="form-control" type="file" #file id="formFile">
            </div>
            <div class="mt-2">
                <button class="btn btn-outline-success" type="button" (click)="uploadFileToPublicStorage(file.files)">{{buttonContent}}</button>
            </div>
            <div *ngIf="progress" class="progress" style="margin-top: 8px;margin-bottom: 8px;">
                <div class="progress-bar" role="progressbar" aria-label="Example with label"
                    [style.width]="progress+'%'" style="width: 25%;" [attr.aria-valuenow]="progress" aria-valuemin="0"
                    aria-valuemax="100">{{progress}}%</div>
            </div>
            <div *ngIf="message">
                <span style="text-align:center;display: block;margin: 8px;">{{message}}</span>
            </div>
        </div>
    </div>
</div>