import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
  selector: 'app-add-book-content',
  templateUrl: './add-book-content.component.html',
  styleUrls: ['./add-book-content.component.css']
})
export class AddBookContentComponent implements OnInit {
    addBookContentForm!: UntypedFormGroup;
    bookContent: any;
    books: any;
    returnUrl: undefined;

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.books = data['books'];
        });
        this.route.params.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });
        this.createaddBookContentForm();
    }

    createaddBookContentForm() {
        this.addBookContentForm = this.fb.group({
            name: ['', Validators.required],
            bookId: [null, Validators.required],
            text: ['', Validators.required],
            subjects: [''],
            footNotes: [''],
            version: ['', Validators.required],
            displayOrder: [0]
        });
    }

    addBookContent() {
        if (this.addBookContentForm.valid) {
            this.bookContent = Object.assign({}, this.addBookContentForm.value);
            this.bookService.addBookContent(this.bookContent).subscribe(() => {
                this.alertify.success($localize`Saved successfully`);
                if (this.returnUrl)
                    this.router.navigate(['/' + this.returnUrl]);
                else
                    this.router.navigate(['/libraryManagement/bookContentManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        if (this.returnUrl)
            this.router.navigate(['/' + this.returnUrl]);
        else
            this.router.navigate(['/libraryManagement/bookContentManagement']);
    }
}
