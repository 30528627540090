<div class="container">
        <form class="form-control" [formGroup]="postForm" (ngSubmit)="onFormSubmit()">
          <h2 *ngIf="post.id<=0" i18n class="text-center text-primary">Add Post</h2>
          <h2 *ngIf="post.id>0" i18n class="text-center text-primary">Edit {{post.title}}</h2>
          <hr>
          <div class="form-group">
            <label i18n>Title</label>
            <input type="text"
                   [ngClass]="{'is-invalid': postForm.get('title')?.errors
                && postForm.get('title')?.touched}"
                   class="form-control"
                   formControlName="title">
            <div i18n class="invalid-feedback">Please enter a title</div>
          </div>
          <div class="form-group">
            <label i18n>Body</label>
            <textarea
                   [ngClass]="{'is-invalid': postForm.get('body')?.errors
                && postForm.get('body')?.touched}"
                   class="form-control"
                   formControlName="body"></textarea>
            <div i18n class="invalid-feedback">Please enter a body</div>
          </div>
          <div class="form-group">
            <app-flags-select flagsSelect [flags]="postFlags" [flag]="post.flags"></app-flags-select>
          </div>
          <div class="form-group">
            <label i18n>Display Order</label>
            <input type="number"
                   [ngClass]="{'is-invalid': postForm.get('displayOrder')?.errors
                && postForm.get('displayOrder')?.touched}"
                   class="form-control"
                   formControlName="displayOrder">
            <div i18n class="invalid-feedback">Please enter a display order</div>
          </div>
          <div class="form-group form-check mt-3">
            <input class="form-check-input" type="checkbox" id="publishPost" formControlName="publish">
            <label i18n for="publishPost" class="form-check-label">Publish</label>
          </div>

          <div id="displayOrderList" class="list-group list-group-numbered mt-2 mb-2">
            <div *ngFor="let df of selectedDataFiles" class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">{{df.title}}</div>
                <div class="d-flex flex-wrap align-content-start">{{df.urlAddress.split('/').pop()}}</div>
              </div>
              <button (click)="deleteDataFileFromSelectList(df)" class="btn btn-outline-danger"><i class="fas fa-trash-alt" aria-hidden="true"></i></button>
            </div>
          </div>

          <div class="form-group">
            <button i18n *ngIf="!attachShow" type="button" (click)="showAttach()" class="btn btn-primary">Attach file</button>
            <app-data-files-select [selectedFiles]="selectedDataFiles" (onFileSelectChanged)="fileSelectChanged($event)" dataFilesSelect *ngIf="attachShow"></app-data-files-select>
          </div>
          <!-- <div class="form-group">
            <label i18n>Upload File</label>
            <app-upload #upload [path]="'posts/attachments'" [titleNeed]="true" [buttonContent]="'Upload File'" (onFileSelected)="fileSelected($event)"></app-upload>
            <small i18n>Upload post files or select from list</small>
          </div> -->
          <div class="form-group text-center m-2">
            <button i18n class="btn btn-success" [disabled]="!postForm.valid" type="submit">Save</button>
          </div>
        </form>
        <p i18n>Display the final output</p>
        <div class="preline border-start border-2 border-info mt-2 p-4">
          {{showBody(postForm.get('body')?.value)}}
        </div>
</div>
