<div class="container mt-5">
  <h2 i18n>User Panel</h2>
  <div class="tab-panel">
    <tabset class="member-tabset">
      <tab heading="Profile">
        <div class="container">
          <form [formGroup]="updateForm" (ngSubmit)="updateUser()">
            <div class="form-group">
              <label i18n class="control-label" style="margin-right:10px">I am a: </label>
              <label i18n class="radio-inline">
                <input class="mr-3" type="radio" name="gender" value="male" formControlName="gender">Male
              </label>
              <label i18n class="radio-inline ml-3">
                <input class="mr-3" type="radio" name="gender" value="female" formControlName="gender">Female
              </label>
            </div>
            <div class="form-group">
              <label i18n for="username">
                User Name:
              </label>
              <input type="text" id="username" class="form-control" name="username"
                     [ngClass]="{'is-invalid': updateForm.get('username')?.errors
        && updateForm.get('username')?.touched}"
                     formControlName="username" />

              <div i18n class="invalid-feedback">
                User Name is required
              </div>
            </div>
            <div class="form-group">
              <label i18n for="dateOfBirth">
                Date of Birth ( Date format:'YYYY-MM-DD' )
              </label>
              <input type="text" id="dateOfBirth" class="form-control"
                     name="dateOfBirth" formControlName="dateOfBirth" />
              <!--<input type="text" id="dateOfBirth" class="form-control" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
         name="dateOfBirth" bsDatepicker formControlName="dateOfBirth" />-->

            </div>
            <div class="form-group">
              <label i18n for="Introduction">
                Introduction:
              </label>
              <textarea type="text" id="Introduction" class="form-control"
                        name="introduction" formControlName="introduction"></textarea>
            </div>
            <div class="form-group">
              <label i18n for="city">
                City:
                <input type="text" id="city" class="form-control"
                       name="city" formControlName="city" />
              </label>
            </div>
            <div class="form-group">
              <label i18n for="country">
                Country:
                <input type="text" id="country" class="form-control" name="country" formControlName="country" />
              </label>
            </div>
            <button type="submit" [disabled]="!updateForm.valid" class="btn btn-primary mt-2">Save</button>
          </form>
        </div>
      </tab>
      <tab heading="Password">
        <div class="container">
          <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
            <div class="form-group">
              <input type="password" class="form-control m-2" name="oldPassword" formControlName="oldPassword"
                     [ngClass]="{'is-invalid': changePasswordForm.get('oldPassword')?.errors
                     && changePasswordForm.get('oldPassword')?.touched}" placeholder="Old Password" />
              <div i18n class="invalid-feedback">
                Old password is required
              </div>
            </div>
            <div class="form-group">
              <input type="password"
                     [ngClass]="{'is-invalid': changePasswordForm.get('newPassword')?.errors
        && changePasswordForm.get('newPassword')?.touched}"
                     class="form-control m-2"
                     formControlName="newPassword"
                     placeholder="New Password">
              <div i18n class="invalid-feedback"
                   *ngIf="changePasswordForm.get('newPassword')?.hasError('required')
          && changePasswordForm.get('newPassword')?.touched">
                Password is required
              </div>
              <div i18n class="invalid-feedback"
                   *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength')
        && changePasswordForm.get('newPassword')?.touched">
                Password must be at least 8 characters
              </div>
              <div i18n class="invalid-feedback"
                   *ngIf="changePasswordForm.get('newPassword')?.hasError('strong')
        && changePasswordForm.get('newPassword')?.touched">
                The password must include lowercase, uppercase and numbers
              </div>
            </div>
            <div class="form-group">
              <input [ngClass]="{'is-invalid': changePasswordForm.get('confirmPassword')?.errors
          && changePasswordForm.get('confirmPassword')?.touched
          || changePasswordForm.get('confirmPassword')?.touched
          && changePasswordForm.hasError('mismatch')}"
                     type="password"
                     class="form-control m-2"
                     formControlName="confirmPassword"
                     placeholder="Confirm Password">
              <div i18n class="invalid-feedback"
                   *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required')
          && changePasswordForm.get('confirmPassword')?.touched">
                Confirm Password is required
              </div>
              <div i18n class="invalid-feedback"
                   *ngIf="changePasswordForm.hasError('mismatch')
        && changePasswordForm.get('confirmPassword')?.touched">
                Passwords must match
              </div>
            </div>
            <button i18n type="submit" [disabled]="!changePasswordForm.valid" class="btn btn-primary m-2">Change Password</button>
          </form>
        </div>
      </tab>
      <tab heading="Email">
        <div class="container">
          <h2 *ngIf="user?.email"><i class="fa fa-envelope"></i> {{user?.email}}
          <button i18n class="btn btn-light" *ngIf="!user?.emailConfirmed" (click)="confirmEmailRequest()">Confirm</button></h2>
          <div class="panel panel-default">
            <div class="panel-heading">Change email</div>
            <div class="panel-body">
              <form [formGroup]="changeEmailForm" (ngSubmit)="changeEmail()">
            <div class="form-group">
              <input type="text" id="newEmail" formControlName="newEmail" placeholder="Enter Email"
                     [ngClass]="{'is-invalid': changeEmailForm.get('newEmail')?.errors
             && changeEmailForm.get('newEmail')?.touched}" class="form-control m-2" />
              <div i18n class="invalid-feedback" *ngIf="changeEmailForm.get('newEmail')?.hasError('required')
          && changeEmailForm.get('newEmail')?.touched">Enter your email for reset password</div>
              <div i18n class="invalid-feedback" *ngIf="changeEmailForm.get('newEmail')?.hasError('pattern')
          && changeEmailForm.get('newEmail')?.touched">Email is not valid</div>
            </div>
            <div class="form-group">
              <input type="password" autocomplete="new-password"
                     [ngClass]="{'is-invalid': changeEmailForm.get('password')?.errors
        && changeEmailForm.get('password')?.touched}"
                     class="form-control m-2"
                     formControlName="password"
                     placeholder="Password">
              <div i18n class="invalid-feedback"
                   *ngIf="changeEmailForm.get('password')?.hasError('required')
          && changeEmailForm.get('password')?.touched">
                Password is required
              </div>
              <div i18n class="invalid-feedback"
                   *ngIf="changeEmailForm.get('password')?.hasError('minlength')
        && changeEmailForm.get('password')?.touched">
                Password must be at least 4 characters
              </div>
              <div i18n class="invalid-feedback"
                   *ngIf="changeEmailForm.get('password')?.hasError('maxlength')
      && changeEmailForm.get('password')?.touched">
                Password cannot exceed 8 characters
              </div>
            </div>
            <button i18n type="submit" [disabled]="!changeEmailForm.valid" class="btn btn-primary m-2">Change</button>
          </form>
            </div>
          </div>
          <div *ngIf="this.codeEmailForm" class="panel panel-default">
            <div class="panel-heading">Send code you received from email</div>
            <div class="panel-body">
              <form [formGroup]="codeEmailForm" (ngSubmit)="codeEmail()">
            <div class="form-group">
              <input type="text" [ngClass]="{'is-invalid': codeEmailForm.get('code')?.errors
        && codeEmailForm.get('code')?.touched}"
                     class="form-control m-2"
                     formControlName="code"
                     placeholder="code">
              <div i18n class="invalid-feedback"
                   *ngIf="codeEmailForm.get('code')?.hasError('required')
          && codeEmailForm?.get('code')?.touched">
                Code is required
              </div>
            </div>
            <button i18n type="submit" [disabled]="!codeEmailForm.valid" class="btn btn-primary m-2">Send</button>
          </form>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
</div>
