import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookCategory } from '../../../_models/bookCategory';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { RestResponse, RestResponseStatus } from 'src/app/_models/restResponse';
import { BookCategoriesAndBooks } from 'src/app/_models/bookCategoriesAndBooks';

@Component({
  selector: 'app-book-category-management',
  templateUrl: './book-category-management.component.html',
  styleUrls: ['./book-category-management.component.css']
})
export class BookCategoryManagementComponent implements OnInit {
  bookCats: BookCategoriesAndBooks | undefined;
  loading: Boolean = true;
  constructor(private route: ActivatedRoute,
    private bookService: BookService,
    private router: Router,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      var id: Number = 0;
      if (params['id']) { id = parseInt(params['id']) };
      this.bookService.getCategoriesWithBooks(id).subscribe({
        next: (restResponse: RestResponse) => {
          if (restResponse.status === RestResponseStatus.Success) {
            this.bookCats = restResponse.data;
            this.loading = false;
          } else {
            this.alertify.showMessage(restResponse);
          }
        },
        error: (error) => {
          this.alertify.showMessage(error);
        }
      })
    });
    // this.route.data.subscribe(data => {
    //     this.bookCats = data['bookCategories'];
    // });
  }
}
