<div class="container">
  <h3 i18n>Library for english books (Authors)</h3>
  <div class="text-right">
    <a [routerLink]="['/libraryManagement/addAuthor']" class="btn btn-default"><span class="fa fa-plus-circle"></span><span i18n>Add Author</span></a>
  </div>
  <div>
    <div class="list-group list-group-root well">
      <app-list-group-author [authors]="authors"></app-list-group-author>
    </div>
  </div>
</div>
