<ul *ngFor="let author of sortedAuthors" class="list-group">
  <li class="list-group-item">
    <a (click)="collapse('aitem'+author.id)" class="btn btn-link" type="button">{{author.firstName}} {{author.lastName}}</a>
    <span class="pull-right">
      <a [routerLink]="['/libraryManagement/editAuthor/'+author.id]" class="fa fa-edit btn btn-default btn-sm" title="Edit {{author.firstName}} {{author.lastName}}"></a>
      <a *ngIf="author.books.length <= 0 " (click)="deleteAuthor(authors, author)" title="Delete {{author.firstName}} {{author.lastName}}" class="fa fa-trash btn btn-default btn-sm"></a>
    </span>
    <ul *ngIf="author" class="list-group list-group-flush collapse" id="aitem{{author.id}}">
      <dl class="row">
        <dt *ngIf="author.imageUrl" class="col-sm-3"></dt>
        <dd *ngIf="author.imageUrl" class="col-sm-9"><img class="img-fluid rounded" [src]="author.imageUrl" /></dd>
        <dt i18n class="col-sm-3">First Name</dt>
        <dd class="col-sm-9">{{author.firstName}}</dd>
        <dt i18n class="col-sm-3">Last Name</dt>
        <dd class="col-sm-9">{{author.lastName}}</dd>
      </dl>
    </ul>
    <div class="accordion">
      <div class="card">
        <div class="card-header">
          <h2 class="mb-0">
            <a i18n (click)="collapse('booksItem'+author.id)" class="btn btn-link" type="button">
              Books ({{author.books.length}})
            </a>
            <a [routerLink]="['/libraryManagement/addAuthorBook/'+author.id]" title="Add book for {{author.firstName}} {{author.lastName}}" class="btn btn-default pull-right"><span class="fa fa-plus-circle"></span></a>
          </h2>
        </div>
        <div id="booksItem{{author.id}}" class="collapse">
          <div class="container">
            <div class="card-columns">
              <app-book-card [editBookLink]="'/libraryManagement/editAuthorBook/'" [books]="author.books" [returnUrl]="'libraryManagement/authorManagement'"></app-book-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
