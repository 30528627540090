<form [formGroup]="addBookForm" (ngSubmit)="addBook()">
  <h2 i18n class="text-center text-primary">Add Author Book</h2>
  <hr>

  <div class="form-group">
    <label i18n>Name</label>
    <input type="text"
           [ngClass]="{'is-invalid': addBookForm.get('name')?.errors
        && addBookForm.get('name')?.touched}"
           class="form-control"
           formControlName="name"
           placeholder="Name">
    <div i18n class="invalid-feedback">Please choose a name</div>
  </div>
  <div class="form-group">
    <label i18n>Description</label>
    <textarea [ngClass]="{'is-invalid': addBookForm.get('description')?.errors
        && addBookForm.get('description')?.touched}"
              class="form-control"
              formControlName="description"
              placeholder="Description"></textarea>
    <div i18n class="invalid-feedback">Please enter description</div>
  </div>
  <!-- <div class="form-group">
    <label i18n>Category</label>
    <select class="form-select"
    [ngClass]="{'is-invalid': addBookForm.get('bookCategoryId')?.errors
        && addBookForm.get('bookCategoryId')?.touched}" formControlName="bookCategoryId">
      <option i18n [ngValue]="null" disabled selected>Select category</option>
      <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
    </select>
    <div i18n class="invalid-feedback">Please select category</div>
  </div> -->
  <div class="form-group">
    <label i18n>Release Date</label>
    <input type="text" id="releaseDate" class="form-control" placeholder="Release Date(1980-03-13)"
           [ngClass]="{'is-invalid': addBookForm.get('releaseDate')?.errors
        && addBookForm.get('releaseDate')?.touched}"
           name="releaseDate" formControlName="releaseDate" />
    <div i18n class="invalid-feedback">Please enter release date like 1980-03-13</div>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="isMain" name="isMain" formControlName="isMain">
    <label i18n class="form-check-label" for="isMain">
      Is Main
    </label>
    <small i18n>Is this main book for author</small>
  </div>
  <div class="form-group">
    <label i18n>Display Order</label>
    <input type="number"
           class="form-control"
           formControlName="displayOrder"
           placeholder="Display Order">
  </div>
  <div class="form-group">
    <label i18n>Book Image</label>
    <app-data-files-select (onFileSelectChanged)="fileSelectChanged($event)" [selectedFiles]="selectedDataFiles" [MultipleFileSelect]="true" dataFilesSelect></app-data-files-select>
    <!-- <app-upload [path]="'books/images'" [buttonContent]="'Upload Image'" (onFileSelected)="fileSelected($event)"></app-upload> -->
    <small i18n>Upload book image or select from list</small>
  </div>
  <div class="form-group text-center">
    <button i18n class="btn btn-success" [disabled]="!addBookForm.valid" type="submit">Save</button>
    <button i18n class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
