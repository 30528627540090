import { DataFile } from './../../../_models/dataFile';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { Book } from '../../../_models/book';

@Component({
    selector: 'app-add-book',
    templateUrl: './add-book.component.html',
    styleUrls: ['./add-book.component.css']
})
export class AddBookComponent implements OnInit {
    addBookForm!: UntypedFormGroup;
    book: any;
    //categoryId!: number;
    authors: any;
    urlAddress: string = "";
    selectedDataFiles: DataFile[] = [];

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.authors = data['authors'];
        });
        // this.route.params.subscribe(params => {
        //     this.categoryId = parseInt(params['categoryId']);
        // });
        this.createaddBookForm();
    }

    //public uploadFinished = (event) => {
    //    this.response = event;
    //}
    // fileSelected(event : any) {
    //     this.urlAddress = event;
    // }
    createaddBookForm() {
        this.addBookForm = this.fb.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            authorId: [null, Validators.required],
            releaseDate: [null, [Validators.required, Validators.pattern(/^\d{4}[./-]\d{2}[./-]\d{2}$/)]],
            isMain: [false],
            displayOrder: [0]
        });
    }

    fileSelectChanged(dataFile: DataFile) {
        if (dataFile.selected) {
            this.selectedDataFiles = [];
            this.selectedDataFiles.push(dataFile);
        } else {
            this.selectedDataFiles = [];
        }
    }

    addBook() {
        if (this.addBookForm.valid) {
            this.book = Object.assign({}, this.addBookForm.value);
            //this.book.bookCategoryId = this.categoryId;
            if (this.selectedDataFiles.length > 0) {
                this.book.dataFileId = this.selectedDataFiles[0].id;
            }
            this.bookService.addBook(this.book).subscribe(() => {
                this.alertify.success($localize`Saved successfully`);
                this.router.navigate(['/libraryManagement/bookCategoryManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/bookCategoryManagement']);
    }
}
