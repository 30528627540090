import { DataFilesSelectComponent } from './../../../helper/data-files-select/data-files-select.component';
import { DataFile } from './../../../_models/dataFile';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../../_services/book.service';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
    selector: 'app-edit-author',
    templateUrl: './edit-author.component.html',
    styleUrls: ['./edit-author.component.css']
})
export class EditAuthorComponent implements OnInit {
    editAuthorForm!: UntypedFormGroup;
    author: any;
    selectedDataFiles: DataFile[] = [];

    @ViewChild(DataFilesSelectComponent) dataFilesSelect!: DataFilesSelectComponent;

    constructor(private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private bookService: BookService,
        private alertify: AlertifyService,
        private router: Router) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.author = data['author'].data;
            if(this.author.birthDate!=null) this.author.birthDate = this.author.birthDate.split("T", 1)[0];
            this.createaddAuthorForm();
        });

    }

    dataFileSelectLoaded(){
        this.dataFilesSelect.getDataFiles().map((v,i)=>{
            if(v.id==this.author.dataFileId){
                v.selected = true;
            }
        })
    }
    // fileSelected(event: any) {
    //     this.author.imageUrl = event;
    // }
    createaddAuthorForm() {
        this.editAuthorForm = this.fb.group({
            firstName: [this.author.firstName, Validators.required],
            lastName: [this.author.lastName, Validators.required],
            gender: [this.author.gender, Validators.required],
            bio: [this.author.bio, Validators.required],
            academy: [this.author.academy, Validators.required],
            birthDate: [this.author.birthDate, Validators.pattern(/^\d{4}[./-]\d{2}[./-]\d{2}$/)],
            nationality: [this.author.nationality],
            religion: [this.author.religion],
            displayOrder: [this.author.displayOrder]
        });
    }

    fileSelectChanged(dataFile: DataFile) {
        if (dataFile.selected) {
            this.selectedDataFiles = [];
            this.selectedDataFiles.push(dataFile);
            this.author.dataFileId = dataFile.id;
        } else {
            this.author.dataFileId = null;
        }
    }
    editAuthor() {
        if (this.editAuthorForm.valid) {
            Object.assign(this.author, this.editAuthorForm.value);
            this.bookService.editAuthor(this.author).subscribe(() => {
                this.alertify.success($localize`Edited successfully`);
                this.router.navigate(['/libraryManagement/authorManagement']);
            }, error => {
                this.alertify.error(error);
            });
        }
    }

    cancel() {
        this.router.navigate(['/libraryManagement/authorManagement']);
    }
}
