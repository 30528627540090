import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {

    constructor(private route: ActivatedRoute, private userService: UserService,
        private alertify: AlertifyService) { }
    queryObj: any = {};
    //username: string;
    //newEmail: string;
    //code: string;
    message: string = '';

    ngOnInit() {
        //this.username = this.route.snapshot.paramMap.get('username');
        //this.newEmail = this.route.snapshot.paramMap.get('newEmail');
        //this.code = this.route.snapshot.paramMap.get('code');
        //console.log(this.username);
        this.route.queryParamMap.subscribe(params => {
            this.userService.changeEmail(params.get('newEmail')!, params.get('code')!).subscribe(() => {
                this.message = $localize`Your email changed successfully.`;
            }, error => {
                this.message = $localize`Faild to changing your email.`;
                this.alertify.error(error);
            });
        });
//        this.route.paramMap.subscribe(paramMap => {
//            //this.username = paramMap.get('username');
//            //this.userService.changeEmail(this.username, this.newEmail, this.code).subscribe(() => {
//            //    this.message = 'Your email changed successfully.';
//            //}, error => {
//            //        this.message = 'Faild to changing your email.';
//            //        this.alertify.error(error);
//            //});
//        }
//);
    }

}
