import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'importance'
})
export class ImportancePipe implements PipeTransform {
    locale: string = 'en';
    constructor() {
    }
    transform(value: any, args?: any): any {
        switch (value) {
            case 1: return $localize`Normal`;
            case 2: return $localize`Important`;
            case 3: return $localize`Very important`;

            default: return ``;
        }
    }
}